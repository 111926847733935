import { executeRequest, executeRequestWithBody, jsRoutes } from '../../../components/lib/RequestUtil';

class UserService {
  signIn(signInObject) {
    const { url, method } = jsRoutes.mvpstars.controllers.Auth.signIn();
    return executeRequestWithBody(url, signInObject, { method });
  }

  signOut() {
    const { url, method } = jsRoutes.mvpstars.controllers.Auth.signOut();
    return executeRequest(url, { method });
  }

  forgotPassword(email) {
    const { url, method } = jsRoutes.mvpstars.controllers.Auth.forgotPassword();
    return executeRequestWithBody(url, { userIdentifier: email }, { method });
  }

  changePassword(changePasswordObject) {
    const { url, method } = jsRoutes.mvpstars.controllers.Auth.changePassword();
    return executeRequestWithBody(url, changePasswordObject, { method });
  }

  resetPassword(tokenId, resetPasswordObject) {
    const { url, method } = jsRoutes.mvpstars.controllers.Auth.resetPassword(tokenId);
    return executeRequestWithBody(url, resetPasswordObject, { method });
  }

  getUser() {
    const { url, method } = jsRoutes.mvpstars.controllers.Auth.user();
    return executeRequest(url, { method });
  }

  getColleagues(organizationId) {
    const { url, method } = jsRoutes.mvpstars.controllers.admin.Users.list();
    return executeRequest(`${url}?filters=organizations%3D${organizationId}`, { method });
  }

  removeFromOrganization(organizationId, userId) {
    const { url, method } = jsRoutes.mvpstars.controllers.admin.Users.removeFromOrganization(organizationId, userId);
    return executeRequest(url, { method });
  }

  addUser(organizationId, user) {
    const { url, method } = jsRoutes.mvpstars.controllers.admin.Users.addOrganizationUser(organizationId);
    return executeRequestWithBody(url, user, { method });
  }

  enableUser(userId) {
    const { url, method } = jsRoutes.mvpstars.controllers.admin.Users.enable(userId);
    return executeRequest(url, { method });
  }

  disableUser(userId) {
    const { url, method } = jsRoutes.mvpstars.controllers.admin.Users.disable(userId);
    return executeRequest(url, { method });
  }
}

const userService = new UserService();

export default userService;
