import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { getErrorMessage } from '../../utils/ErrorHandling';

class CanvasBlock extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
    data: PropTypes.objectOf(PropTypes.string).isRequired,
    name: PropTypes.string.isRequired,
    boardId: PropTypes.string,
    iconName: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    canEdit: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      textareaValue: this.props.data[this.props.name],
      isEditable: this.props.canEdit,
    };
  }

  handleClick = () => this.textarea.focus();

  handleChange = event => this.setState({ textareaValue: event.target.value });

  handleBlur = event => {
    if (!this.state.isEditable) {
      return null;
    }
    if (this.props.data[this.props.name] !== event.target.value) {
      return this.props.data.set({ [this.props.name]: event.target.value }).catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
    } else {
      return null;
    }
  };

  render() {
    return (
      <div
        className={'canvasBlock ' + this.props.boardId + '-canvasBlock-' + this.props.name}
        onClick={this.handleClick}
      >
        <div className="canvasBlock-title">
          <i className={'canvasBlock-title-icon ' + this.props.iconName} />
          {this.props.label || this.props.name}
        </div>
        <textarea
          ref={textarea => (this.textarea = textarea)}
          className="canvasBlock-textarea"
          value={this.state.textareaValue}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          placeholder={this.props.placeholder}
          readOnly={!this.state.isEditable}
        />
      </div>
    );
  }
}

export default observer(CanvasBlock);
