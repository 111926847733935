import { detach, types } from 'mobx-state-tree';
import EventModel from './models/EventModel';
import organizationService from '../organizations/OrganizationsService';
import projectsStore from '../projects/ProjectsStore';
import { jsRoutes } from '../../../components/lib/RequestUtil';

let websocket = null;

const eventsStore = types
  .model('Events', {
    currentOrganization: types.maybe(types.string),
    events: types.array(EventModel),
  })
  .views(self => ({
    news(startAt = 0) {
      return self.events.slice(startAt, startAt + 10);
    },

    get latestImages() {
      const fileEvents = self.events.filter(event => event.event.files);
      const isImage = file => file.contentType.startsWith('image');
      const fileWithUrl = file => ({
        name: file.name,
        url: jsRoutes.controllers.Projects.getFile(file.id).url + '?inline=true',
      });
      const files = fileEvents.reduce(
        (acc, event) => acc.concat(event.event.files.files.filter(isImage).map(fileWithUrl)),
        []
      );
      return files.slice(0, 20);
    },
  }))
  .actions(self => ({
    open(organizationId) {
      if (!websocket) {
        self.currentOrganization = organizationId;
        self.events.forEach(detach);
        self.events = [];
        websocket = organizationService.events(self.currentOrganization);
        websocket.onclose = evt => {
          console.info('WS closed', evt);
        };
        websocket.onmessage = evt => {
          const message = JSON.parse(evt.data);
          if (message !== 'ping') {
            if (Array.isArray(message)) {
              self.addEvents(message);
            } else {
              projectsStore.handleEvent(message);
              self.addEvent(message);
            }
          }
        };
        websocket.onerror = evt => {
          console.warn('WS error', evt);
        };
        websocket.onopen = evt => {
          console.info('WS connected', evt);
          websocket.send(JSON.stringify({ command: 'Subscribe' }));
        };
      }
    },

    close() {
      if (websocket) {
        websocket.send(JSON.stringify({ command: 'Unsubscribe' }));
        websocket.close();
        websocket = null;
      }
    },

    addEvent(event) {
      // TODO: organizationId should be sent by the backend?
      event.organizationId = self.currentOrganization;
      const evt = EventModel.create(event);
      if (evt.label) {
        self.events.unshift(event);
        self.events.splice(100);
      }
      return evt;
    },

    addEvents(events) {
      const newEvents = events
        .map(event => self.addEvent(event))
        .filter(event => event && event.label)
        .reverse();
      newEvents.splice(100);
      self.events = newEvents;
    },
  }))
  .create({ events: [] });

export default eventsStore;
