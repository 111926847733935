/*global window */

import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from '../../../i18n';
import AddButton from '../../global/elems/AddButton';
import DeleteButton from '../../global/elems/DeleteButton';
import withFeedback from '../../global/feedback/withFeedback';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import { getColorForLearningStatusIcon, getLearningStatusIcon } from '../../global/utils/IconUtils';
import UserStore from '../../stores/user/UserStore';
import learningStatusEnum from '../../stores/projects/models/learnings/LearningStatusEnum';
import LearningForm from './LearningForm';
import './css/learnings.css';

class LearningsList extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    name: PropTypes.string,
    iconName: PropTypes.string,
    emptyText: PropTypes.string,
    learningType: PropTypes.string.isRequired,
    learnings: PropTypes.any.isRequired,
    project: PropTypes.shape({
      isMember: PropTypes.func.isRequired,
    }),
  };

  state = {
    text: '',
    status: learningStatusEnum.enum.ASSUMPTION,
    editing: false,
    editedLearning: null,
  };

  canAddOrDeleteOrEdit =
    !!UserStore.currentUser &&
    (UserStore.currentUser.hasOneOrManyAdminRoles || this.props.project.isMember(UserStore.currentUser.id));

  handleStartEditing = editedLearning => {
    if (this.canAddOrDeleteOrEdit) {
      this.setState({ editing: true, editedLearning });
    }
  };

  handleStartAdding = () => this.handleStartEditing(null);

  handleCancel = () => this.setState({ editing: false, editedLearning: null });

  handleSave = learning => {
    if (this.state.editedLearning) {
      this.state.editedLearning
        .save({
          text: learning.text,
          status: learning.status,
          type: this.props.learningType,
        })
        .catch(error => {
          this.props.showErrorMessage(getErrorMessage(error), 5000);
        });
    } else {
      this.props.onAdd(learning);
    }
    this.handleCancel();
  };

  onDelete = learning => event => {
    event.stopPropagation();
    if (window.confirm(i18n.t('learnings.confirmDelete', { learningText: learning.text }))) {
      this.props.onDelete(learning);
    }
  };

  renderEmptyList = () => (
    <div className="learnings-block-emptyList d-flex alignItems_center justifyContent_center flex_1 flexDirection_column">
      {this.props.emptyText}
      {this.renderAddButton(true)}
    </div>
  );

  renderLearningContent = learning => {
    const content = [
      <div
        className="learnings-block-content-line-text d-flex flexDirection_row alignItems_center"
        key="text"
        onClick={() => this.handleStartEditing(learning)}
      >
        <i
          className={'m-r-5 ' + getLearningStatusIcon(learning.status)}
          style={{ color: getColorForLearningStatusIcon(learning.status) }}
        />
        <div>{learning.text}</div>
      </div>,
      <DeleteButton
        key="trash"
        onClick={this.onDelete(learning)}
        title="Delete learning"
        className="learnings-block-btnTrash"
        canDelete={this.canAddOrDeleteOrEdit}
      />,
    ];
    if (learning.hasLink()) {
      content.push(<i className="learnings-block-content-line-cta ic-arrow-right" key="icon" />);
    }
    return content;
  };

  renderLearning = learning => {
    let result;
    if (learning.hasLink()) {
      result = (
        <a
          href={learning.link}
          className="learnings-block-content-line text_normal"
          key={learning.id}
          target="_blank"
          rel="noopener noreferrer"
        >
          {this.renderLearningContent(learning)}
        </a>
      );
    } else {
      result = (
        <div className="learnings-block-content-line text_normal" key={learning.id}>
          {this.renderLearningContent(learning)}
        </div>
      );
    }
    return result;
  };

  renderAddButton = isEmptyList => {
    return this.canAddOrDeleteOrEdit ? (
      <AddButton
        isEmptyList={isEmptyList}
        buttonText={i18n.t('learnings.add')}
        onClick={this.handleStartAdding}
        value={'add-' + this.props.learningType}
      />
    ) : null;
  };

  render() {
    return (
      <div className="learnings-block">
        <h3 className="learnings-title d-flex flexDirection_row alignItems_center">
          <i className={'learnings-title-icon ' + this.props.iconName} />
          {this.props.name}
          {this.renderAddButton()}
        </h3>
        <div className="card learnings-block-content">
          {this.props.learnings.length > 0 ? this.props.learnings.map(this.renderLearning) : this.renderEmptyList()}
          {this.state.editing ? (
            <LearningForm
              learningType={this.props.learningType}
              learning={this.state.editedLearning}
              onSave={this.handleSave}
              onCancel={this.handleCancel}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default withFeedback(observer(LearningsList));
