import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { RIEInput } from '@attently/riek';
import i18n from '../../i18n';
import withFeedback from '../global/feedback/withFeedback';
import { getErrorMessage } from '../global/utils/ErrorHandling';
import { getColorForProjectStateIcon, getProjectStateIcon } from '../global/utils/IconUtils';
import UserStore from '../stores/user/UserStore';
import './css/BoardHeader.css';

class BoardHeader extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
    project: PropTypes.shape({
      id: PropTypes.string.isRequired,
      isMember: PropTypes.func.isRequired,
      members: PropTypes.object.isRequired,
      name: PropTypes.string.isRequired,
      nbIdeas: PropTypes.number.isRequired,
      nbExperiments: PropTypes.number.isRequired,
      nbSolutions: PropTypes.number.isRequired,
      setName: PropTypes.func.isRequired,
      state: PropTypes.string.isRequired,
    }).isRequired,
  };

  canEdit =
    !!UserStore.currentUser &&
    (UserStore.currentUser.hasOneOrManyAdminRoles || this.props.project.isMember(UserStore.currentUser.id));

  _handleChange = value => {
    if (this.canEdit) {
      this.props.project.setName(value.name).catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
    }
  };

  navLink = (url, title, icon, iconStyle = {}) => (
    <NavLink exact className="tab text_normal" activeClassName="tab_selected" to={url} title={title}>
      <i className={`tab-icon ${icon}`} style={iconStyle} />
      <div className="tab-label">{title}</div>
    </NavLink>
  );

  render() {
    const projectState = this.props.project.state;
    return (
      <div className="board-header">
        <div className="board-header-left">
          <div
            className="board-header-left-icon-wrapper"
            style={{
              backgroundColor: getColorForProjectStateIcon(projectState),
            }}
            title={i18n.t('projects.' + projectState)}
          >
            <i className={'board-header-left-icon ' + getProjectStateIcon(projectState)} />
          </div>
          <div className="board-header-left-inner">
            <h3 className="board-header-title">
              <RIEInput
                value={this.props.project.name}
                propName="name"
                change={this._handleChange}
                validate={value => value.trim() !== ''}
                classEditing="input input_medium"
                isDisabled={!this.canEdit}
              />
            </h3>
            <div className="board-header-infos">
              <div className="board-header-info" title={i18n.t('numberOf.members')}>
                <i className="board-header-info-icon ic-users" />
                {this.props.project.members.length}
              </div>
              <div className="board-header-info" title={i18n.t('numberOf.ideas')}>
                <i className="board-header-info-icon ic-lightbulb_outline" />
                {this.props.project.nbIdeas}
              </div>
              <div className="board-header-info" title={i18n.t('numberOf.experiments')}>
                <i className="board-header-info-icon ic-rocket" />
                {this.props.project.nbExperiments}
              </div>
              <div className="board-header-info" title={i18n.t('numberOf.solutions')}>
                <i className="board-header-info-icon ic-check-circle" />
                {this.props.project.nbSolutions}
              </div>
            </div>
          </div>
        </div>
        <div className="board-header-right">
          <div className="tabs">
            {this.navLink(
              `/projects/${this.props.project.id}/business-challenge`,
              i18n.t('businessChallenge.title'),
              'ic-layout'
            )}
            {this.navLink(`/projects/${this.props.project.id}`, i18n.t('board.title'), 'ic-bar-chart-2', {
              transform: 'rotate(180deg)',
            })}
            {this.navLink(`/projects/${this.props.project.id}/todo`, i18n.t('todo.title'), 'ic-education-quiz')}
            {this.navLink(`/projects/${this.props.project.id}/learnings`, i18n.t('learnings.title'), 'ic-book-open')}
            {this.navLink(`/projects/${this.props.project.id}/files`, i18n.t('files.title'), 'ic-paperclip')}
            {this.navLink(`/projects/${this.props.project.id}/members`, i18n.t('members.title'), 'ic-users')}
          </div>
        </div>
      </div>
    );
  }
}

export default withFeedback(observer(BoardHeader));
