/*global window */

import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import DeleteButton from '../../global/elems/DeleteButton';
import CheckBox from '../../global/input/CheckBox';
import FileCallForStateEnum from './../../stores/projects/models/files/FileCallForEnum';
import FileStateCallFor from './FileStateCallFor';
import './css/file.css';

class File extends React.Component {
  static propTypes = {
    publicSwitch: PropTypes.bool,
    deleteFile: PropTypes.func,
    canUpdateOrDelete: PropTypes.bool,
    handleCheckBoxChange: PropTypes.func,
    handleSelectChange: PropTypes.func,
    file: PropTypes.shape({
      metadata: PropTypes.shape({
        callFor: PropTypes.oneOf(FileCallForStateEnum.keys),
        public: PropTypes.bool,
      }).isRequired,
      contentType: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      link: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    canUpdateOrDelete: false,
  };

  state = {
    callForValue: this.props.file.metadata.callFor || '',
  };

  onDelete = event => {
    event.preventDefault();
    event.stopPropagation();
    const file = this.props.file;
    if (window.confirm(i18n.t('files.confirmDelete', { fileName: file.name }))) {
      if (this.props.deleteFile) {
        this.props.deleteFile(file);
      }
    }
  };

  handleCheckBoxChange = file => value => {
    this.props.handleCheckBoxChange(file, value);
  };

  handleSelectChange = (file, value) => {
    this.setState({ callForValue: value });
    this.props.handleSelectChange(file, value);
  };

  getIcon = file => {
    if (file.contentType.indexOf('image/') === 0) {
      return <img className="files-block-content-line-icon" src={file.link + '?inline=true'} alt={file.name} />;
    }
    const filenameParts = file.name.split('.');
    return (
      <i
        className={`files-block-content-line-icon ic-file ic-document-file-${filenameParts[filenameParts.length - 1]}`}
      />
    );
  };

  renderFileBlockMetadata = file => {
    if (this.props.publicSwitch && this.props.canUpdateOrDelete) {
      const callFor = file.metadata.public ? (
        <FileStateCallFor
          editable={true}
          value={file.metadata.callFor}
          handleSelectChange={value => this.handleSelectChange(file, value)}
        />
      ) : null;
      return (
        <div className="file-block-metadata">
          {callFor}
          <CheckBox
            title={i18n.t('files.public')}
            value={file.metadata.public}
            onChange={this.handleCheckBoxChange(file)}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    const file = this.props.file;
    return (
      <div className="files-block-content-line text_normal">
        <a
          className="files-block-content-line-text"
          key="text"
          href={file.link}
          target="_blank"
          rel="noopener noreferrer"
          download={file.name}
        >
          {this.getIcon(file)} <span>{file.name}</span>
        </a>
        {this.renderFileBlockMetadata(file)}
        <DeleteButton
          onClick={this.onDelete}
          title="Delete file"
          className="files-block-btnTrash"
          canDelete={this.props.canUpdateOrDelete}
        />
      </div>
    );
  }
}

export default observer(File);
