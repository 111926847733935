/*global window */

// Global
import React from 'react';
import { observer } from 'mobx-react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// Component
import FullScreenLoader from '../../components/lib/loader/FullScreenLoader';
import NotFound from '../../components/notfound/NotFound';
import PageTracking from '../../components/lib/analytics/PageTracking';
// Moddules
import AdminPanel from '../admin/AdminPanel';
import BoardPanel from '../board/BoardPanel';
import BoardsPanel from '../boards/BoardsPanel';
import DashboardPanel from '../dashboard/DashboardPanel';
import EventsPanel from '../root/events/EventsPanel';
import Users from '../root/users/Users';
import Funnel from '../funnel/Funnel';
import HelpPanel from '../helps/HelpPanel';
import OrganizationStore from '../stores/organizations/OrganizationsStore';
import ProjectsStore from '../stores/projects/ProjectsStore';
import PublicGallery from '../public/PublicGallery';
import SearchPanel from '../search/SearchPanel';
import UserStore from '../stores/user/UserStore';
// Local folder
import ChangePassword from './ChangePassword';
import Header from './Header';
import Login from './Login';
import ResetPassword from './ResetPassword';
import './css/App.css';

class App extends React.Component {
  renderLoader() {
    return (
      <div className="app">
        <FullScreenLoader />
      </div>
    );
  }

  render() {
    if (window.location.pathname.startsWith('/public/')) {
      return (
        <BrowserRouter>
          <div className="app">
            <PageTracking>
              <Switch>
                <Route path="/public/:organizationName" component={PublicGallery} />
                <Route component={NotFound} />
              </Switch>
            </PageTracking>
          </div>
        </BrowserRouter>
      );
    }
    if (UserStore.isLogged === null) {
      return this.renderLoader();
    }
    if (!UserStore.isLogged) {
      return (
        <BrowserRouter>
          <div className="app">
            <PageTracking>
              <Switch>
                <Route path="/resetPassword/:tokenId" component={ResetPassword} />
                <Route component={Login} />
              </Switch>
            </PageTracking>
          </div>
        </BrowserRouter>
      );
    }
    if (!ProjectsStore.initialized || !OrganizationStore.initialized) {
      return this.renderLoader();
    }
    return (
      <BrowserRouter>
        <div className="app">
          <Header />
          <PageTracking>
            <Switch>
              {UserStore.currentUser.isAdmin ? <Route exact path="/root/events" component={EventsPanel} /> : null}
              {UserStore.currentUser.isAdmin ? <Route exact path="/root/users" component={Users} /> : null}
              <Route path="/search" component={SearchPanel} />
              <Route path="/helps/:helpId" component={HelpPanel} />
              <Route exact path="/projects" component={BoardsPanel} />
              <Route path="/projects/:projectId" component={BoardPanel} />
              <Route exact path="/" component={DashboardPanel} />
              <Route exact path="/admin" component={AdminPanel} />
              <Route exact path="/changePassword" component={ChangePassword} />
              <Route exact path="/in-dev/funnel" component={Funnel} />
              <Route component={NotFound} />
            </Switch>
          </PageTracking>
        </div>
      </BrowserRouter>
    );
  }
}

export default observer(App);
