import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';
import ExperimentStateLabel from '../board/experiment/ExperimentStateLabel';

class SearchExperiment extends React.Component {
  static propTypes = {
    experiment: PropTypes.shape({
      title: PropTypes.string,
      projectId: PropTypes.string.isRequired,
      ideaId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    nestingLevel: PropTypes.number,
  };

  static defaultProps = {
    nestingLevel: 0,
  };

  render() {
    const experiment = this.props.experiment;
    return (
      <div className="searchPanel-line" style={{ paddingLeft: 20 + this.props.nestingLevel * 30 }}>
        <i className="searchPanel-line-icon ic-rocket" />
        <div className="searchPanel-line-text">{experiment.title}</div>
        <ExperimentStateLabel value={experiment.state} />
        <Link
          title={i18n.t('see.experiment')}
          className="btn btn_primary btn_medium searchPanel-line-btn"
          to={'/projects/' + experiment.projectId + '/ideas/' + experiment.ideaId + '/experiments/' + experiment.id}
        >
          <i className="ic-arrow-right" />
        </Link>
      </div>
    );
  }
}

export default observer(SearchExperiment);
