import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from '../../../i18n';
import withFeedback from '../../global/feedback/withFeedback';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import UserStore from '../../stores/user/UserStore';
import Files from '../files/Files';
import ExperimentLearnings from './ExperimentLearnings';
import './css/results.css';

class Results extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    projectId: PropTypes.string,
    ideaId: PropTypes.string,
    project: PropTypes.shape({
      isMember: PropTypes.func.isRequired,
    }),
    experiment: PropTypes.shape({
      id: PropTypes.string,
      results: PropTypes.shape({
        nextSteps: PropTypes.string,
        text: PropTypes.string,
        set: PropTypes.func,
      }),
      resultFiles: PropTypes.shape({
        createExperimentResultFiles: PropTypes.func.isRequired,
        deleteExperimentResultFile: PropTypes.func.isRequired,
      }).isRequired,
    }),
  };

  state = {
    resultsValue: this.props.experiment && this.props.experiment.results ? this.props.experiment.results.text : '',
    nextStepsValue:
      this.props.experiment && this.props.experiment.results ? this.props.experiment.results.nextSteps : '',
  };

  canEdit =
    !!UserStore.currentUser &&
    (UserStore.currentUser.hasOneOrManyAdminRoles || this.props.project.isMember(UserStore.currentUser.id));

  handleChange = (key, value) => this.setState({ [key]: value });
  handleResultsChange = event => this.handleChange('resultsValue', event.target.value);
  handleNextStepsChange = event => this.handleChange('nextStepsValue', event.target.value);

  handleBlur = (key, value) => {
    if (!this.canEdit) {
      return null;
    }
    if (this.props.experiment.results[key] !== value) {
      return this.props.experiment.results.set({ [key]: value }).catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
    } else {
      return null;
    }
  };
  handleResultsBlur = event => {
    this.handleBlur('text', event.target.value);
  };
  handleNextStepsBlur = event => {
    this.handleBlur('nextSteps', event.target.value);
  };

  createFiles = files => {
    return this.props.experiment.resultFiles
      .createExperimentResultFiles(this.props.projectId, this.props.ideaId, this.props.experiment.id, files)
      .catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
  };

  deleteFile = file => {
    return this.props.experiment.resultFiles
      .deleteExperimentResultFile(this.props.projectId, this.props.ideaId, this.props.experiment.id, file)
      .catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
  };

  render() {
    return (
      <div className="results-row">
        <div className="results-column results-column-left">
          <h3>
            <i className="ic-test m-r-10" />
            {i18n.t('results.title')}
          </h3>
          <textarea
            className="m-b-20"
            rows={10}
            value={this.state.resultsValue}
            onChange={this.handleResultsChange}
            onBlur={this.handleResultsBlur}
            placeholder={this.props.placeholder}
            readOnly={!this.canEdit}
          />
          <ExperimentLearnings
            projectId={this.props.projectId}
            project={this.props.project}
            experiment={this.props.experiment}
            ideaId={this.props.ideaId}
            showErrorMessage={this.props.showErrorMessage}
          />
          <h3>
            <i className="ic-test m-r-10" />
            {i18n.t('nextSteps.title')}
          </h3>
          <textarea
            className="m-b-20"
            rows={10}
            value={this.state.nextStepsValue}
            onChange={this.handleNextStepsChange}
            onBlur={this.handleNextStepsBlur}
            placeholder={this.props.placeholder}
            readOnly={!this.canEdit}
          />
        </div>
        <div className="results-column results-column-right">
          <Files
            files={this.props.experiment ? this.props.experiment.resultFiles : {}}
            project={this.props.project}
            createFiles={this.createFiles}
            deleteFile={this.deleteFile}
          />
        </div>
      </div>
    );
  }
}

export default withFeedback(observer(Results));
