import { types } from 'mobx-state-tree';
import FileCallForEnum from './FileCallForEnum';

const FileMetadataModel = types
  .model('FileMetadata', {
    public: types.optional(types.boolean, false),
    callFor: types.optional(FileCallForEnum.type, FileCallForEnum.enum.IDEA),
  })
  .actions(self => ({
    togglePublicFile(value) {
      self.public = value;
    },
    setCallFor(value) {
      self.callFor = value;
    },
  }));

export default FileMetadataModel;
