import { flow, types } from 'mobx-state-tree';
import userStore from '../user/UserStore';
import RootService from './RootService';

const RootStore = types
  .model('RootStore', {})
  .actions(() => ({
    getAllUsers: flow(function* getAllUsers() {
      if (userStore.isLogged && userStore.currentUser && userStore.currentUser.isAdmin) {
        try {
          const { data } = yield RootService.getAllUsers();
          return data;
        } catch (err) {
          console.warn('getAllUser', err);
        }
      }
      return yield new Promise(resolve => resolve([]));
    }),

    rawEvents: flow(function* rawEvents() {
      if (userStore.isLogged && userStore.currentUser && userStore.currentUser.isAdmin) {
        try {
          const { data } = yield RootService.rawEvents();
          return data;
        } catch (error) {
          throw new Error(error);
        }
      }
      return yield new Promise(resolve => resolve([]));
    }),

    getUserIdFromEvent(event) {
      if (
        !event ||
        !event.auditTrail ||
        !event.auditTrail.created ||
        !event.auditTrail.created.by ||
        !event.auditTrail.created.by.identity
      ) {
        return undefined;
      }
      if (event.auditTrail.created.by.identity.hasOwnProperty('id')) {
        return event.auditTrail.created.by.identity.id;
      }
      return event.auditTrail.created.by.identity;
    },
  }))
  .create({});

export default RootStore;
