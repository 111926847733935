import { createEnhancedEnum } from '../../../../global/utils/EnumBuilder';

const IDEA_STATES = {
  IDEAS: 0,
  MVP: 1,
  TEST: 2,
  PPP: 3,
  SOLVED: 4,
};

const IdeaStateEnum = createEnhancedEnum('IdeaState', IDEA_STATES);

export default IdeaStateEnum;
