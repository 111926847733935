/* global window document Headers */

import { executeRequest, jsRoutes } from '../RequestUtil';

const facebookEventTypes = [
  'ViewContent',
  'Search',
  'AddToCart',
  'AddToWishlist',
  'InitiateCheckout',
  'AddPaymentInfo',
  'Purchase',
  'Lead',
  'CompleteRegistration',
];

const EventService = {
  alreadyIdentified: new Set(),

  trackPageView(page, title) {
    this.log({
      eventType: 'pageview',
      page,
      title,
    });

    if (typeof window.ga === 'function') {
      window.ga('set', 'page', page);
      window.ga('set', 'title', title);
      window.ga('send', 'pageview');
    }

    if (typeof window.fbq === 'function') {
      window.fbq('track', 'PageView');
    }

    if (typeof window.mixpanel === 'object') {
      window.mixpanel.track(page, { title });
    }
  },

  trackEventDeferred(eventType, category, otherProps) {
    setTimeout(() => {
      try {
        this.trackEvent(eventType, category, otherProps);
      } catch (e) {
        //nothing to do
      }
    });
  },

  trackEvent(eventType, category, otherProps) {
    const evt = { eventType, category, ...otherProps };
    this.log(evt);

    if (typeof window.ga === 'function') {
      if (otherProps) {
        window.ga('send', 'event', category, eventType, otherProps.label, otherProps.value);
      } else {
        window.ga('send', 'event', category, eventType);
      }
    }

    if (typeof window.fbq === 'function' && facebookEventTypes.indexOf(eventType) >= 0) {
      if (eventType === 'Purchase') {
        window.fbq('track', eventType, { value: otherProps.value, currency: otherProps.label });
      } else {
        window.fbq('track', eventType);
      }
    }

    if (typeof window.mixpanel === 'object') {
      window.mixpanel.track(category + '.' + eventType, otherProps);
      if (eventType === 'Purchase') {
        window.mixpanel.people.track_charge(otherProps.value, {
          currency: otherProps.label,
          ...otherProps,
        });
      }
    }
  },

  registerDeferred(props) {
    setTimeout(() => {
      try {
        this.register(props);
      } catch (e) {
        //nothing to do
      }
    });
  },

  register(props) {
    if (typeof window.mixpanel === 'object') {
      window.mixpanel.register(props);
    }
  },

  identifyDeferred(id) {
    setTimeout(() => {
      try {
        this.identify(id);
      } catch (e) {
        //nothing to do
      }
    });
  },

  identify(id) {
    if (typeof window.mixpanel === 'object') {
      if (!this.alreadyIdentified.has(id)) {
        this.alreadyIdentified.add(id);
        window.mixpanel.alias(id);
        window.mixpanel.identify(id);
        window.mixpanel.people.set({ $email: id });
      }
    }
  },

  log(evt) {
    if (typeof evt === 'string') {
      evt = { eventType: evt };
    }

    // TODO: Manage A/B testing tracking
    // evt.standardFeature = standardFeature;

    const { url, method } = jsRoutes.mvpstars.controllers.Events.create();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    if (process.env.BROWSER === 'true' && document.referer) {
      headers.append('Referer', document.referer);
    }
    return executeRequest(url, {
      method,
      body: JSON.stringify(evt),
      headers,
    });
  },
};

export default EventService;
