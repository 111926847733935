import { executeRequest, executeRequestWithBody, jsRoutes } from '../../../components/lib/RequestUtil';

class ProjectsService {
  getProjects(organizationId) {
    const { url, method } = jsRoutes.controllers.Projects.getProjects(organizationId);
    return executeRequest(url, { method });
  }

  create(project) {
    const { url, method } = jsRoutes.controllers.Projects.createProject(project.organization);
    return executeRequestWithBody(url, project, { method });
  }

  delete(project) {
    const { url, method } = jsRoutes.controllers.Projects.deleteProject(project.organization, project.id);
    return executeRequest(url, { method });
  }

  setName(project, name) {
    const { url, method } = jsRoutes.controllers.Projects.renameProject(project.id);
    return executeRequestWithBody(url, { name }, { method });
  }

  setBusinessChallenge(project, businessChallenge) {
    const { url, method } = jsRoutes.controllers.Projects.setContext(project.id);
    return executeRequestWithBody(url, businessChallenge, { method });
  }

  addMember(project, memberId) {
    const { url, method } = jsRoutes.controllers.Projects.addMember(project.id, memberId);
    return executeRequest(url, { method });
  }

  removeMember(project, memberId) {
    const { url, method } = jsRoutes.controllers.Projects.removeMember(project.id, memberId);
    return executeRequest(url, { method });
  }

  searchFilesInContentFiles(organizationId, text) {
    const { url, method } = jsRoutes.controllers.Projects.getFilesFromSearchContent(organizationId, text);
    return executeRequest(url, { method });
  }
}

const projectsService = new ProjectsService();

export default projectsService;
