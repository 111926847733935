import React from 'react';
import PropTypes from 'prop-types';
import BoardHeader from './BoardHeader';
import BoardColumns from './columns/BoardColumns';
import TodoBoardColumns from './todo/TodoBoardColumns';
import BoardLearnings from './learnings/BoardLearnings';
import BoardMembers from './members/BoardMembers';
import BoardContext from './context/BoardContext';
import projectsStore from '../stores/projects/ProjectsStore';
import { Redirect, Route, Switch } from 'react-router-dom';
import CreateExperimentModal from './experiment/CreateExperimentModal';
import ExperimentModal from './experiment/ExperimentModal';
import IdeaModal from './idea/IdeaModal';
import ActionModal from './actions/ActionModal';
import BoardFiles from './files/BoardFiles';

class BoardPanel extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        projectId: PropTypes.string.isRequired,
      }),
    }).isRequired,
    location: PropTypes.shape({}).isRequired,
  };

  project;

  UNSAFE_componentWillMount() {
    this.project =
      this.props.match.params && this.props.match.params.projectId
        ? projectsStore.getProjectById(this.props.match.params.projectId) || null
        : null;
  }

  render() {
    if (this.project === null || !this.project.isVisible) {
      return null;
    }
    return (
      <div className="panel">
        <BoardHeader project={this.project} location={this.props.location} />
        <Switch>
          <Redirect exact from="/projects/:projectId/context" to="/projects/:projectId/business-challenge" />
          <Route
            exact
            path="/projects/:projectId/business-challenge"
            component={() => <BoardContext project={this.project} />}
          />
          <Route
            exact
            path="/projects/:projectId/learnings"
            component={() => <BoardLearnings project={this.project} />}
          />
          <Route exact path="/projects/:projectId/files" component={() => <BoardFiles project={this.project} />} />
          <Route exact path="/projects/:projectId/members" component={() => <BoardMembers project={this.project} />} />
          <Route exact path="/projects/:projectId/todo" component={() => <TodoBoardColumns project={this.project} />} />
          <Route path="/projects/:projectId/" component={() => <BoardColumns project={this.project} />} />
        </Switch>
        <Switch>
          <Route exact path="/projects/:projectId/ideas/:ideaId/experiments/new" component={CreateExperimentModal} />
          <Route path="/projects/:projectId/ideas/:ideaId/experiments/:experimentId" component={ExperimentModal} />
          <Route path="/projects/:projectId/ideas/:ideaId" component={IdeaModal} />
          <Route path="/projects/:projectId/actions/:actionId" component={ActionModal} />
        </Switch>
      </div>
    );
  }
}

export default BoardPanel;
