import { flow, types } from 'mobx-state-tree';
import { caseInsensitiveSearch } from '../../../../../components/lib/SearchUtil';
import ActionsService from '../../ActionsService';
import LearningsService from '../../LearningsService';
import composeTask from '../tasks/composeTask';
import ActionTypeEnum from './ActionTypeEnum';

const ActionModel = composeTask(
  'Action',
  ActionsService,
  LearningsService.addActionLearning,
  LearningsService.deleteActionLearning,
  types
    .model({
      title: types.string,
      text: types.optional(types.string, ''),
      type: types.optional(ActionTypeEnum.type, ActionTypeEnum.enum.NONE),
    })
    .views(self => ({
      contains(text) {
        return caseInsensitiveSearch(self.title, text) || caseInsensitiveSearch(self.text, text);
      },

      searchFiles(text) {
        return self.files.searchFiles(text);
      },

      getFileFromId(fileId) {
        return self.files.getFileFromId(fileId);
      },
    }))
    .actions(self => ({
      setTitle: flow(function* setTitle(title) {
        yield ActionsService.setTitle(self, title);
        self.title = title;
      }),

      setText: flow(function* setText(text) {
        yield ActionsService.setText(self, text);
        self.text = text;
      }),

      setType: flow(function* setType(type) {
        yield ActionsService.setType(self, type);
        self.type = type;
      }),

      handleEvent(event) {
        if (event && event.event && event.eventType) {
          switch (event.eventType) {
            case 'ActionTitleUpdated':
              return self.checkAlreadyProcessedEvent(event, event => {
                self.title = event.event.title;
                return true;
              });
            case 'ActionTextUpdated':
              return self.checkAlreadyProcessedEvent(event, event => {
                self.text = event.event.text;
                return true;
              });
            case 'ActionTypeUpdated':
              return self.checkAlreadyProcessedEvent(event, event => {
                self.type = event.event.type;
                return true;
              });
            case 'ActionStatusUpdated':
              return self.checkAlreadyProcessedEvent(event, event => {
                return self._setStatus(event.event.status);
              });
            case 'ActionAssigneeAdded':
              return self.handleAssigneeAddedEvent(event);
            case 'ActionAssigneeRemoved':
              return self.handleAssigneeRemovedEvent(event);
            case 'ActionLearningAdded':
              // TODO: Is it not the same than LearningCreated event below?
              // Is it used? Because it does nothing in terms of assignment.
              return self.checkAlreadyProcessedEvent(event, () => true);
            case 'ActionLearningRemoved':
              // TODO: Is it not the same than LearningDeleted event below?
              // Is it used? Because it does nothing in terms of assignment.
              return self.checkAlreadyProcessedEvent(event, () => true);
            case 'LearningCreated':
              return self.handleLearningCreatedEvent(event);
            case 'LearningDeleted':
              return self.handleLearningDeletedEvent(event);
            case 'ActionFilesAdded':
              return self.checkAlreadyProcessedEvent(event, self.files.handleEvent);
            case 'ActionFileRemoved':
              return self.checkAlreadyProcessedEvent(event, self.files.handleEvent);
            default:
              return self.handleDefaultEvent(event);
          }
        }
        console.warn('ActionModel does not know how to handle event ', event);
        return false;
      },
    }))
);

export default ActionModel;
