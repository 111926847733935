import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import CreateModal from '../../global/elems/CreateModal';
import withFeedback from '../../global/feedback/withFeedback';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import projectsStore from '../../stores/projects/ProjectsStore';

class CreateExperimentModal extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
    hideMessage: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        projectId: PropTypes.string,
        ideaId: PropTypes.string,
      }),
    }).isRequired,
    history: PropTypes.shape({}),
  };

  project = null;
  idea = null;

  handleCreate = name => {
    this.idea
      .addExperiment(this.project.id, this.idea.id, { title: name })
      .then(() => {
        this.props.hideMessage();
      })
      .catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
  };

  UNSAFE_componentWillMount() {
    this.project =
      this.props.match.params && this.props.match.params.projectId
        ? projectsStore.getProjectById(this.props.match.params.projectId) || null
        : null;
    this.idea =
      this.project && this.props.match.params && this.props.match.params.ideaId
        ? this.project.getIdeaById(this.props.match.params.ideaId) || null
        : null;
  }

  componentDidMount() {
    if (this.idea) {
      this.createModal.open();
    }
  }

  render() {
    if (this.idea === null) {
      return null;
    }
    return (
      <CreateModal
        ref={createModal => (this.createModal = createModal)}
        title={i18n.t('experiment.new')}
        label={i18n.t('experiment.title.label')}
        createButtonText={i18n.t('experiment.create')}
        placeholder={i18n.t('experiment.title.placeholder')}
        handleCreate={this.handleCreate}
        withRouter={true}
        history={this.props.history}
      />
    );
  }
}

export default withFeedback(CreateExperimentModal);
