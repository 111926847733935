import React from 'react';
import { observer } from 'mobx-react';
import OrganizationsStore from '../stores/organizations/OrganizationsStore';
import UserStore from '../stores/user/UserStore';
import { jsRoutes } from '../../components/lib/RequestUtil';
import i18n from '../../i18n';
import CheckBox from '../global/input/CheckBox';
import Logo from './Logo';
import Groups from './groups/Groups';
import Users from './users/Users';
import './css/AdminPanel.css';

class AdminPanel extends React.Component {
  render() {
    if (UserStore.currentUser.hasOneOrManyAdminRoles) {
      const organization = OrganizationsStore.getOrganizationById(UserStore.currentUser.currentOrganization);
      return (
        <div className="panel">
          <div className="adminPanel-block">
            <h3 className="adminPanel-title">
              <i className="adminPanel-title-icon ic-dashboard2" />
              <div className="adminPanel-title-text">
                {i18n.t('admin.organizationAdmin', {
                  organizationName: organization.name,
                })}
              </div>
            </h3>
            <div className="card adminPanel-block-content">
              <div className="adminPanel-block-content-line">
                <h4>{i18n.t('admin.logo.title')}</h4>
                <Logo
                  file={organization.logo}
                  updateLogo={file => organization.updateLogo(organization.id, file)}
                  deleteLogo={() => organization.deleteLogo(organization.id)}
                />
              </div>
            </div>
            <div className="card adminPanel-block-content">
              <div className="adminPanel-block-content-line">
                <CheckBox
                  label={i18n.t('admin.scaleColumnHiddenLabel')}
                  value={organization.scaleColumnHidden}
                  text={i18n.t('admin.scaleColumnHidden')}
                  onChange={organization.hideScaleColumn}
                />
              </div>
            </div>
            <div className="card adminPanel-block-content">
              <a
                className="adminPanel-block-content-line"
                href={jsRoutes.controllers.Projects.zipExport(organization.id).url}
              >
                <h4>
                  <i className="ic-download m-r-10" />
                  {i18n.t('admin.exportAll')}
                </h4>
              </a>
            </div>
            <Groups organization={organization} />
            <Users organization={organization} users={UserStore.colleagues} />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default observer(AdminPanel);
