import i18n from '../../../i18n';
import EnumLabel from '../../global/elems/EnumLabel';
import { getColorForActionStatusIcon, getActionStatusIcon } from '../../global/utils/IconUtils';
import TaskStatusEnum from './../../stores/projects/models/tasks/TaskStatusEnum';
import './css/taskStatusLabel.css';

const TaskStatusLabel = EnumLabel(
  TaskStatusEnum,
  value => i18n.t('task.status.' + value),
  getActionStatusIcon,
  'taskStatusLabel',
  getColorForActionStatusIcon
);

export default TaskStatusLabel;
