/* global window */

import React from 'react';
import { getDisplayName } from '../HOCUtils';

export default function scrollToTopOnMount(Component) {
  return class ScrollToTopOnMount extends React.Component {
    static displayName = `scrollToTopOnMount(${getDisplayName(Component)})`;

    componentDidMount() {
      if (process.env.BROWSER === 'true') {
        window.scrollTo(0, 0);
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  };
}
