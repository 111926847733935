import React from 'react';
import PropTypes from 'prop-types';

class Member extends React.PureComponent {
  static propTypes = {
    member: PropTypes.shape({
      fullName: PropTypes.string,
      email: PropTypes.string.isRequired,
      id: PropTypes.string,
      avatarURL: PropTypes.string,
    }),
    className: PropTypes.string,
    onClick: PropTypes.func,
    thumbnail: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    thumbnail: false,
  };

  render() {
    const member = this.props.member;
    if (member) {
      const name = member.fullName ? member.fullName : member.email;
      if (!this.props.thumbnail) {
        return (
          <div className={this.props.className} key={member.id} onClick={this.props.onClick}>
            <img src={member.avatarURL} className="member-avatar" alt={member.fullName} />
            <span>{name}</span>
          </div>
        );
      }
      return (
        <span className={this.props.className} key={member.id}>
          <img src={member.avatarURL} className="member-thumbnail" alt={member.fullName} title={member.fullName} />
        </span>
      );
    }
    return null;
  }
}

export default Member;
