import { createEnhancedEnum } from '../../../../global/utils/EnumBuilder';

const VALIDATION_STATUSES = {
  REQUESTED: 0,
  REFUSED: 1,
  APPROVED: 2,
};

const ValidationStatusEnum = createEnhancedEnum('ValidationStatus', VALIDATION_STATUSES);

export default ValidationStatusEnum;
