import React from 'react';
import PropTypes from 'prop-types';
import withFeedback from '../global/feedback/withFeedback';
import { getErrorMessage } from '../global/utils/ErrorHandling';
import i18n from '../../i18n';
import userStore from '../stores/user/UserStore';
import './css/User.css';

class Login extends React.Component {
  static propTypes = {
    hideMessage: PropTypes.func.isRequired,
    showErrorMessage: PropTypes.func.isRequired,
    showSuccessMessage: PropTypes.func.isRequired,
  };

  state = { forgotPassword: false, email: '', password: '' };

  componentDidMount() {
    this.emailInput && this.emailInput.focus();
  }

  handleEmailFieldChange = event => {
    this.setState({ email: event.target.value });
  };

  handlePasswordFieldChange = event => {
    this.setState({ password: event.target.value });
  };

  handleSubmitLogin = event => {
    event.preventDefault();
    userStore.login(this.state).catch(error => {
      this.props.showErrorMessage(getErrorMessage(error));
    });
  };

  handleSubmitForgotPassword = event => {
    event.preventDefault();
    userStore
      .forgotPassword(this.state.email)
      .then(data => {
        this.props.showSuccessMessage(i18n.t('user.' + data.ok));
      })
      .catch(error => {
        this.props.showErrorMessage(getErrorMessage(error));
      });
  };

  handleForgotPassword = event => {
    event.preventDefault();
    this.props.hideMessage();
    this.setState(
      {
        forgotPassword: !this.state.forgotPassword,
      },
      () => this.emailInput.focus()
    );
  };

  render() {
    return (
      <form
        className="user-form"
        onSubmit={this.state.forgotPassword ? this.handleSubmitForgotPassword : this.handleSubmitLogin}
      >
        <div className="center-square card">
          <i className="ic-logo user-logo" />
          <label className="form-title">
            {i18n.t(this.state.forgotPassword ? 'user.resetPassword.request' : 'user.welcomeBack')}
          </label>
          <input
            ref={input => {
              this.emailInput = input;
            }}
            className={`user-email-input input ${this.state.forgotPassword ? 'm-b-40' : 'm-b-10'}`}
            type="email"
            value={this.state.email}
            placeholder={i18n.t('user.signIn.email')}
            onChange={this.handleEmailFieldChange}
          />
          {this.state.forgotPassword ? (
            <div>
              <button
                className="user-submit-button btn btn_medium btn_medium_square btn_close m-r-20"
                type="button"
                onClick={this.handleForgotPassword}
                value={i18n.t('user.cancel')}
              >
                {i18n.t('user.cancel')}
              </button>
              <button
                className="user-submit-button btn btn_medium btn_medium_square btn_primary m-l-20"
                type="submit"
                value={i18n.t('user.submit')}
              >
                {i18n.t('user.submit')}
              </button>
            </div>
          ) : (
            [
              <input
                key="password"
                className="user-password-input input m-b-40"
                type="password"
                value={this.state.password}
                placeholder={i18n.t('user.signIn.password')}
                onChange={this.handlePasswordFieldChange}
              />,
              <button
                key="submit"
                className="user-submit-button btn btn_medium btn_medium_square btn_primary m-b-10"
                type="submit"
                value={i18n.t('user.submit')}
              >
                {i18n.t('user.submit')}
              </button>,
              <a key="forgotPassword" href="#forgotPassword" onClick={this.handleForgotPassword} className="m-t-10">
                {i18n.t('user.forgotPassword')}
              </a>,
            ]
          )}
        </div>
      </form>
    );
  }
}

export default withFeedback(Login);
