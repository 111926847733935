import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './css/BoardColumnHeader.css';

class BoardColumnHeader extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    iconName: PropTypes.string,
    projectId: PropTypes.string,
    helpId: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  openHelp = () => this.props.history.push('/helps/' + this.props.helpId + '?back=/projects/' + this.props.projectId);

  render() {
    return (
      <div className="board-column-header">
        <i className={'board-column-header-icon ' + this.props.iconName} />
        <div className="board-column-header-title">{this.props.name}</div>
        <div className="board-column-header-help">
          {this.props.helpId ? <i className="ic-help-circle" onClick={this.openHelp} /> : null}
        </div>
      </div>
    );
  }
}

export default withRouter(BoardColumnHeader);
