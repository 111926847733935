import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from '../../../i18n';
import AddButton from '../../global/elems/AddButton';
import withFeedback from '../../global/feedback/withFeedback';
import Members from '../../global/members/Members';
import UserStore from '../../stores/user/UserStore';
import CreateUserModal from './CreateUserModal';
import './css/users.css';
import { getErrorMessage } from '../../global/utils/ErrorHandling';

class Users extends React.Component {
  static propTypes = {
    users: PropTypes.object.isRequired,
    showErrorMessage: PropTypes.func.isRequired,
  };

  canAddOrDelete = UserStore.currentUser && UserStore.currentUser.hasOneOrManyAdminRoles;

  handleStartAdding = () => this.createModal.open();

  handleRemove = userId => {
    return UserStore.removeFromOrganization(userId).catch(error => {
      const errorMessage = getErrorMessage(error);
      this.props.showErrorMessage(i18n.t('admin.users.remove.requestError', { errorMessage }), 5000);
    });
  };

  handleEnableUser = userId => {
    return UserStore.enableUser(userId).catch(error => {
      const errorMessage = getErrorMessage(error);
      this.props.showErrorMessage(i18n.t('admin.users.enable.requestError', { errorMessage }), 5000);
    });
  };

  handleDisableUser = userId => {
    return UserStore.disableUser(userId).catch(error => {
      const errorMessage = getErrorMessage(error);
      this.props.showErrorMessage(i18n.t('admin.users.disable.requestError', { errorMessage }), 5000);
    });
  };

  renderAddButton = isEmptyList => {
    return this.canAddOrDelete ? (
      <AddButton isEmptyList={isEmptyList} buttonText={i18n.t('members.add')} onClick={this.handleStartAdding} />
    ) : null;
  };

  render() {
    return (
      <div className="users card adminPanel-block-content">
        <div className="adminPanel-block-content-line">
          <h4 className="d-flex flexDirection_row alignItems_center">
            <i className="ic-user m-r-10" />
            {i18n.t('admin.users.title')}
          </h4>
          <span>
            &nbsp;
            {i18n.t('admin.users.nbUsers', { count: this.props.users.length })}
          </span>
          {this.renderAddButton()}
          <CreateUserModal ref={c => (this.createModal = c)} onError={err => this.props.showErrorMessage(err, 5000)} />
        </div>
        <div className="adminPanel-users">
          <Members
            members={this.props.users}
            membersList={this.props.users.map(user => user)}
            removeMember={this.handleRemove}
            confirmDeleteMessage="admin.users.confirmDelete"
            enableUser={this.handleEnableUser}
            disableUser={this.handleDisableUser}
          />
        </div>
      </div>
    );
  }
}

export default withFeedback(observer(Users));
