/*global document */

import React from 'react';
import ReactDOM from 'react-dom';
import './polyfills.js';
import './fonts/acre/acre.css';
import './fonts/icons/icons.css';
import './css/buttons.css';
import './css/cards.css';
import './css/forms.css';
import './css/index.css';
import './css/modals.css';
import './css/panels.css';
import './css/tabs.css';
import './css/utils/coreUtils.css';
import './css/utils/flexUtils.css';
import './css/utils/headerUtils.css';
import './css/utils/marginUtils.css';
import './css/utils/paddingUtils.css';
import './css/utils/textUtils.css';
import './css/utils/sizeUtils.css';
import App from './modules/main/App';
// Disable service worker to avoid caching issues: import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
// Disable service worker to avoid caching issues: registerServiceWorker();
unregister();
