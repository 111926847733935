import React from 'react';
import PropTypes from 'prop-types';

export default class DeleteButton extends React.PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    canDelete: PropTypes.bool,
    size: PropTypes.string,
    square: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    canDelete: true,
    size: 'medium',
    square: false,
    className: '',
  };

  render() {
    if (!this.props.canDelete) {
      return null;
    }
    const sizeClass = `btn_${this.props.size}`;
    const className = this.props.square ? `${sizeClass} ${sizeClass}_square` : sizeClass;
    return (
      <div
        className={`btn ${className} btn_close ${this.props.className}`}
        onClick={this.props.onClick}
        title={this.props.title}
      >
        <i className="ic-trash btn-icon" />
      </div>
    );
  }
}
