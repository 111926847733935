import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../global/elems/Modal';

class CreateModal extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    createButtonText: PropTypes.string.isRequired,
    handleCreate: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    withRouter: PropTypes.bool,
    history: PropTypes.shape({
      goBack: PropTypes.func,
    }),
  };

  static defaultProps = {
    placeholder: '',
  };

  state = {
    name: '',
  };

  open = () => this.modal.open();

  close = () => this.modal.close();

  focusInput = () => this.input.focus();

  handleNameChange = event => this.setState({ name: event.target.value });

  handleCreate = () => {
    if (this.state.name.trim() !== '') {
      this.props.handleCreate(this.state.name);
      this.close();
    }
  };

  onSubmit = event => {
    event.preventDefault();
    this.handleCreate();
  };

  render() {
    const { withRouter, history } = this.props;
    return (
      <Modal ref={c => (this.modal = c)} onOpened={this.focusInput} withRouter={withRouter} history={history}>
        <h2 className="modal-title">{this.props.title}</h2>
        <form className="form-group" onSubmit={this.onSubmit}>
          <label>{this.props.label}</label>
          <input
            ref={c => (this.input = c)}
            className="input"
            value={this.state.name}
            onChange={this.handleNameChange}
            placeholder={this.props.placeholder}
          />
        </form>
        <div className="d-flex flexDirection_row">
          <div className="btn btn_ok btn_medium m-r-5" onClick={this.handleCreate}>
            {this.props.createButtonText}
          </div>
          <div className="btn btn_transparent btn_medium" onClick={this.close}>
            <i className="ic-x btn-icon" />
          </div>
        </div>
      </Modal>
    );
  }
}

export default CreateModal;
