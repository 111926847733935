import React from 'react';
import PropTypes from 'prop-types';
import './Loader.css';

export default class Loader extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.number,
  };

  static defaultProps = {
    size: 64,
  };

  render() {
    const { size } = this.props;
    const style = { width: size, height: size };
    if (this.props.color) {
      style.color = this.props.color;
    }
    return (
      <div className={'loader dark' + (this.props.className ? ' ' + this.props.className : '')} style={style}>
        <div style={{ borderWidth: size / 16 }} />
      </div>
    );
  }
}
