import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './css/StatCard.css';
import i18n from '../../i18n';

class StatCard extends React.Component {
  static propTypes = {
    stat: PropTypes.shape({
      iconName: PropTypes.string,
      label: PropTypes.string,
      link: PropTypes.string,
      value: PropTypes.number,
    }).isRequired,
  };

  render() {
    if (this.props.stat.link) {
      return (
        <Link to={this.props.stat.link} className="card statCard">
          <div className="statCard-icon-wrapper">
            <i className={'statCard-icon ' + this.props.stat.iconName} />
          </div>
          <div className="statCard-value">{this.props.stat.value}</div>
          <div className="statCard-label">{i18n.t(this.props.stat.label, { count: this.props.stat.value })}</div>
        </Link>
      );
    } else {
      return (
        <div className="card statCard">
          <div className="statCard-icon-wrapper">
            <i className={'statCard-icon ' + this.props.stat.iconName} />
          </div>
          <div className="statCard-value">{this.props.stat.value}</div>
          <div className="statCard-label">{i18n.t(this.props.stat.label, { count: this.props.stat.value })}</div>
        </div>
      );
    }
  }
}

export default StatCard;
