import React from 'react';
import PropTypes from 'prop-types';
import { jsRoutes } from '../../components/lib/RequestUtil';
import { caseInsensitiveSearch } from '../../components/lib/SearchUtil';
import i18n from '../../i18n';
import logoImage from '../../public/images/logo.png';
import { getPublicFileCallForIcon } from '../global/utils/IconUtils';
import PublicGalleryModel from '../stores/public/models/PublicGalleryModel';
import facebookImage from './images/facebook.png';
import instagramImage from './images/instagram.png';
import rssImage from './images/rss.png';
import twitterImage from './images/twitter.png';
import './css/PublicGallery.css';

class PublicGallery extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationName: PropTypes.string.isRequired,
      }),
    }).isRequired,
  };

  state = {
    organization: null,
    searchValue: '',
  };

  componentDidMount() {
    const organizationName =
      this.props.match.params && this.props.match.params.organizationName
        ? this.props.match.params.organizationName
        : null;
    if (organizationName) {
      PublicGalleryModel.getPublicProjects(organizationName)
        .then(organizationWithItsPublicFiles => {
          const organization = organizationWithItsPublicFiles;
          organization.logo = organization.logo
            ? jsRoutes.controllers.Projects.getFile(organization.logo.id).url
            : logoImage;
          this.setState({ organization });
        })
        .catch(error => {
          // TODO Fix error handling using withFeedback HOC ?
          console.error('componentDidMount', error);
          this.setState({ error });
        });
    }
  }

  getCallForSentence = callForValue =>
    !callForValue || callForValue === '' ? '' : i18n.t('files.callForSentence.' + callForValue);

  renderNoCard = () => (
    <div className="public-gallery-no-project">
      <p>There is no call for projet today, come back soon!</p>
    </div>
  );

  renderFileCard = (file, index, array) => (
    <div key={file.id} className="public-gallery-file">
      <i className={'public-gallery-file-icon ' + getPublicFileCallForIcon(file.metadata.callFor)} />
      <div className={'public-gallery-file-cta' + (array.length === index + 1 ? ' public-gallery-last-file' : '')}>
        <div>{this.getCallForSentence(file.metadata.callFor) + ' ' + file.name}</div>
        <div>
          <a
            href={jsRoutes.controllers.Projects.getFile(file.id).url}
            target="_blank"
            rel="noopener noreferrer"
            download={file.name}
          >
            Apply now
          </a>
        </div>
      </div>
    </div>
  );

  handleChange = event => this.setState({ searchValue: event.target.value });

  renderSearch() {
    return (
      <div className="public-gallery-search-engine">
        <i className="ic-search" />
        <input
          className="public-gallery-search-input"
          placeholder="Search ..."
          value={this.state.searchValue}
          onChange={this.handleChange}
        />
      </div>
    );
  }

  renderSocialNetwork = () => (
    <div className="public-gallery-social-network">
      <a href="#rss">
        <img src={rssImage} alt="rss" />
      </a>
      <a href="https://www.linkedin.com/company/reacteev">
        <img src={facebookImage} alt="facebook" />
      </a>
      <a href="https://www.twitter.com">
        <img src={twitterImage} alt="twitter" />
      </a>
      <a href="https://www.instagram.com">
        <img src={instagramImage} alt="instagram" />
      </a>
    </div>
  );

  render() {
    if (!this.state.organization) {
      return null;
    }
    const organizationName = this.state.organization.name ? this.state.organization.name : null;
    const publicProjects = this.state.organization.projects ? this.state.organization.projects : null;
    const allFiles =
      publicProjects && publicProjects.length > 0
        ? publicProjects.map(project => project.files).reduce((accumulator, currentValue) => {
            return accumulator.concat(currentValue);
          })
        : null;
    return (
      <div className="container">
        <div className="header">
          <img src={this.state.organization.logo} alt={organizationName} className="header-organization-logo" />
        </div>
        <div className="public-gallery">
          <h1 className="textTransform_uppercase">{i18n.t('publicGallery.organizationName', { organizationName })}</h1>
          <h3 className="textTransform_uppercase">{i18n.t('publicGallery.welcome', { organizationName })}</h3>
          <h3>
            <i>{i18n.t('publicGallery.openMarket')}</i>
          </h3>
          <div className="public-gallery-header">
            {this.renderSearch()}
            {this.renderSocialNetwork()}
          </div>
          <h2 className="textTransform_uppercase">{i18n.t('publicGallery.openInnovation.title')}</h2>
          <p>{i18n.t('publicGallery.openInnovation.text')}</p>
          <div className="public-gallery-files">
            {allFiles && allFiles.length > 0
              ? allFiles
                  .filter(file => caseInsensitiveSearch(file.name, this.state.searchValue))
                  .map(this.renderFileCard)
              : this.renderNoCard()}
          </div>
          <h2 className="textTransform_uppercase">{i18n.t('publicGallery.howToCollaborate.title')}</h2>
          <p>
            {i18n.t('publicGallery.howToCollaborate.text', { organizationName })}
            <br />
            <br />
            {i18n.t('publicGallery.howToCollaborate.sendProposal')}
            &nbsp;
            <a href="#contact-us">contact us</a>
          </p>
          <div className="public-gallery-footer">
            <i className="ic-logo public-gallery-footer-logo" />
            <p>
              {i18n.t('publicGallery.footer.text', { organizationName })}
              <br />
              <a href="#legal">{i18n.t('publicGallery.footer.legal')}</a>
              <br />
              <a href="#contact">{i18n.t('publicGallery.footer.contact')}</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PublicGallery;
