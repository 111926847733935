import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import i18n from '../../../i18n';
import DeleteButton from '../elems/DeleteButton';
import withFeedback from '../feedback/withFeedback';
import { getErrorMessage } from '../utils/ErrorHandling';
import '../../../css/dropzone.css';
import './css/imagePicker.css';

class ImagePicker extends React.Component {
  static propTypes = {
    onImageUpdated: PropTypes.func.isRequired,
    onImageDeleted: PropTypes.func.isRequired,
    imageUrl: PropTypes.string,
    showErrorMessage: PropTypes.func.isRequired,
  };

  state = {
    sending: false,
  };

  onDrop = files => {
    if (files.length > 0 && !this.state.sending) {
      this.setState({ sending: true });
      this.props
        .onImageUpdated(files[0])
        .catch(error => {
          this.props.showErrorMessage(getErrorMessage(error), 5000);
        })
        .then(() => {
          this.setState({ sending: false });
        });
    } else {
      this.setState({ sending: false });
    }
  };

  onDelete = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onImageDeleted();
  };

  render() {
    const style = this.props.imageUrl ? { backgroundImage: 'url(' + this.props.imageUrl + ')' } : {};
    return (
      <div className="imagePicker">
        <Dropzone
          multiple={false}
          accept="image/*"
          className="dropzone visible"
          activeClassName="active"
          acceptClassName="accept"
          rejectClassName="reject"
          onDropAccepted={this.onDrop}
          style={style}
        >
          <i className={this.state.sending ? 'ic-save' : 'ic-file-plus'} />
          {i18n.t(this.state.sending ? 'files.uploading' : 'admin.logo.clickOrDrag')}
        </Dropzone>
        <DeleteButton
          onClick={this.onDelete}
          title="Delete image"
          className="imagePicker-btnTrash"
          canDelete={!!this.props.imageUrl}
        />
      </div>
    );
  }
}

export default withFeedback(ImagePicker);
