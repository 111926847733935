/* global window */

import ReconnectingWebSocket from 'reconnecting-websocket';
import { executeRequest, executeRequestWithBody, jsRoutes } from '../../../components/lib/RequestUtil';

class OrganizationsService {
  all() {
    const { url, method } = jsRoutes.controllers.Organizations.getOrganizations();
    return executeRequest(url, { method });
  }

  events(organizationId) {
    const url = jsRoutes.controllers.Organizations.eventStream(organizationId).webSocketURL(
      window.location.protocol === 'https:'
    );
    return new ReconnectingWebSocket(url);
  }

  hideScaleColumn(organizationId, scaleColumnHidden) {
    const { url, method } = jsRoutes.controllers.Organizations.hideScaleColumn(organizationId, scaleColumnHidden);
    return executeRequest(url, { method });
  }

  createGroup(organizationId, group) {
    const { url, method } = jsRoutes.controllers.Organizations.createGroup(organizationId);
    return executeRequestWithBody(url, group, { method });
  }

  renameGroup(organizationId, groupId, name) {
    const { url, method } = jsRoutes.controllers.Organizations.renameGroup(organizationId, groupId);
    return executeRequestWithBody(url, { name }, { method });
  }

  deleteGroup(organizationId, groupId) {
    const { url, method } = jsRoutes.controllers.Organizations.deleteGroup(organizationId, groupId);
    return executeRequest(url, { method });
  }

  addGroupMember(organizationId, groupId, memberId) {
    const { url, method } = jsRoutes.controllers.Organizations.addGroupMember(organizationId, groupId, memberId);
    return executeRequest(url, { method });
  }

  removeGroupMember(organizationId, groupId, memberId) {
    const { url, method } = jsRoutes.controllers.Organizations.removeGroupMember(organizationId, groupId, memberId);
    return executeRequest(url, { method });
  }
}

const organizationsService = new OrganizationsService();
export default organizationsService;
