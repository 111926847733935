import React from 'react';
import PropTypes from 'prop-types';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import ActionTypeEnum from '../../stores/projects/models/actions/ActionTypeEnum';
import EmpathizeCard from './EmpathizeCard';

class EmpathizeCardsList extends React.Component {
  static propTypes = {
    project: PropTypes.shape({
      id: PropTypes.string.isRequired,
      tasks: PropTypes.shape({
        actions: PropTypes.oneOfType([PropTypes.array, MobxPropTypes.observableArray]).isRequired,
      }).isRequired,
    }).isRequired,
  };

  renderAction = action => {
    return <EmpathizeCard action={action} key={action.id} projectId={this.props.project.id} />;
  };

  render() {
    return (
      <div>
        {this.props.project.tasks.actions
          .filter(action => action.type === ActionTypeEnum.enum.EMPATHIZE)
          .map(this.renderAction)}
      </div>
    );
  }
}

export default observer(EmpathizeCardsList);
