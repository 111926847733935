/* eslint-disable react/display-name */
import React from 'react';
import SearchAction from './SearchAction';
import SearchError from './SearchError';
import SearchFile from './SearchFile';
import SearchIdea from './SearchIdea';
import SearchLearning from './SearchLearning';
import SearchExperiment from './SearchExperiment';
import SearchProject from './SearchProject';

export function groupBy(arr, keyExtractor) {
  const res = {};
  arr.forEach(item => {
    const key = keyExtractor(item);
    if (key in res) {
      res[key].push(item);
    } else {
      res[key] = [item];
    }
  });
  return res;
}

export function mapProperties(obj, fn) {
  const res = [];
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      res.push(fn(obj[key]));
    }
  }
  return res;
}

export const renderProject = project => <SearchProject project={project} key={project.id} />;

export const renderIdea = nestingLevel => idea => <SearchIdea idea={idea} key={idea.id} nestingLevel={nestingLevel} />;

export const renderExperiment = nestingLevel => experiment => (
  <SearchExperiment experiment={experiment} key={experiment.id} nestingLevel={nestingLevel} />
);

export const renderLearning = nestingLevel => learning => (
  <SearchLearning learning={learning} key={learning.id} nestingLevel={nestingLevel} />
);

export const renderAction = nestingLevel => action => (
  <SearchAction action={action} key={action.id} nestingLevel={nestingLevel} />
);

export const renderFile = nestingLevel => file => <SearchFile file={file} key={file.id} nestingLevel={nestingLevel} />;

export const renderError = () => <SearchError />;

export const renderLearningsByAction = nestingLevel => learningsByAction =>
  learningsByAction[0].actionId ? (
    <div className="searchPanel-group" key={learningsByAction[0].actionId}>
      {renderAction(nestingLevel)(learningsByAction[0].action)}
      {learningsByAction.map(renderLearning(nestingLevel + 1))}
    </div>
  ) : (
    learningsByAction.map(renderLearning(nestingLevel))
  );

export const renderLearningsByIdea = nestingLevel => learningsByIdea =>
  learningsByIdea[0].ideaId ? (
    <div className="searchPanel-group" key={learningsByIdea[0].ideaId}>
      {renderIdea(nestingLevel)(learningsByIdea[0].idea)}
      {mapProperties(groupBy(learningsByIdea, learning => learning.experimentId), learningsByExperiment => (
        <div className="searchPanel-group" key={learningsByExperiment[0].experimentId}>
          {renderExperiment(nestingLevel + 1)(learningsByExperiment[0].experiment)}
          {learningsByExperiment.map(renderLearning(nestingLevel + 2))}
        </div>
      ))}
    </div>
  ) : (
    mapProperties(groupBy(learningsByIdea, learning => learning.actionId), renderLearningsByAction(nestingLevel))
  );

export const renderFilesByAction = nestingLevel => filesByAction =>
  filesByAction[0].actionId ? (
    <div className="searchPanel-group" key={filesByAction[0].actionId}>
      {renderAction(nestingLevel)(filesByAction[0].action)}
      {filesByAction.map(renderFile(nestingLevel + 1))}
    </div>
  ) : (
    filesByAction.map(renderFile(nestingLevel))
  );

export const renderFilesByIdea = nestingLevel => filesByIdea =>
  filesByIdea[0].ideaId ? (
    <div className="searchPanel-group" key={filesByIdea[0].ideaId}>
      {renderIdea(nestingLevel)(filesByIdea[0].idea)}
      {mapProperties(groupBy(filesByIdea, file => file.experimentId), filesByExperiment => (
        <div className="searchPanel-group" key={filesByExperiment[0].experimentId}>
          {renderExperiment(nestingLevel + 1)(filesByExperiment[0].experiment)}
          {filesByExperiment.map(renderFile(nestingLevel + 2))}
        </div>
      ))}
    </div>
  ) : (
    mapProperties(groupBy(filesByIdea, file => file.actionId), renderFilesByAction(nestingLevel))
  );
