import { flow, getParent, types } from 'mobx-state-tree';
import userStore from '../../user/UserStore';
import OrganizationsService from '../OrganizationsService';

const OrganizationGroupModel = types
  .model('OrganizationGroup', {
    id: types.identifier,
    name: types.string,
    members: types.array(types.string),
  })
  .views(self => ({
    get organizationId() {
      return getParent(self, 2).id;
    },

    get membersList() {
      const members = self.members.map(memberId => userStore.colleaguesMap[memberId]);
      return members.filter(member => member !== undefined);
    },
  }))
  .actions(self => ({
    renameGroup: flow(function* renameGroup(name) {
      if (self.name !== name) {
        yield OrganizationsService.renameGroup(self.organizationId, self.id, name);
        if (self.name !== name) {
          self.name = name;
        }
      }
    }),

    addMember: flow(function* addMember(memberId) {
      if (!self.members.includes(memberId)) {
        yield OrganizationsService.addGroupMember(self.organizationId, self.id, memberId);
        if (!self.members.includes(memberId)) {
          self.members.push(memberId);
        }
      }
    }),

    removeMember: flow(function* removeMember(memberId) {
      if (self.members.includes(memberId)) {
        yield OrganizationsService.removeGroupMember(self.organizationId, self.id, memberId);
        const memberIndex = self.members.indexOf(memberId);
        if (memberIndex > -1) {
          self.members.splice(memberIndex, 1);
        }
      }
    }),
  }));

export default OrganizationGroupModel;
