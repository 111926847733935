const KEYBOARD = {
  ESCAPE: {
    key: 'Escape',
    code: 27,
    keyCode: 27,
    which: 27,
  },
  ENTER: {
    key: 'Enter',
    name: 'Enter',
    keyCode: 13,
    which: 13,
  },
};

export default KEYBOARD;
