import React from 'react';
import './css/funnel.css';

class Funnel extends React.Component {
  assumptions = [
    { id: '0' },
    { id: '1' },
    { id: '2' },
    { id: '3' },
    { id: '4' },
    { id: '5' },
    { id: '6' },
    { id: '7' },
    { id: '8' },
    { id: '9' },
  ];
  ideas_desire = [{ id: '0' }, { id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }, { id: '5' }];
  ideas_feasable = [{ id: '0' }, { id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }];
  ideas_viable = [{ id: '0' }, { id: '1' }, { id: '2' }, { id: '3' }];
  experiments = [{ id: '1' }, { id: '2' }];

  renderEmptyBlock = block => {
    return (
      <div key={block.id} className="funnel-stage-item">
        &nbsp;
      </div>
    );
  };

  render() {
    return (
      <div className="funnel panel">
        <div className="funnel-container">
          <div className="funnel-block border">
            <div className="funnel-stage-header">Empathy</div>
            <div className="funnel-stage-container">{this.assumptions.map(this.renderEmptyBlock)}</div>
          </div>
          <div className="funnel-block">
            <div className="funnel-stage-header">
              &nbsp;
              <br />
              Desirability
            </div>
            <div className="funnel-stage-container">{this.ideas_desire.map(this.renderEmptyBlock)}</div>
          </div>
          <div className="funnel-block">
            <div className="funnel-stage-header">
              Explore
              <br />
              Feasability
            </div>
            <div className="funnel-stage-container">{this.ideas_feasable.map(this.renderEmptyBlock)}</div>
          </div>
          <div className="funnel-block border">
            <div className="funnel-stage-header">
              &nbsp;
              <br />
              Viability
            </div>
            <div className="funnel-stage-container">{this.ideas_viable.map(this.renderEmptyBlock)}</div>
          </div>
          <div className="funnel-block">
            <div className="funnel-stage-header">Scale</div>
            <div className="funnel-stage-container">{this.experiments.map(this.renderEmptyBlock)}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Funnel;
