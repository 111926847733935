/* global window */

import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { NavLink, Route, Switch } from 'react-router-dom';
import { RIEInput } from '@attently/riek';
import 'url-search-params-polyfill';
import i18n from '../../../i18n';
import DeleteButton from '../../global/elems/DeleteButton';
import Modal from '../../global/elems/Modal';
import withFeedback from '../../global/feedback/withFeedback';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import projectsStore from '../../stores/projects/ProjectsStore';
import UserStore from '../../stores/user/UserStore';
import ActionLearnings from '../actions/ActionLearnings';
import Files from '../files/Files';
import TaskAssignees from '../tasks/TaskAssignees';
import ActionDescription from './ActionDescription';
import './css/actionModal.css';

class ActionModal extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        projectId: PropTypes.string.isRequired,
        actionId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  };

  project = null;
  action = null;
  canEditOrRemove = false;

  UNSAFE_componentWillMount() {
    this.project =
      this.props.match.params && this.props.match.params.projectId
        ? projectsStore.getProjectById(this.props.match.params.projectId) || null
        : null;
    this.action =
      this.project && this.props.match.params.actionId
        ? this.project.getActionById(this.props.match.params.actionId) || null
        : null;
    this.canEditOrRemove =
      !!UserStore.currentUser &&
      (UserStore.currentUser.hasOneOrManyAdminRoles || this.project.isMember(UserStore.currentUser.id));
  }

  handleTitleChange = value =>
    this.action.setTitle(value.title).catch(error => {
      this.props.showErrorMessage(getErrorMessage(error), 5000);
    });

  onDelete = action => event => {
    event.stopPropagation();
    if (window.confirm(i18n.t('action.confirmDelete', { actionTitle: action.title }))) {
      this.project.tasks.deleteAction(this.action).catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
      this.action = null;
      this.modal.close();
    }
  };

  handleBack = () => {
    const backUrl = new URLSearchParams(this.props.location.search).get('back');
    if (backUrl) {
      this.props.history.push(backUrl);
    } else {
      this.props.history.push('/projects/' + this.project.id + '/todo');
    }
  };

  renderURLSearchBack = () => {
    const backUrl = new URLSearchParams(this.props.location.search).get('back');
    return backUrl ? '?back=' + new URLSearchParams(this.props.location.search).get('back') : '';
  };

  createFiles = files => {
    return this.action.files.createActionFiles(this.project.id, this.action.id, files);
  };

  deleteFile = file => {
    return this.action.files.deleteActionFile(this.project.id, this.action.id, file).catch(error => {
      this.props.showErrorMessage(getErrorMessage(error), 5000);
    });
  };

  renderTab = tab => (
    <NavLink
      exact
      to={
        '/projects/' +
        this.project.id +
        '/actions/' +
        this.action.id +
        '/' +
        (tab.default ? '' : tab.id) +
        this.renderURLSearchBack()
      }
      key={tab.id}
      className="tab tab_small text_normal"
      activeClassName="tab_selected"
      title={tab.label}
    >
      <i className={'tab-icon ' + tab.iconName} />
      <div className="tab-label">{tab.label}</div>
    </NavLink>
  );

  render() {
    if (this.action === null) {
      this.handleBack();
      return null;
    }

    const TABS = [
      {
        default: true,
        id: 'description',
        label: i18n.t('action.description'),
        iconName: 'ic-layout',
        component: (
          <ActionDescription
            action={this.action}
            canEditOrRemove={this.canEditOrRemove}
            showErrorMessage={this.props.showErrorMessage}
          />
        ),
      },
      {
        id: 'assignees',
        label: i18n.t('task.assignees.title'),
        iconName: 'ic-users',
        component: (
          <TaskAssignees
            task={this.action}
            canAddOrRemove={this.canEditOrRemove}
            membersList={this.project.membersList}
            showErrorMessage={this.props.showErrorMessage}
          />
        ),
      },
      {
        id: 'learnings',
        label: i18n.t('learnings.title'),
        iconName: 'ic-book-open',
        component: (
          <ActionLearnings
            projectId={this.project.id}
            project={this.project}
            action={this.action}
            showErrorMessage={this.props.showErrorMessage}
          />
        ),
      },
      {
        id: 'files',
        label: i18n.t('files.title'),
        iconName: 'ic-paperclip',
        component: (
          <Files
            files={this.action.files}
            project={this.project}
            createFiles={this.createFiles}
            deleteFile={this.deleteFile}
          />
        ),
      },
    ];
    let tabs = TABS.slice();

    return (
      <Modal
        ref={modal => (this.modal = modal)}
        className="actionModal"
        drawer={true}
        withRouter={true}
        history={this.props.history}
        backLabel={i18n.t('board.backToBoard')}
        onBack={this.handleBack}
      >
        <div className="modal-title d-flex flexDirection_row alignItems_center">
          <i className="ic-assignment m-r-15 text_big" />
          <h2 className="m-0 d-flex flexDirection_row alignItems_center">
            <RIEInput
              value={this.action.title}
              propName="title"
              change={this.handleTitleChange}
              validate={value => value.trim() !== ''}
              classEditing="input input_medium"
              isDisabled={!this.canEditOrRemove}
            />
            <DeleteButton
              onClick={this.onDelete(this.action)}
              title="Delete action"
              square={true}
              className="actionModal-btnTrash"
              canDelete={this.canEditOrRemove}
            />
          </h2>
          <div className="flex_1" />
          <div className="tabs m-t-5 m-b-10">{tabs.map(this.renderTab)}</div>
        </div>

        <div className="modal-content">
          <Switch>
            {tabs.map(tab => (
              <Route
                key={tab.id}
                exact
                path={'/projects/:projectId/actions/:actionId/' + (tab.default ? '' : tab.id)}
                component={() => {
                  return React.cloneElement(tab.component);
                }}
              />
            ))}
          </Switch>
        </div>
      </Modal>
    );
  }
}

export default withFeedback(observer(ActionModal));
