import { executeRequest, executeRequestWithBody, jsRoutes } from '../../../components/lib/RequestUtil';

class ExperimentsService {
  setTitle(experiment, title) {
    const { url, method } = jsRoutes.controllers.Experiments.setTitle(
      experiment.projectId,
      experiment.ideaId,
      experiment.id
    );
    return executeRequestWithBody(url, { title }, { method });
  }

  setState(experiment, state) {
    const { url, method } = jsRoutes.controllers.Experiments.setState(
      experiment.projectId,
      experiment.ideaId,
      experiment.id,
      state
    );
    return executeRequest(url, { method });
  }

  setStatus(experiment, status) {
    const { url, method } = jsRoutes.controllers.Experiments.setStatus(
      experiment.projectId,
      experiment.ideaId,
      experiment.id,
      status
    );
    return executeRequest(url, { method });
  }

  setExperimentCard(experiment, experimentCard) {
    const { url, method } = jsRoutes.controllers.Experiments.setExperimentCard(
      experiment.projectId,
      experiment.ideaId,
      experiment.id
    );
    return executeRequestWithBody(url, experimentCard, { method });
  }

  setResults(experiment, results) {
    const { url, method } = jsRoutes.controllers.Experiments.setResults(
      experiment.projectId,
      experiment.ideaId,
      experiment.id
    );
    return executeRequestWithBody(url, results, { method });
  }

  setSolutions(experiment, solutions) {
    const { url, method } = jsRoutes.controllers.Experiments.setSolutions(
      experiment.projectId,
      experiment.ideaId,
      experiment.id
    );
    return executeRequestWithBody(url, solutions, { method });
  }

  requestValidation(experiment, groups) {
    const { url, method } = jsRoutes.controllers.Experiments.requestExperimentValidation(
      experiment.projectId,
      experiment.ideaId,
      experiment.id
    );
    return executeRequestWithBody(url, groups, { method });
  }

  addAssignee(experiment, assigneeId) {
    const { url, method } = jsRoutes.controllers.Experiments.addAssignee(
      experiment.projectId,
      experiment.ideaId,
      experiment.id,
      assigneeId
    );
    return executeRequestWithBody(url, { assigneeId }, { method });
  }

  removeAssignee(experiment, assigneeId) {
    const { url, method } = jsRoutes.controllers.Experiments.removeAssignee(
      experiment.projectId,
      experiment.ideaId,
      experiment.id,
      assigneeId
    );
    return executeRequestWithBody(url, { assigneeId }, { method });
  }
}

const experimentsService = new ExperimentsService();
export default experimentsService;
