import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import AddButton from '../../global/elems/AddButton';
import Members from '../../global/members/Members';
import i18n from '../../../i18n';

class TaskAssignees extends React.Component {
  static propTypes = {
    task: PropTypes.shape({
      assigneesList: PropTypes.arrayOf(PropTypes.object),
      addAssignee: PropTypes.func,
      removeAssignee: PropTypes.func,
    }).isRequired,
    canAddOrRemove: PropTypes.bool,
    membersList: PropTypes.arrayOf(PropTypes.object),
    showErrorMessage: PropTypes.func.isRequired,
  };

  state = {
    adding: false,
  };

  handleStartAdding = () => this.setState({ adding: true });

  handleCancelAdding = () => this.setState({ adding: false });

  addAssignee = memberId => {
    return this.props.task.addAssignee(memberId);
  };

  removeAssignee = memberId => {
    return this.props.task.removeAssignee(memberId);
  };

  renderAddButton = isEmptyList => {
    return this.props.canAddOrRemove ? (
      <AddButton isEmptyList={isEmptyList} buttonText={i18n.t('task.assignees.add')} onClick={this.handleStartAdding} />
    ) : null;
  };

  render() {
    return (
      <div className="board-members">
        <div className="board-members-blocks">
          <div className="members-block">
            <h3 className="members-title d-flex flexDirection_row alignItems_center">
              <i className="ic-users m-r-10" />
              {i18n.t('task.assignees.title')}
              {this.renderAddButton()}
            </h3>
            <Members
              members={this.props.task.assigneesList}
              membersList={this.props.membersList}
              allowAddRemoveSelf={true}
              addMember={this.addAssignee}
              removeMember={this.removeAssignee}
              adding={this.state.adding}
              startAdding={this.handleStartAdding}
              cancelAdding={this.handleCancelAdding}
              showErrorMessage={this.props.showErrorMessage}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default observer(TaskAssignees);
