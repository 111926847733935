/*global window */

import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { withRouter } from 'react-router-dom';
import DeleteButton from '../../global/elems/DeleteButton';
import withFeedback from '../../global/feedback/withFeedback';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import ideaStateEnum from '../../stores/projects/models/ideas/IdeaStateEnum';
import UserStore from '../../stores/user/UserStore';
import ExperimentsList from './ExperimentsList';
import './css/BoardIdeaCard.css';

class BoardIdeaCard extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
  };

  canEditDelete =
    !!UserStore.currentUser &&
    (UserStore.currentUser.hasOneOrManyAdminRoles || this.props.project.isMember(UserStore.currentUser.id));

  onDelete = event => {
    event.stopPropagation();
    if (window.confirm(`Do you really want to delete the idea "${this.props.idea.title}" ?`)) {
      this.props.project.ideas.deleteIdea(this.props.idea).catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
    }
  };

  goToIdea = () => this.props.history.push('/projects/' + this.props.project.id + '/ideas/' + this.props.idea.id);

  renderContent = provided => (
    <div
      className="board-ideaCard"
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={provided.draggableProps.style}
    >
      <DeleteButton
        onClick={this.onDelete}
        title="Delete idea"
        size="small"
        square={true}
        className="boardIdeaCard-btnTrash"
        canDelete={this.canEditDelete}
      />
      <div className="board-ideaCard-title" onClick={this.goToIdea}>
        <i className="ic-lightbulb_outline m-r-5" />
        {this.props.idea.title}
      </div>
      {this.props.idea.experiments.length > 0 || this.props.column.id !== ideaStateEnum.enum.IDEAS ? (
        <ExperimentsList
          project={this.props.project}
          idea={this.props.idea}
          experiments={this.props.idea.experiments}
          column={this.props.column}
        />
      ) : null}
    </div>
  );

  render() {
    if (!this.canEditDelete) {
      return (
        <div className="board-ideaCard">
          <div className="board-ideaCard-title" onClick={this.goToIdea}>
            <i className="ic-lightbulb_outline m-r-5" />
            {this.props.idea.title}
          </div>
          {this.props.idea.experiments.length > 0 || this.props.column.id !== ideaStateEnum.enum.IDEAS ? (
            <ExperimentsList
              project={this.props.project}
              idea={this.props.idea}
              experiments={this.props.idea.experiments}
              column={this.props.column}
            />
          ) : null}
        </div>
      );
    }
    return (
      <Draggable draggableId={this.props.idea.id} index={this.props.index}>
        {this.renderContent}
      </Draggable>
    );
  }
}

export default withRouter(withFeedback(BoardIdeaCard));
