/* global window */

import React from 'react';
import { getDisplayName } from '../../../components/lib/HOCUtils';
import Feedback from './Feedback';

export default function withFeedback(Component) {
  return class FeedbackWrapper extends React.Component {
    static displayName = `withFeedback(${getDisplayName(Component)})`;

    static WrappedComponent = Component;

    state = {
      feedbackMessage: null,
      feedbackClass: null,
    };

    hideMessage = () => {
      this.setState({
        feedbackMessage: null,
        feedbackClass: null,
      });
    };

    hideMessageIfValue = feedbackMessage => {
      if (this.state.feedbackMessage === feedbackMessage) {
        this.hideMessage();
      }
    };

    showFeedbackMessage = (feedbackMessage, feedbackClass, callbackDelay = 0, callback = this.hideMessageIfValue) => {
      // TODO: The function can be called when the component is unmounted.
      // It was the case with ResetPassword.js before 10/24/2018
      // @see https://github.com/jaredpalmer/formik/issues/772
      this.setState({ feedbackMessage, feedbackClass }, () => {
        if (callback && callbackDelay > 0) {
          window.setTimeout(() => {
            callback(feedbackMessage);
          }, callbackDelay);
        }
      });
    };

    showErrorMessage = (errorMessage, callbackDelay = 0, callback = this.hideMessageIfValue) => {
      this.showFeedbackMessage(errorMessage, 'error', callbackDelay, callback);
    };

    showSuccessMessage = (successMessage, callbackDelay = 0, callback = this.hideMessageIfValue) => {
      this.showFeedbackMessage(successMessage, 'success', callbackDelay, callback);
    };

    render() {
      return (
        <div className="feedback-wrapper">
          <Feedback feedbackMessage={this.state.feedbackMessage} feedbackClass={this.state.feedbackClass} />
          <Component
            {...this.props}
            hideMessage={this.hideMessage}
            showFeedbackMessage={this.showFeedbackMessage}
            showErrorMessage={this.showErrorMessage}
            showSuccessMessage={this.showSuccessMessage}
          />
        </div>
      );
    }
  };
}
