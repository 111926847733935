import i18n from '../../../i18n';
import EnumLabel from '../../global/elems/EnumLabel';
import { getColorForProjectStateIcon, getProjectStateIcon } from '../../global/utils/IconUtils';
import IdeaStateEnum from '../../stores/projects/models/ideas/IdeaStateEnum';
import './css/ideaStateLabel.css';

const IdeaStateLabel = EnumLabel(
  IdeaStateEnum,
  value => i18n.t('idea.' + value),
  getProjectStateIcon,
  'ideaStateLabel',
  getColorForProjectStateIcon
);

export default IdeaStateLabel;
