import i18n from '../../../i18n';
import EnumLabel from '../../global/elems/EnumLabel';
import { getPublicFileCallForIcon } from '../../global/utils/IconUtils';
import FileCallForEnum from './../../stores/projects/models/files/FileCallForEnum';
import './css/fileStateCallFor.css';

const FileStateCallFor = EnumLabel(
  FileCallForEnum,
  value => i18n.t('files.callFor.' + value),
  getPublicFileCallForIcon,
  'callForStateLabel'
);

export default FileStateCallFor;
