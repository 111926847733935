/* global document */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { locationHasChanged } from '../routing/LocationUtils';
import EventService from './EventService';

class PageTracking extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.shape({}),
  };

  componentDidMount() {
    this.logPageView();
  }

  componentDidUpdate(prevProps) {
    if (locationHasChanged(this.props.location, prevProps.location)) {
      this.logPageView();
    }
  }

  render() {
    return this.props.children;
  }

  logPageView() {
    if (process.env.BROWSER === 'true' || process.browser === true) {
      const {
        location: { pathname, search, hash },
      } = this.props;
      const title = document.title;
      setTimeout(() => {
        EventService.trackPageView(pathname + search + hash, title);
      }, 100);
    }
  }
}

export default withRouter(PageTracking);
