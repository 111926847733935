import ExperimentStateEnum from '../../stores/projects/models/experiments/ExperimentStateEnum';
import ideaStateEnum from '../../stores/projects/models/ideas/IdeaStateEnum';
import learningStatusEnum from '../../stores/projects/models/learnings/LearningStatusEnum';
import FileCallForEnum from '../../stores/projects/models/files/FileCallForEnum';
import TaskStatusEnum from '../../stores/projects/models/tasks/TaskStatusEnum';

function getPublicFileCallForIcon(fileCallForState) {
  let stateIcon;
  switch (fileCallForState) {
    case FileCallForEnum.enum.IDEA:
      stateIcon = 'ic-lightbulb_outline';
      break;
    case FileCallForEnum.enum.PROJECT:
      stateIcon = 'ic-folder';
      break;
    case FileCallForEnum.enum.PROPOSAL:
      stateIcon = 'ic-rocket';
      break;
    case FileCallForEnum.enum.INFORMATION:
      stateIcon = 'ic-check-circle';
      break;
    default:
  }
  return stateIcon;
}

function getProjectStateIcon(projectState) {
  let stateIcon;
  switch (projectState) {
    case ideaStateEnum.enum.IDEAS:
      stateIcon = 'ic-lightbulb_outline';
      break;
    case ideaStateEnum.enum.SOLVED:
      stateIcon = 'ic-check';
      break;
    case ideaStateEnum.enum.MVP:
      stateIcon = 'ic-rocket';
      break;
    case ideaStateEnum.enum.TEST:
      stateIcon = 'ic-test';
      break;
    case ideaStateEnum.enum.PPP:
      stateIcon = 'ic-test-quiz';
      break;
    default:
  }
  return stateIcon;
}

function getColorForProjectStateIcon(projectState) {
  let color;
  switch (projectState) {
    case ideaStateEnum.enum.IDEAS:
      color = '#FDD760';
      break;
    case ideaStateEnum.enum.SOLVED:
      color = '#B4F9BC';
      break;
    case ideaStateEnum.enum.MVP:
      color = '#7795F8';
      break;
    case ideaStateEnum.enum.TEST:
      color = '#6BD5F8';
      break;
    case ideaStateEnum.enum.PPP:
      color = '#0063FF';
      break;
    default:
  }
  return color;
}

function getExperimentStateIcon(experimentState) {
  let iconName;
  switch (experimentState) {
    case ExperimentStateEnum.enum.BUILDING:
      iconName = 'ic-sketch';
      break;
    case ExperimentStateEnum.enum.TESTING:
      iconName = 'ic-test';
      break;
    case ExperimentStateEnum.enum.ACCEPTED:
      iconName = 'ic-check';
      break;
    case ExperimentStateEnum.enum.PPP:
      iconName = 'ic-test-quiz';
      break;
    case ExperimentStateEnum.enum.REFUSED:
      iconName = 'ic-x';
      break;
    case ExperimentStateEnum.enum.PIVOTED:
      iconName = 'ic-rotate-ccw';
      break;
    default:
  }
  return iconName;
}

function getColorForExperimentStateIcon(experimentState) {
  let color;
  switch (experimentState) {
    case ExperimentStateEnum.enum.BUILDING:
      color = '#f8a534';
      break;
    case ExperimentStateEnum.enum.TESTING:
      color = '#6BD5F8';
      break;
    case ExperimentStateEnum.enum.PPP:
      color = '#0063FF';
      break;
    case ExperimentStateEnum.enum.ACCEPTED:
      color = '#68ff7a';
      break;
    case ExperimentStateEnum.enum.REFUSED:
      color = '#E25950';
      break;
    case ExperimentStateEnum.enum.PIVOTED:
      color = '#7795F8';
      break;
    default:
  }
  return color;
}

function getLearningStatusIcon(learningStatus) {
  let statusIcon;
  switch (learningStatus) {
    case learningStatusEnum.enum.VALIDATED:
      statusIcon = 'ic-check';
      break;
    case learningStatusEnum.enum.REJECTED:
      statusIcon = 'ic-x';
      break;
    case learningStatusEnum.enum.ASSUMPTION:
      statusIcon = 'ic-ask';
      break;
    default:
  }
  return statusIcon;
}

function getColorForLearningStatusIcon(learningStatus) {
  let color;
  switch (learningStatus) {
    case learningStatusEnum.enum.VALIDATED:
      color = '#68ff7a';
      break;
    case learningStatusEnum.enum.REJECTED:
      color = '#E25950';
      break;
    case learningStatusEnum.enum.ASSUMPTION:
      color = '#7795F8';
      break;
    default:
  }
  return color;
}

function getActionWorkflowIcon(actionStatus) {
  let statusIcon;
  switch (actionStatus) {
    case TaskStatusEnum.enum.BACKLOG:
      statusIcon = 'ic-education-quiz';
      break;
    case TaskStatusEnum.enum.TODO:
      statusIcon = 'ic-square';
      break;
    case TaskStatusEnum.enum.INPROGRESS:
      statusIcon = 'ic-test-quiz';
      break;
    case TaskStatusEnum.enum.DONE:
      statusIcon = 'ic-check-square';
      break;
    default:
  }
  return statusIcon;
}
function getActionStatusIcon(actionStatus) {
  let statusIcon;
  switch (actionStatus) {
    case TaskStatusEnum.enum.BACKLOG:
    case TaskStatusEnum.enum.TODO:
    case TaskStatusEnum.enum.INPROGRESS:
      statusIcon = 'ic-assignment';
      break;
    case TaskStatusEnum.enum.DONE:
      statusIcon = 'ic-assignment_turned_in';
      break;
    default:
  }
  return statusIcon;
}
function getColorForActionStatusIcon(actionStatus) {
  let color;
  switch (actionStatus) {
    case TaskStatusEnum.enum.BACKLOG:
      color = '#999999';
      break;
    case TaskStatusEnum.enum.TODO:
      color = '#f8a534';
      break;
    case TaskStatusEnum.enum.INPROGRESS:
      color = '#3d81ed';
      break;
    case TaskStatusEnum.enum.DONE:
      color = '#38c548';
      break;
    default:
  }
  return color;
}

export {
  getActionWorkflowIcon,
  getActionStatusIcon,
  getColorForActionStatusIcon,
  getLearningStatusIcon,
  getColorForLearningStatusIcon,
  getExperimentStateIcon,
  getColorForExperimentStateIcon,
  getProjectStateIcon,
  getColorForProjectStateIcon,
  getPublicFileCallForIcon,
};
