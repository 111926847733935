import I18n from 'i18n-js';
import en from './locales/en';
import fr from './locales/fr';

/* global navigator */

I18n.fallbacks = true;
I18n.defaultLocale = 'en';
I18n.locale = (navigator.languages && navigator.languages[0]) || navigator.language;
I18n.translations = {
  en,
  fr,
};

export default I18n;
