/*global fetch, Headers, window */

const readResponse = function(response) {
  if (response.headers.get('content-length') === '0') {
    return Promise.resolve({});
  }

  const contentType = response.headers.get('content-type');
  let reader = 'text';
  if (contentType) {
    if (contentType.startsWith('application/json')) {
      reader = 'json';
    } else if (contentType.startsWith('multipart/form-data')) {
      reader = 'formData';
    }
  }
  return response[reader]();
};

const executeRequest = function(path, options) {
  const fetchOptions = options || {};
  if (!fetchOptions.credentials) {
    fetchOptions.credentials = 'same-origin';
  }
  if (!fetchOptions.headers) {
    fetchOptions.headers = new Headers();
  }
  fetchOptions.headers.append('X-Requested-With', 'RequestUtil-fetch');
  // TODO: for serverside rendering: return fetch(`${process.env.API_ENDPOINT}/${path}`, fetchOptions)
  return fetch(`${path}`, fetchOptions)
    .then(
      response => readResponse(response).then(data => ({ data, response }), () => ({ data: {}, response })),
      () => {
        throw new Error('errors.network');
      }
    )
    .then(({ data, response }) => {
      if (data && data.error !== undefined) {
        throw data;
      }
      if (response.status === 403) {
        throw new Error('errors.forbidden');
      }
      if (response.status >= 400) {
        throw new Error('errors.unknownLight');
      }
      return { data, response };
    });
};

const executeRequestWithBody = function(url, body, fetchOptions, contentType) {
  const headers = new Headers();
  const method = fetchOptions.method || 'POST';
  headers.append('Content-Type', contentType || 'application/json');
  if (typeof body !== 'string') {
    body = JSON.stringify(body);
  }
  const options = Object.assign({ method, body, headers }, fetchOptions);
  return executeRequest(url, options);
};

const executeFormDataRequest = function(url, body, fetchOptions) {
  const headers = new Headers();
  const method = 'POST';
  const options = Object.assign({ method, body, headers }, fetchOptions);
  return executeRequest(url, options);
};

const jsRoutes = window.jsRoutes;

export { executeRequest, executeRequestWithBody, executeFormDataRequest, jsRoutes };
