import React from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import i18n from '../../../i18n';
import ColumnFooter from '../../global/elems/ColumnFooter';
import TaskStatusEnum from '../../stores/projects/models/tasks/TaskStatusEnum';
import BoardColumnHeader from '../BoardColumnHeader';
import TaskCardsList from './TaskCardsList';
import '../columns/css/BoardColumn.css';

class TodoBoardColumn extends React.Component {
  static propTypes = {
    column: PropTypes.shape({
      iconName: PropTypes.string,
      name: PropTypes.string,
      helpId: PropTypes.string,
      id: PropTypes.oneOf(TaskStatusEnum.keys),
    }).isRequired,
    project: PropTypes.shape({
      id: PropTypes.string.isRequired,
      tasks: PropTypes.shape({
        addAction: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  };

  renderCards = provided => (
    <div ref={provided.innerRef} className="flex_1 overflowY_auto m-r--15 p-r-15 p-t-10">
      <TaskCardsList project={this.props.project} column={this.props.column} />
      {provided.placeholder}
    </div>
  );

  renderFooter() {
    if (this.props.column.id !== TaskStatusEnum.enum.BACKLOG && this.props.column.id !== TaskStatusEnum.enum.TODO) {
      return null;
    }
    return (
      <ColumnFooter
        project={this.props.project}
        handleCreate={(projectId, action) =>
          this.props.project.tasks.addAction(projectId, { status: this.props.column.id, ...action })
        }
        buttonText={i18n.t('action.add.text')}
        buttonTitle={i18n.t('action.add.title')}
        placeholder={i18n.t('action.new.title.placeholder')}
        createText={i18n.t('action.new.create')}
      />
    );
  }

  render() {
    return (
      <div className="card board-column">
        <BoardColumnHeader
          name={this.props.column.name}
          iconName={this.props.column.iconName}
          projectId={this.props.project.id}
          helpId={this.props.column.helpId}
        />
        {this.renderFooter()}
        <Droppable className="board-column-cards" droppableId={this.props.column.id}>
          {this.renderCards}
        </Droppable>
      </div>
    );
  }
}

export default TodoBoardColumn;
