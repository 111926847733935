/* global window */

import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { RIEInput } from '@attently/riek';
import 'url-search-params-polyfill';
import i18n from '../../../i18n';
import DeleteButton from '../../global/elems/DeleteButton';
import Modal from '../../global/elems/Modal';
import withFeedback from '../../global/feedback/withFeedback';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import projectsStore from '../../stores/projects/ProjectsStore';
import ExperimentStateEnum from '../../stores/projects/models/experiments/ExperimentStateEnum';
import UserStore from '../../stores/user/UserStore';
import Files from '../files/Files';
import TaskAssignees from '../tasks/TaskAssignees';
import DecisionChoices from './DecisionChoices';
import ExperimentCard from './ExperimentCard';
import ExperimentStateLabel from './ExperimentStateLabel';
import Results from './Results';
import Solutions from './Solutions';
import './css/experimentModal.css';

class ExperimentModal extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        projectId: PropTypes.string.isRequired,
        ideaId: PropTypes.string.isRequired,
        experimentId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  };

  project = null;
  idea = null;
  experiment = null;
  canEditOrRemove = false;

  UNSAFE_componentWillMount() {
    this.project =
      this.props.match.params && this.props.match.params.projectId
        ? projectsStore.getProjectById(this.props.match.params.projectId) || null
        : null;
    this.idea =
      this.project && this.props.match.params.ideaId
        ? this.project.getIdeaById(this.props.match.params.ideaId) || null
        : null;
    this.experiment =
      this.idea && this.props.match.params.experimentId
        ? this.idea.getExperimentById(this.props.match.params.experimentId) || null
        : null;
    this.canEditOrRemove =
      !!UserStore.currentUser &&
      (UserStore.currentUser.hasOneOrManyAdminRoles || this.project.isMember(UserStore.currentUser.id));
  }

  onDelete = experiment => event => {
    event.stopPropagation();
    if (window.confirm(i18n.t('experiment.confirmDelete', { experimentTitle: experiment.title }))) {
      this.idea.deleteExperiment(experiment).catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
      this.experiment = null;
      this.modal.close();
    }
  };

  handleBack = () => {
    const backUrl = new URLSearchParams(this.props.location.search).get('back');
    if (backUrl) {
      this.props.history.push(backUrl);
    } else {
      this.props.history.push('/projects/' + this.props.match.params.projectId);
    }
  };

  handleTitleChange = value =>
    this.experiment.setTitle(value.title).catch(error => {
      this.props.showErrorMessage(getErrorMessage(error), 5000);
    });

  handleStateChange = value =>
    this.experiment.setState(value).catch(error => {
      this.props.showErrorMessage(getErrorMessage(error), 5000);
    });

  renderURLSearchBack = () => {
    const backUrl = new URLSearchParams(this.props.location.search).get('back');
    return backUrl ? '?back=' + new URLSearchParams(this.props.location.search).get('back') : '';
  };

  renderTab = tab => (
    <NavLink
      exact
      to={
        '/projects/' +
        this.project.id +
        '/ideas/' +
        this.idea.id +
        '/experiments/' +
        this.experiment.id +
        '/' +
        (tab.default ? '' : tab.id) +
        this.renderURLSearchBack()
      }
      key={tab.id}
      className="tab tab_small text_normal"
      activeClassName="tab_selected"
      title={tab.label}
    >
      <i className={'tab-icon ' + tab.iconName} />
      <div className="tab-label">{tab.label}</div>
    </NavLink>
  );

  createFiles = files => {
    return this.experiment.files.createExperimentFiles(this.project.id, this.idea.id, this.experiment.id, files);
  };

  deleteFile = file => {
    return this.experiment.files
      .deleteExperimentFile(this.project.id, this.idea.id, this.experiment.id, file)
      .catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
  };

  render() {
    if (this.experiment === null) {
      this.handleBack();
      return null;
    }

    const TABS = [
      {
        id: 'solutions',
        label: i18n.t('solutions.title'),
        iconName: 'ic-check',
        component: (
          <Solutions
            projectId={this.project.id}
            project={this.project}
            experiment={this.experiment}
            ideaId={this.idea.id}
          />
        ),
      },
      {
        default: true,
        id: 'experiment-card',
        label: i18n.t('experimentCard.title'),
        iconName: 'ic-layout',
        component: (
          <ExperimentCard
            projectId={this.project.id}
            project={this.project}
            experiment={this.experiment}
            ideaId={this.idea.id}
          />
        ),
      },
      {
        id: 'learning-card',
        label: i18n.t('learningCard.title'),
        iconName: 'ic-test',
        component: (
          <Results
            projectId={this.project.id}
            project={this.project}
            experiment={this.experiment}
            ideaId={this.idea.id}
          />
        ),
      },
      {
        id: 'files',
        label: i18n.t('files.title'),
        iconName: 'ic-paperclip',
        component: (
          <Files
            files={this.experiment.files}
            project={this.project}
            createFiles={this.createFiles}
            deleteFile={this.deleteFile}
          />
        ),
      },
      {
        id: 'assignees',
        label: i18n.t('task.assignees.title'),
        iconName: 'ic-users',
        component: (
          <TaskAssignees
            task={this.experiment}
            canAddOrRemove={this.canEditOrRemove}
            membersList={this.project.membersList}
            showErrorMessage={this.props.showErrorMessage}
          />
        ),
      },
    ];
    let tabs = TABS.slice();
    tabs[0].disabled = this.experiment.state !== ExperimentStateEnum.enum.ACCEPTED;
    tabs[2].disabled = this.experiment.state === ExperimentStateEnum.enum.BUILDING;
    tabs[3].disabled = this.experiment.state === ExperimentStateEnum.enum.BUILDING;
    tabs = tabs.filter(tab => !tab.disabled);

    return (
      <Modal
        ref={modal => (this.modal = modal)}
        className="experimentModal"
        drawer={true}
        withRouter={true}
        history={this.props.history}
        backLabel={i18n.t('board.backToBoard')}
        onBack={this.handleBack}
      >
        <div className="modal-title d-flex flexDirection_row">
          <ExperimentStateLabel
            value={this.experiment.state}
            handleSelectChange={this.handleStateChange}
            editable={this.canEditOrRemove}
          />
          <i className="ic-rocket m-r-15 m-t-5 text_big" />
          <h2 className="m-0">
            <RIEInput
              value={this.experiment.title}
              propName="title"
              change={this.handleTitleChange}
              validate={value => value.trim() !== ''}
              classEditing="input input_medium"
              isDisabled={!this.canEditOrRemove}
            />
            <DeleteButton
              onClick={this.onDelete(this.experiment)}
              title={i18n.t('experiment.deleteIt')}
              square={true}
              className="experimentModal-btnTrash"
              canDelete={this.canEditOrRemove}
            />
          </h2>
          <div className="flex_1" />
          <div className="tabs m-t-5 m-b-10">{tabs.map(this.renderTab)}</div>
        </div>

        <div className="modal-content">
          {this.canEditOrRemove && this.experiment.state === ExperimentStateEnum.enum.PPP ? (
            <DecisionChoices project={this.project} idea={this.idea} experiment={this.experiment} />
          ) : null}
          <Switch>
            <Redirect
              exact
              from="/projects/:projectId/ideas/:ideaId/experiments/:experimentId/learnings"
              to="/projects/:projectId/ideas/:ideaId/experiments/:experimentId/learning-card"
            />
            <Redirect
              exact
              from="/projects/:projectId/ideas/:ideaId/experiments/:experimentId/results"
              to="/projects/:projectId/ideas/:ideaId/experiments/:experimentId/learning-card"
            />
            {tabs.map(tab => (
              <Route
                key={tab.id}
                exact
                path={'/projects/:projectId/ideas/:ideaId/experiments/:experimentId/' + (tab.default ? '' : tab.id)}
                component={() => {
                  return React.cloneElement(tab.component);
                }}
              />
            ))}
          </Switch>
        </div>
      </Modal>
    );
  }
}

export default withFeedback(observer(ExperimentModal));
