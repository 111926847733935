import { createEnhancedEnum } from '../../../../global/utils/EnumBuilder';

const LEARNING_TYPE = {
  PAIN: 0,
  GAIN: 1,
  OTHER: 2,
};

const LearningTypeEnum = createEnhancedEnum('LearningType', LEARNING_TYPE);

export default LearningTypeEnum;
