import React from 'react';
import PropTypes from 'prop-types';
import Canvas from '../../global/elems/canvas/Canvas';
import './css/boardContext.css';
import i18n from '../../../i18n';

class BoardContext extends React.Component {
  static propTypes = {
    project: PropTypes.shape({
      context: PropTypes.objectOf(PropTypes.string).isRequired,
    }).isRequired,
  };

  static CANVAS_LINES = [
    {
      blocks: [
        {
          name: 'description',
          iconName: 'ic-assignment',
          label: i18n.t('board.businessChallenge.description.label'),
          placeholder: i18n.t('board.businessChallenge.description.placeholder'),
        },
        {
          name: 'customers',
          iconName: 'ic-assignment_ind',
          label: i18n.t('board.businessChallenge.customers.label'),
          placeholder: i18n.t('board.businessChallenge.customers.placeholder'),
        },
      ],
    },
    {
      blocks: [
        {
          name: 'expectation',
          iconName: 'ic-target',
          label: i18n.t('board.businessChallenge.expectation.label'),
          placeholder: i18n.t('board.businessChallenge.expectation.placeholder'),
          style: { flex: 1.6 },
        },
        {
          name: 'metrics',
          iconName: 'ic-network_check',
          label: i18n.t('board.businessChallenge.metrics.label'),
          placeholder: i18n.t('board.businessChallenge.metrics.placeholder'),
        },
      ],
    },
    {
      blocks: [
        {
          name: 'context',
          iconName: 'ic-zbriefcase',
          label: i18n.t('board.businessChallenge.context.label'),
          placeholder: i18n.t('board.businessChallenge.context.placeholder'),
        },
        {
          name: 'constraints',
          iconName: 'ic-sliders',
          label: i18n.t('board.businessChallenge.constraints.label'),
          placeholder: i18n.t('board.businessChallenge.constraints.placeholder'),
        },
        {
          name: 'resources',
          iconName: 'ic-box',
          label: i18n.t('board.businessChallenge.resources.label'),
          placeholder: i18n.t('board.businessChallenge.resources.placeholder'),
        },
      ],
    },
    {
      blocks: [
        {
          name: 'teamCoordinator',
          iconName: 'ic-user',
          label: i18n.t('board.businessChallenge.teamCoordinator.label'),
          placeholder: i18n.t('board.businessChallenge.teamCoordinator.placeholder'),
        },
        {
          name: 'businessOwner',
          iconName: 'ic-user',
          label: i18n.t('board.businessChallenge.businessOwner.label'),
          placeholder: i18n.t('board.businessChallenge.businessOwner.placeholder'),
        },
        {
          name: 'builder',
          iconName: 'ic-user',
          label: i18n.t('board.businessChallenge.builder.label'),
          placeholder: i18n.t('board.businessChallenge.builder.placeholder'),
        },
        {
          name: 'catalyst',
          iconName: 'ic-user',
          label: i18n.t('board.businessChallenge.catalyst.label'),
          placeholder: i18n.t('board.businessChallenge.catalyst.placeholder'),
        },
      ],
    },
  ];

  render() {
    return (
      <div className="boardBusinessChallenge">
        <Canvas
          boardId="boardBusinessChallenge"
          project={this.props.project}
          data={this.props.project.context}
          lines={BoardContext.CANVAS_LINES}
        />
      </div>
    );
  }
}

export default BoardContext;
