import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';
import { getColorForProjectStateIcon, getProjectStateIcon } from '../global/utils/IconUtils';

class SearchProject extends React.Component {
  static propTypes = {
    project: PropTypes.shape({
      id: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      members: PropTypes.shape({
        length: PropTypes.number,
      }).isRequired,
      nbIdeas: PropTypes.number.isRequired,
      nbExperiments: PropTypes.number.isRequired,
      nbSolutions: PropTypes.number.isRequired,
      additionalLink: PropTypes.string,
    }).isRequired,
  };

  render() {
    const project = this.props.project;
    const projectState = project.state;
    return (
      <div className="searchPanel-line" key={project.id}>
        <i
          title={i18n.t('projects.' + projectState)}
          className={'searchPanel-line-icon ' + getProjectStateIcon(projectState)}
          style={{ color: getColorForProjectStateIcon(projectState) }}
        />
        <div className="searchPanel-line-text">{project.name}</div>
        <div className="searchPanel-line-infos">
          <div className="searchPanel-line-info" title={i18n.t('numberOf.members')}>
            <i className="searchPanel-line-info-icon ic-users" />
            {project.members.length}
          </div>
          <div className="searchPanel-line-info" title={i18n.t('numberOf.ideas')}>
            <i className="searchPanel-line-info-icon ic-lightbulb_outline" />
            {project.nbIdeas}
          </div>
          <div className="searchPanel-line-info" title={i18n.t('numberOf.experiments')}>
            <i className="searchPanel-line-info-icon ic-rocket" />
            {project.nbExperiments}
          </div>
          <div className="searchPanel-line-info" title={i18n.t('numberOf.solutions')}>
            <i className="searchPanel-line-info-icon ic-check-circle" />
            {project.nbSolutions}
          </div>
        </div>
        <Link
          title={i18n.t('see.project')}
          className="btn btn_primary btn_medium searchPanel-line-btn"
          to={'/projects/' + project.id + (project.additionalLink ? project.additionalLink : '')}
        >
          <i className="ic-arrow-right" />
        </Link>
      </div>
    );
  }
}

export default observer(SearchProject);
