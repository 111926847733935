import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ExperimentStateEnum from './../../stores/projects/models/experiments/ExperimentStateEnum';
import i18n from '../../../i18n';
import './css/decisionChoices.css';
import PropTypes from 'prop-types';

class DecisionChoices extends React.Component {
  static propTypes = {
    project: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    idea: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    experiment: PropTypes.shape({
      setState: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  handleAccept = () => this.props.experiment.setState(ExperimentStateEnum.enum.ACCEPTED);

  handleRefuse = () => this.props.experiment.setState(ExperimentStateEnum.enum.REFUSED);

  handlePivot = () => {
    this.props.experiment.setState(ExperimentStateEnum.enum.PIVOTED);
    this.props.history.push(`/projects/${this.props.project.id}/ideas/${this.props.idea.id}/experiments/new`);
  };

  render() {
    return (
      <div className="decisionChoices">
        <p>What is your decision?</p>
        <div className="d-flex flexDirection_row alignItems_center justifyContent_flexStart m-t-10">
          <div className="btn btn_ok btn_medium btn_medium_square" onClick={this.handleAccept}>
            <i className="ic-check m-r-5" />
            {i18n.t('experiment.accept')}
          </div>
          <div className="btn btn_primary btn_medium btn_medium_square m-l-10" onClick={this.handlePivot}>
            <i className="ic-rotate-ccw m-r-5" />
            {i18n.t('experiment.pivot')}
          </div>
          <div className="btn btn_close btn_medium btn_medium_square m-l-10" onClick={this.handleRefuse}>
            <i className="ic-x m-r-5" />
            {i18n.t('experiment.refuse')}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(observer(DecisionChoices));
