import { flow, getParent, types } from 'mobx-state-tree';
import { caseInsensitiveSearch } from '../../../../components/lib/SearchUtil';
import ProjectsService from '../ProjectsService';

const ContextModel = types
  .model('Context', {
    description: types.optional(types.string, ''),
    customers: types.optional(types.string, ''),
    expectation: types.optional(types.string, ''),
    metrics: types.optional(types.string, ''),
    context: types.optional(types.string, ''),
    constraints: types.optional(types.string, ''),
    resources: types.optional(types.string, ''),
    teamCoordinator: types.optional(types.string, ''),
    businessOwner: types.optional(types.string, ''),
    builder: types.optional(types.string, ''),
    catalyst: types.optional(types.string, ''),
  })
  .views(self => ({
    isFilled() {
      return (
        self.description.trim() !== '' &&
        self.customers.trim() !== '' &&
        self.expectation.trim() !== '' &&
        self.metrics.trim() !== '' &&
        self.context.trim() !== '' &&
        self.constraints.trim() !== '' &&
        self.resources.trim() !== '' &&
        self.teamCoordinator.trim() !== '' &&
        self.businessOwner.trim() !== '' &&
        self.builder.trim() !== '' &&
        self.catalyst.trim() !== ''
      );
    },

    contains(text) {
      return (
        caseInsensitiveSearch(self.description, text) ||
        caseInsensitiveSearch(self.customers, text) ||
        caseInsensitiveSearch(self.expectation, text) ||
        caseInsensitiveSearch(self.metrics, text) ||
        caseInsensitiveSearch(self.context, text) ||
        caseInsensitiveSearch(self.constraints, text) ||
        caseInsensitiveSearch(self.resources, text) ||
        caseInsensitiveSearch(self.teamCoordinator, text) ||
        caseInsensitiveSearch(self.businessOwner, text) ||
        caseInsensitiveSearch(self.builder, text) ||
        caseInsensitiveSearch(self.catalyst, text)
      );
    },

    get project() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    set: flow(function* set(properties) {
      yield ProjectsService.setBusinessChallenge(self.project, {
        ...self,
        ...properties,
      });
      Object.assign(self, properties);
    }),

    handleEvent(event) {
      if (event && event.event && event.eventType) {
        switch (event.eventType) {
          case 'ProjectContextUpdated':
            Object.assign(self, event.event.context);
            return true;
          default:
        }
      }
      console.warn('ContextModel does not know how to handle event ', event);
      return false;
    },
  }));

export default ContextModel;
