import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from '../../i18n';
import CreateModal from '../global/elems/CreateModal';
import withFeedback from '../global/feedback/withFeedback';
import { getErrorMessage } from '../global/utils/ErrorHandling';
import projectsStore from '../stores/projects/ProjectsStore';
import UserStore from '../stores/user/UserStore';
import BoardCard from './BoardCard';
import './css/BoardsList.css';

class BoardsList extends React.Component {
  static propTypes = {
    starred: PropTypes.bool,
    name: PropTypes.string,
    iconName: PropTypes.string,
    showErrorMessage: PropTypes.func.isRequired,
    hideMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    starred: false,
  };

  canAdd = !!UserStore.currentUser && UserStore.currentUser.hasOneOrManyAdminRoles;

  handleStartAdding = () => this.createModal.open();

  handleCreate = name => {
    projectsStore
      .createProject(name)
      .then(() => {
        this.props.hideMessage();
      })
      .catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
  };

  renderBoard = project => <BoardCard project={project} key={project.name} />;

  renderAddButton = () => {
    if (this.props.starred || !this.canAdd) {
      return null;
    } else {
      return (
        <div className="btn btn_medium btn_medium_square btn_primary" onClick={this.handleStartAdding}>
          <i className="ic-plus btn-icon" />
          <span>{i18n.t('projects.add')}</span>
        </div>
      );
    }
  };

  render() {
    const projects = this.props.starred ? projectsStore.starredProjects : projectsStore.ownProjects;
    return (
      <div className="boardsList">
        <h3 className="boardsList-title d-flex flexDirection_row alignItems_center">
          <i className={'boardsList-title-icon ' + this.props.iconName} />
          <div className="flex_1">{this.props.name}</div>
          {this.renderAddButton()}
        </h3>
        <div className="boardsList-boards">{projects.map(this.renderBoard)}</div>
        <CreateModal
          ref={createModal => (this.createModal = createModal)}
          title={i18n.t('projects.new.title')}
          label={i18n.t('projects.new.name.label')}
          createButtonText={i18n.t('projects.new.create')}
          placeholder={i18n.t('projects.new.name.placeholder')}
          handleCreate={this.handleCreate}
        />
      </div>
    );
  }
}

export default withFeedback(observer(BoardsList));
