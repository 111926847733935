import { flow, getParent, types } from 'mobx-state-tree';
import { caseInsensitiveSearch } from '../../../../../components/lib/SearchUtil';
import experimentsService from '../../ExperimentsService';

const ExperimentSolutionsModel = types
  .model('ExperimentSolutions', {
    text: types.optional(types.string, ''),
  })
  .views(self => ({
    isFilled() {
      return self.text.trim() !== '';
    },
    get experiment() {
      return getParent(self);
    },
    contains(text) {
      return caseInsensitiveSearch(self.text, text);
    },
  }))
  .actions(self => ({
    set: flow(function* set(properties) {
      yield experimentsService.setSolutions(self.experiment, {
        ...self,
        ...properties,
      });
      Object.assign(self, properties);
    }),

    handleEvent(event) {
      if (event && event.event && event.eventType) {
        switch (event.eventType) {
          case 'ExperimentSolutionsUpdated':
            Object.assign(self, event.event.solutions);
            return true;
          default:
        }
      }
      console.warn('ExperimentSolutionsModel does not know how to handle event ', event);
      return false;
    },
  }));

export default ExperimentSolutionsModel;
