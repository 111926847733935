import { flow, getParent, types } from 'mobx-state-tree';
import { caseInsensitiveSearch } from '../../../../../components/lib/SearchUtil';
import experimentsService from '../../ExperimentsService';

const ExperimentCardModel = types
  .model('ExperimentCard', {
    learningGoal: types.optional(types.string, ''),
    hypothesis: types.optional(types.string, ''),
    metrics: types.optional(types.string, ''),
    invalidationCriteria: types.optional(types.string, ''),
    earlyStopCondition: types.optional(types.string, ''),
    plan: types.optional(types.string, ''),
  })
  .views(self => ({
    isFilled() {
      return (
        self.learningGoal.trim() !== '' &&
        self.hypothesis.trim() !== '' &&
        self.metrics.trim() !== '' &&
        self.invalidationCriteria.trim() !== '' &&
        self.earlyStopCondition.trim() !== '' &&
        self.plan.trim() !== ''
      );
    },

    contains(text) {
      return (
        caseInsensitiveSearch(self.learningGoal, text) ||
        caseInsensitiveSearch(self.hypothesis, text) ||
        caseInsensitiveSearch(self.metrics, text) ||
        caseInsensitiveSearch(self.invalidationCriteria, text) ||
        caseInsensitiveSearch(self.earlyStopCondition, text) ||
        caseInsensitiveSearch(self.plan, text)
      );
    },

    get experiment() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    set: flow(function* set(properties) {
      yield experimentsService.setExperimentCard(self.experiment, {
        ...self,
        ...properties,
      });
      Object.assign(self, properties);
    }),

    handleEvent(event) {
      if (event && event.event && event.eventType) {
        switch (event.eventType) {
          case 'ExperimentCardUpdated':
            Object.assign(self, event.event.experimentCard);
            return true;
          default:
        }
      }
      console.warn('ExperimentCardModel does not know how to handle event ', event);
      return false;
    },
  }));

export default ExperimentCardModel;
