import { createEnhancedEnum } from '../../../../global/utils/EnumBuilder';

const TASK_STATUSES = {
  BACKLOG: -1,
  TODO: 0,
  INPROGRESS: 1,
  DONE: 2,
};

const TaskStatusEnum = createEnhancedEnum('TaskStatus', TASK_STATUSES);

export default TaskStatusEnum;
