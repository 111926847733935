import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import KEYBOARD from '../../constants/KeyBoard';
import i18n from '../../i18n';

class SearchBar extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    text: '',
  };

  handleChange = event => this.setState({ text: event.target.value });

  handleKeyPress = event => {
    if (event.key === KEYBOARD.ENTER.name) {
      this.handleSearch();
    }
  };

  handleSearch = () => {
    const text = this.state.text.trim();
    if (text !== '') {
      this.setState({ text: '' });
      this.props.history.push('/search?text=' + encodeURIComponent(text));
    }
  };

  render() {
    return (
      <div className="header-item header-search">
        <div
          className={
            'header-item-icon ' +
            (this.state.text.trim().length > 0
              ? 'header-item-icon_active btn btn_primary btn_medium btn_medium_square'
              : '')
          }
          onClick={this.handleSearch}
        >
          <i className="ic-search" />
        </div>
        <input
          className="header-search-input"
          placeholder={i18n.t('search.placeholder')}
          value={this.state.text}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
        />
      </div>
    );
  }
}

export default withRouter(SearchBar);
