import React from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import i18n from '../../../i18n';
import ColumnFooter from '../../global/elems/ColumnFooter';
import ActionTypeEnum from '../../stores/projects/models/actions/ActionTypeEnum';
import EmpathizeEnum from '../../stores/projects/models/empathize/EmpathizeEnum';
import ideaStateEnum from '../../stores/projects/models/ideas/IdeaStateEnum';
import BoardColumnHeader from '../BoardColumnHeader';
import EmpathizeCardsList from './EmpathizeCardsList';
import IdeaCardsList from './IdeaCardsList';
import './css/BoardColumn.css';

class BoardColumn extends React.Component {
  static propTypes = {
    column: PropTypes.shape({
      iconName: PropTypes.string,
      name: PropTypes.string,
      helpId: PropTypes.string,
      id: PropTypes.oneOf(ideaStateEnum.keys.concat(EmpathizeEnum.keys)),
      isDropDisabled: PropTypes.bool,
    }).isRequired,
    project: PropTypes.shape({
      id: PropTypes.string.isRequired,
      ideas: PropTypes.shape({
        addIdea: PropTypes.func.isRequired,
      }).isRequired,
      tasks: PropTypes.shape({
        addAction: PropTypes.func.isRequired,
      }),
    }).isRequired,
  };

  renderCards = provided => {
    if (this.props.column.id === EmpathizeEnum.enum.EMPATHIZE) {
      return (
        <div ref={provided.innerRef} className="flex_1 overflowY_auto m-r--15 p-r-15 p-t-10">
          <EmpathizeCardsList project={this.props.project} />
        </div>
      );
    }
    return (
      <div ref={provided.innerRef} className="flex_1 overflowY_auto m-r--15 p-r-15 p-t-10">
        <IdeaCardsList project={this.props.project} column={this.props.column} />
        {provided.placeholder}
      </div>
    );
  };

  renderFooter() {
    if (this.props.column.id === ideaStateEnum.enum.IDEAS) {
      return (
        <ColumnFooter
          project={this.props.project}
          handleCreate={this.props.project.ideas.addIdea}
          buttonText={i18n.t('idea.add.text')}
          buttonTitle={i18n.t('idea.add.title')}
          placeholder={i18n.t('idea.new.title.placeholder')}
          createText={i18n.t('idea.new.create')}
        />
      );
    }
    if (this.props.column.id === EmpathizeEnum.enum.EMPATHIZE) {
      return (
        <ColumnFooter
          project={this.props.project}
          handleCreate={(projectId, action) =>
            this.props.project.tasks.addAction(projectId, { type: ActionTypeEnum.enum.EMPATHIZE, ...action })
          }
          buttonText={i18n.t('action.add.text')}
          buttonTitle={i18n.t('action.add.title')}
          placeholder={i18n.t('action.new.title.placeholder')}
          createText={i18n.t('action.new.create')}
        />
      );
    }
    return null;
  }

  render() {
    const isDropDisabled = this.props.column.isDropDisabled ? this.props.column.isDropDisabled : false;
    return (
      <div className="card board-column">
        <BoardColumnHeader
          name={this.props.column.name}
          iconName={this.props.column.iconName}
          projectId={this.props.project.id}
          helpId={this.props.column.helpId}
        />
        {this.renderFooter()}
        <Droppable className="board-column-cards" droppableId={this.props.column.id} isDropDisabled={isDropDisabled}>
          {this.renderCards}
        </Droppable>
      </div>
    );
  }
}

export default BoardColumn;
