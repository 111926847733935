import { executeRequest, executeRequestWithBody, jsRoutes } from '../../../components/lib/RequestUtil';

class ActionsService {
  addAction(projectId, action) {
    const { url, method } = jsRoutes.controllers.Actions.createAction(projectId);
    return executeRequestWithBody(url, action, { method });
  }

  deleteAction(action) {
    const { url, method } = jsRoutes.controllers.Actions.deleteAction(action.projectId, action.id);
    return executeRequest(url, { method });
  }

  setStatus(action, status) {
    const { url, method } = jsRoutes.controllers.Actions.setStatus(action.projectId, action.id, status);
    return executeRequest(url, { method });
  }

  setType(action, type) {
    const { url, method } = jsRoutes.controllers.Actions.setType(action.projectId, action.id, type);
    return executeRequest(url, { method });
  }

  setTitle(action, title) {
    const { url, method } = jsRoutes.controllers.Actions.setTitle(action.projectId, action.id);
    return executeRequestWithBody(url, { title }, { method });
  }

  setText(action, text) {
    const { url, method } = jsRoutes.controllers.Actions.setText(action.projectId, action.id);
    return executeRequestWithBody(url, { text }, { method });
  }

  addAssignee(action, assigneeId) {
    const { url, method } = jsRoutes.controllers.Actions.addAssignee(action.projectId, action.id, assigneeId);
    return executeRequestWithBody(url, { assigneeId }, { method });
  }

  removeAssignee(action, assigneeId) {
    const { url, method } = jsRoutes.controllers.Actions.removeAssignee(action.projectId, action.id, assigneeId);
    return executeRequestWithBody(url, { assigneeId }, { method });
  }
}

const actionsService = new ActionsService();
export default actionsService;
