import React from 'react';
import PropTypes from 'prop-types';
import KEYBOARD from '../../../constants/KeyBoard';
import withFeedback from '../feedback/withFeedback';
import { getErrorMessage } from '../utils/ErrorHandling';
import UserStore from '../../stores/user/UserStore';

class ColumnFooter extends React.Component {
  static propTypes = {
    project: PropTypes.shape({
      id: PropTypes.string.isRequired,
      isMember: PropTypes.func.isRequired,
    }),
    buttonText: PropTypes.string.isRequired,
    buttonTitle: PropTypes.string.isRequired,
    createText: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    handleCreate: PropTypes.func.isRequired,
    showErrorMessage: PropTypes.func.isRequired,
  };

  state = {
    title: '',
    adding: false,
  };

  canAdd =
    !!UserStore.currentUser &&
    (UserStore.currentUser.hasOneOrManyAdminRoles || this.props.project.isMember(UserStore.currentUser.id));

  setFocus = ref => ref && ref.focus();

  handleChange = event => this.setState({ title: event.target.value });

  handleKeyDown = event => {
    if (event.key === KEYBOARD.ENTER.name) {
      this.handleCreate();
    }
    if (event.keyCode === KEYBOARD.ESCAPE.code) {
      this.handleCancel();
    }
  };

  handleCreate = () => {
    if (this.state.title.trim() !== '') {
      this.props.handleCreate(this.props.project.id, { title: this.state.title }).catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
    }
    this.setState({ title: '', adding: false });
  };

  handleCancel = () => this.setState({ adding: false });

  handleStartAdding = () => this.setState({ adding: true });

  renderCta() {
    if (!this.canAdd) {
      return null;
    }
    return (
      <div className="btn btn_primary btn_medium" onClick={this.handleStartAdding} title={this.props.buttonTitle}>
        <i className="ic-plus btn-icon" />
        <span>{this.props.buttonText}</span>
      </div>
    );
  }

  renderForm() {
    return (
      <div className="column-footer-form">
        <input
          className="m-b-5 input"
          placeholder={this.props.placeholder}
          value={this.state.title}
          onChange={this.handleChange}
          ref={this.setFocus}
          onKeyDown={this.handleKeyDown}
        />
        <div className="d-flex flexDirection_row">
          <div className="btn btn_ok btn_medium m-r-5" onClick={this.handleCreate}>
            {this.props.createText}
          </div>
          <div className="btn btn_transparent btn_medium" onClick={this.handleCancel}>
            <i className="ic-x btn-icon" />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <div className="column-footer m-b-20">{this.state.adding ? this.renderForm() : this.renderCta()}</div>;
  }
}

export default withFeedback(ColumnFooter);
