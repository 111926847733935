import React from 'react';
import PropTypes from 'prop-types';
import withFeedback from '../global/feedback/withFeedback';
import { getErrorMessage } from '../global/utils/ErrorHandling';
import i18n from '../../i18n';
import userStore from '../stores/user/UserStore';
import './css/User.css';

class ChangePassword extends React.Component {
  static propTypes = {
    hideMessage: PropTypes.func.isRequired,
    showErrorMessage: PropTypes.func.isRequired,
    showSuccessMessage: PropTypes.func.isRequired,
    history: PropTypes.shape({
      goBack: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    current: '',
    password1: '',
    password2: '',
  };

  handleFieldValueChange = fieldName => event => {
    const newState = {};
    newState[fieldName] = event.target.value;
    this.setState(newState);
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.hideMessage();
    const { current, password1, password2 } = this.state;
    userStore
      .changePassword({ current, password1, password2 })
      .then(() => {
        // back to previous screen after displaying success message
        this.props.showSuccessMessage(i18n.t('user.changePassword.success'), 1500, () => this.props.history.goBack());
      })
      .catch(error => {
        this.props.showErrorMessage(getErrorMessage(error));
      });
  };

  render() {
    return (
      <form className="user-form" onSubmit={this.handleSubmit}>
        <div className="center-square card">
          <label className="m-b-20">{i18n.t('user.changePassword.title')}</label>
          <input
            className="user-password-input input m-b-40"
            type="password"
            value={this.state.current}
            placeholder={i18n.t('user.changePassword.currentPassword')}
            onChange={this.handleFieldValueChange('current')}
          />
          <input
            className="user-password-input input m-b-40"
            type="password"
            value={this.state.password1}
            placeholder={i18n.t('user.changePassword.newPassword1')}
            onChange={this.handleFieldValueChange('password1')}
          />
          <input
            className="user-password-input input m-b-40"
            type="password"
            value={this.state.password2}
            placeholder={i18n.t('user.changePassword.newPassword2')}
            onChange={this.handleFieldValueChange('password2')}
          />
          <button
            className="user-submit-button btn btn_medium btn_medium_square btn_primary"
            type="submit"
            value={i18n.t('user.submit')}
          >
            {i18n.t('user.submit')}
          </button>
        </div>
      </form>
    );
  }
}

export default withFeedback(ChangePassword);
