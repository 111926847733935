import { destroy, flow, types } from 'mobx-state-tree';
import FileModel from '../../projects/models/files/FileModel';
import OrganizationGroupModel from './OrganizationGroupModel';
import OrganizationsService from '../OrganizationsService';
import filesService from '../../projects/FilesService';

const OrganizationModel = types
  .model('Organization', {
    id: types.identifier,
    name: types.string,
    projects: types.array(types.string),
    logo: types.maybe(FileModel),
    publicGallery: types.optional(types.boolean, false),
    scaleColumnHidden: types.optional(types.boolean, false),
    groups: types.array(OrganizationGroupModel),
  })
  .views(self => ({
    get nonEmptyGroups() {
      return self.groups.filter(group => group.members.length > 0);
    },

    getGroupById(groupId) {
      return self.groups.find(group => group.id === groupId);
    },
  }))
  .actions(self => ({
    hideScaleColumn: flow(function* hideScaleColumn(scaleColumnHidden) {
      yield OrganizationsService.hideScaleColumn(self.id, scaleColumnHidden);
      self.scaleColumnHidden = scaleColumnHidden;
    }),

    updateLogo: flow(function* updateLogo(organizationId, file) {
      const res = yield filesService.updateOrganizationLogo(organizationId, file);
      self.logo = FileModel.create(res.data);
    }),

    deleteLogo: flow(function* deleteLogo(organizationId) {
      yield filesService.deleteOrganizationLogo(organizationId);
      self.logo = undefined;
    }),

    createGroup: flow(function* createGroup(group) {
      const { data } = yield OrganizationsService.createGroup(self.id, group);
      if (data && !self.getGroupById(data.id)) {
        self.groups.push(data);
      }
    }),

    deleteGroup: flow(function* deleteGroup(group) {
      const groupId = group.id;
      yield OrganizationsService.deleteGroup(self.id, groupId);
      const groupToRemove = self.getGroupById(groupId);
      if (groupToRemove) {
        destroy(groupToRemove);
      }
    }),
  }));

export default OrganizationModel;
