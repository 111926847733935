import { createEnhancedEnum } from '../../../../global/utils/EnumBuilder';

const ACTION_TYPE = {
  NONE: 0,
  EMPATHIZE: 1,
};

const ActionTypeEnum = createEnhancedEnum('ActionType', ACTION_TYPE);

export default ActionTypeEnum;
