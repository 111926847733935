import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from '../../../i18n';
import withFeedback from '../../global/feedback/withFeedback';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import { mapProperties, groupBy, renderFilesByIdea } from '../../search/SearchResults';
import Files from './Files';
import './css/boardFiles.css';

class BoardFiles extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
    project: PropTypes.shape({
      id: PropTypes.string.isRequired,
      organization: PropTypes.string.isRequired,
      organizationModel: PropTypes.object.isRequired,
      files: PropTypes.shape({
        createProjectFiles: PropTypes.func,
        deleteProjectFile: PropTypes.func,
      }),
      experimentsFiles: PropTypes.array,
      tasksFiles: PropTypes.array,
    }).isRequired,
  };

  state = {};

  createFiles = files => {
    return this.props.project.files.createProjectFiles(this.props.project.organization, this.props.project.id, files);
  };

  deleteFile = file => {
    return this.props.project.files.deleteProjectFile(this.props.project.id, file).catch(error => {
      this.props.showErrorMessage(getErrorMessage(error), 5000);
    });
  };

  handleCheckBoxChange = (file, value) => {
    file.togglePublicFile(this.props.project, value).catch(error => {
      this.props.showErrorMessage(getErrorMessage(error), 5000);
    });
  };
  handleSelectChange = (file, value) => {
    file.setCallFor(this.props.project, value).catch(error => {
      this.props.showErrorMessage(getErrorMessage(error), 5000);
    });
  };

  render() {
    return (
      <div className="board-files">
        <Files
          project={this.props.project}
          files={this.props.project.files}
          createFiles={this.createFiles}
          deleteFile={this.deleteFile}
          publicSwitch={this.props.project.organizationModel.publicGallery}
          handleCheckBoxChange={this.handleCheckBoxChange}
          handleSelectChange={this.handleSelectChange}
        />
        {this.props.project.experimentsFiles.length + this.props.project.tasksFiles.length > 0 ? (
          <div className="tasks-files">
            <div className="files-block">
              <h3 className="files-title d-flex flexDirection_row alignItems_center">
                <i className="files-title-icon ic-paperclip" />
                {i18n.t('files.tasksFiles')}
              </h3>
              <div className="card files-block-content">
                {mapProperties(
                  groupBy(
                    this.props.project.tasksFiles.concat(this.props.project.experimentsFiles),
                    file => file.ideaId
                  ),
                  renderFilesByIdea(0)
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withFeedback(observer(BoardFiles));
