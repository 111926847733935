import React from 'react';
import PropTypes from 'prop-types';
import modelOf from '../../../components/propTypes/modelOf';
import { observer } from 'mobx-react';
import { RIEInput } from '@attently/riek';
import i18n from '../../../i18n';
import AddButton from '../../global/elems/AddButton';
import withFeedback from '../../global/feedback/withFeedback';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import Members from '../../global/members/Members';
import UserStore from '../../stores/user/UserStore';
import OrganizationGroupModel from '../../stores/organizations/models/OrganizationGroupModel';

class Group extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
    group: modelOf(OrganizationGroupModel).isRequired,
    className: PropTypes.string,
    canEdit: PropTypes.bool,
    onNameChange: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    canEdit: false,
  };

  canAddOrDelete = !!UserStore.currentUser && UserStore.currentUser.hasOneOrManyAdminRoles;

  state = {
    adding: false,
    membersCollapsed: true,
  };

  handleStartAdding = () => this.setState({ adding: true });

  handleCancelAdding = () => this.setState({ adding: false });

  handleCollapse = () => {
    this.setState({ membersCollapsed: !this.state.membersCollapsed });
  };

  renameGroup = value => {
    this.props.group.renameGroup(value.name).catch(error => {
      this.props.showErrorMessage(getErrorMessage(error), 5000);
    });
  };

  addMember = memberId => {
    return this.props.group.addMember(memberId);
  };

  removeMember = memberId => {
    return this.props.group.removeMember(memberId);
  };

  renderAddButton = isEmptyList => {
    return this.canAddOrDelete ? (
      <AddButton
        isEmptyList={isEmptyList}
        buttonText={i18n.t('members.add')}
        onClick={() => {
          this.setState({ membersCollapsed: false, adding: true });
        }}
      />
    ) : null;
  };

  render() {
    const group = this.props.group;
    return (
      <div className={this.props.className} key={group.id}>
        <div className="d-flex flexDirection_row alignItems_center">
          <i
            className={`members-collapse m-r-10 ${this.state.membersCollapsed ? 'ic-chevron-down' : 'ic-chevron-up'}`}
            onClick={this.handleCollapse}
          />
          <span>
            <RIEInput
              value={group.name}
              propName="name"
              change={this.renameGroup}
              validate={value => value.trim() !== ''}
              classEditing="input input_medium"
              isDisabled={!this.props.canEdit}
            />
          </span>
          <span>
            &nbsp;
            {i18n.t('admin.groups.nbMembers', { count: group.members.length })}
          </span>
          {this.renderAddButton()}
        </div>
        {this.state.membersCollapsed ? null : (
          <div className="members-block">
            <Members
              members={group.membersList}
              membersList={UserStore.colleagues}
              addMember={group.addMember}
              removeMember={group.removeMember}
              confirmDeleteMessage="admin.groups.confirmRemoveMember"
              allowAddRemoveSelf={true}
              adding={this.state.adding}
              startAdding={this.handleStartAdding}
              cancelAdding={this.handleCancelAdding}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withFeedback(observer(Group));
