/* global document */

import React from 'react';
import { Link } from 'react-router-dom';
import UserStore from '../stores/user/UserStore';
import i18n from '../../i18n';
import HeaderUserOrganization from './HeaderUserOrganization';

class HeaderUser extends React.Component {
  preventNext = false;

  state = {
    opened: false,
  };

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  handleOutsideClick = () => {
    if (this.preventNext) {
      this.preventNext = false;
      return;
    }
    this.state.opened && this.setState({ opened: false });
  };

  signOut = () => UserStore.logout();

  toggleDropdown = event => {
    event.stopPropagation();
    this.preventNext = true;
    this.setState({ opened: !this.state.opened });
  };

  renderDropdown() {
    if (this.state.opened) {
      return (
        <div className="header-user-dropdown">
          {UserStore.currentUser.hasManyOrganizations ? (
            <div>
              <HeaderUserOrganization editable={true} organizations={UserStore.currentUser.organizations} />
            </div>
          ) : null}
          {UserStore.currentUser.hasOneOrManyAdminRoles ? (
            <div>
              <Link to="/admin" className="header-user-dropdown-button btn_medium">
                {i18n.t('admin.title')}
              </Link>
            </div>
          ) : null}
          <div>
            <Link to="/changePassword" className="header-user-dropdown-button btn_medium">
              {i18n.t('user.changePassword.link')}
            </Link>
          </div>
          <div>
            <button
              className="header-user-dropdown-button btn btn_medium btn_medium_square btn_primary"
              onClick={this.signOut}
            >
              {i18n.t('user.signOut')}
            </button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const openedIcon = this.state.opened ? 'up' : 'down';

    return (
      <div className="header-item header-user" onClick={this.toggleDropdown}>
        <img src={UserStore.currentUser.avatarURL} className="header-avatar" alt="Profile" />
        {i18n.t('user.hello', {
          firstName: UserStore.currentUser.firstName ? ', ' + UserStore.currentUser.firstName : '',
        })}
        <i className={`ic-chevron-${openedIcon} m-l-10`} />
        {this.renderDropdown()}
      </div>
    );
  }
}

export default HeaderUser;
