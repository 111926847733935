import { executeRequest, jsRoutes } from '../../../components/lib/RequestUtil';

class PublicGalleryService {
  getPublicProjects(organizationName) {
    const { url, method } = jsRoutes.controllers.Projects.getPublicProjects(organizationName);
    return executeRequest(url, { method });
  }
}

const publicGalleryService = new PublicGalleryService();
export default publicGalleryService;
