import { types } from 'mobx-state-tree';
import validationStatusEnum from './ValidationStatusEnum';

const ValidationStatusModel = types
  .model('ValidationStatus', {
    status: types.optional(validationStatusEnum.type, validationStatusEnum.enum.REQUESTED),
    identity: types.string,
  })
  .views(self => ({
    isApproved() {
      return self.status === validationStatusEnum.enum.APPROVED;
    },
    isRefused() {
      return self.status === validationStatusEnum.enum.REFUSED;
    },
  }))
  .actions(self => ({
    handleEvent(event) {
      if (event && event.event && event.eventType) {
        switch (event.eventType) {
          case 'ExperimentValidationApproved':
            Object.assign(self, {
              status: validationStatusEnum.enum.APPROVED,
              identity: event.event.identity.id,
            });
            return true;
          case 'ExperimentValidationRefused':
            Object.assign(self, {
              status: validationStatusEnum.enum.REFUSED,
              identity: event.event.identity.id,
            });
            return true;
          default:
        }
      }
      console.warn('ValidationStatusModel does not know how to handle event', event);
      return false;
    },
  }));

export default ValidationStatusModel;
