import { executeRequest, executeRequestWithBody, jsRoutes } from '../../../components/lib/RequestUtil';

class IdeasService {
  addIdea(projectId, idea) {
    const { url, method } = jsRoutes.controllers.Ideas.createIdea(projectId);
    return executeRequestWithBody(url, idea, { method });
  }

  deleteIdea(idea) {
    const { url, method } = jsRoutes.controllers.Ideas.deleteIdea(idea.projectId, idea.id);
    return executeRequest(url, { method });
  }

  addExperiment(projectId, ideaId, experiment) {
    const { url, method } = jsRoutes.controllers.Experiments.createExperiment(projectId, ideaId);
    return executeRequestWithBody(url, experiment, { method });
  }

  deleteExperiment(experiment) {
    const { url, method } = jsRoutes.controllers.Experiments.deleteExperiment(
      experiment.projectId,
      experiment.ideaId,
      experiment.id
    );
    return executeRequest(url, { method });
  }

  setStatus(idea, status) {
    const { url, method } = jsRoutes.controllers.Ideas.setStatus(idea.projectId, idea.id, status);
    return executeRequest(url, { method });
  }

  setTitle(idea, title) {
    const { url, method } = jsRoutes.controllers.Ideas.setTitle(idea.projectId, idea.id);
    return executeRequestWithBody(url, { title }, { method });
  }

  setText(idea, text) {
    const { url, method } = jsRoutes.controllers.Ideas.setText(idea.projectId, idea.id);
    return executeRequestWithBody(url, { text }, { method });
  }
}

const ideasService = new IdeasService();
export default ideasService;
