import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ideaStateEnum from '../../stores/projects/models/ideas/IdeaStateEnum';
import BoardIdeaCard from './BoardIdeaCard';

class IdeaCardsList extends React.Component {
  static propTypes = {
    project: PropTypes.shape({
      ideas: PropTypes.object.isRequired,
      organizationModel: PropTypes.shape({
        scaleColumnHidden: PropTypes.bool,
      }),
    }).isRequired,
    column: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  renderCard = (card, index) => (
    <BoardIdeaCard idea={card} index={index} key={card.id} project={this.props.project} column={this.props.column} />
  );

  render() {
    return (
      <div>
        {this.props.project.ideas[this.props.column.id].map(this.renderCard)}
        {this.props.project.organizationModel.scaleColumnHidden && this.props.column.id === ideaStateEnum.enum.PPP
          ? this.props.project.ideas[ideaStateEnum.enum.SOLVED].map(this.renderCard)
          : null}
      </div>
    );
  }
}

export default observer(IdeaCardsList);
