import { destroy, detach, flow, types } from 'mobx-state-tree';
import ideaStateEnum from './models/ideas/IdeaStateEnum';
import ProjectModel from './models/ProjectModel';
import projectsService from './ProjectsService';
import userStore from '../user/UserStore';
import eventsStore from '../events/EventsStore';

const projectsStore = types
  .model('Projects', {
    initialized: false,
    projects: types.array(ProjectModel),
  })
  .views(self => ({
    get nbIdeas() {
      return self.projects.reduce((nbIdeas, project) => nbIdeas + project.ideas.nbIdeas, 0);
    },

    get nbExperiments() {
      return self.projects.reduce((nbExperiments, project) => nbExperiments + project.nbExperiments, 0);
    },

    get nbLearnings() {
      return self.projects.reduce((nbLearnings, project) => nbLearnings + project.nbLearnings, 0);
    },

    get nbProjectsSolved() {
      return self.projects.reduce(
        (nbProjectsSolved, project) => nbProjectsSolved + (project.state === ideaStateEnum.enum.SOLVED ? 1 : 0),
        0
      );
    },

    get starredProjects() {
      return self.projects.filter(project => project.starred);
    },

    get ownProjects() {
      return self.projects.filter(project => project.isVisible);
    },

    searchProjects(text, statuses) {
      const statusesToSearch = statuses.length === 0 ? ideaStateEnum.keys : statuses;
      return self.projects.filter(project => statusesToSearch.includes(project.state) && project.contains(text));
    },

    searchIdeas(text, statuses) {
      return self.projects.reduce((ideas, project) => ideas.concat(project.searchIdeas(text, statuses)), []);
    },

    searchExperiments(text) {
      return self.projects.reduce((experiments, project) => experiments.concat(project.searchExperiments(text)), []);
    },

    searchLearnings(text) {
      return self.projects.reduce((learnings, project) => learnings.concat(project.searchLearnings(text)), []);
    },

    searchActions(text) {
      return self.projects.reduce((actions, project) => actions.concat(project.searchActions(text)), []);
    },

    searchFiles(text) {
      return self.projects.reduce((files, project) => files.concat(project.searchFiles(text)), []);
    },

    search(text, types, statuses) {
      return {
        projects: types.length === 0 || types.includes('project') ? self.searchProjects(text, statuses) : [],
        ideas: types.length === 0 || types.includes('idea') ? self.searchIdeas(text, statuses) : [],
        experiments: types.length === 0 || types.includes('experiment') ? self.searchExperiments(text) : [],
        learnings: types.length === 0 || types.includes('learning') ? self.searchLearnings(text) : [],
        actions: types.length === 0 || types.includes('action') ? self.searchActions(text) : [],
        files: types.length === 0 || types.includes('file') ? self.searchFiles(text) : [],
      };
    },

    getFileFromId(fileId) {
      let result;
      self.projects.forEach(project => {
        result = result || project.getFileFromId(fileId);
      });
      return result;
    },

    getProjectById(projectId) {
      return self.projects.find(project => project.id === projectId);
    },
  }))
  .actions(self => ({
    createProject: flow(function* createProject(projectName) {
      const res = yield projectsService.create({
        name: projectName,
        organization: userStore.currentUser.currentOrganization,
        members: [userStore.currentUser.id],
      });
      if (!self.getProjectById(res.data.id)) {
        self.addProject(ProjectModel.create(res.data));
      }
    }),

    deleteProject: flow(function* deleteProject(project) {
      const projectId = project.id;
      yield projectsService.delete(project);
      const projectToRemove = self.getProjectById(projectId);
      if (projectToRemove) {
        self.removeProject(projectToRemove);
      }
    }),

    close() {
      try {
        self.projects.forEach(detach);
        self.projects = [];
        eventsStore.close();
        self.initialized = false;
      } catch (err) {
        console.warn(err);
      }
    },

    refreshOrganizationProjects: flow(function* refreshOrganizationProjects(organizationId) {
      self.close();
      try {
        const { data } = yield projectsService.getProjects(organizationId);
        if (data) {
          data.forEach(project => self.addProject(project));
        }
        eventsStore.open(organizationId);
      } catch (err) {
        console.warn(err);
      }
      self.initialized = true;
    }),

    searchFilesInContentFiles: flow(function* searchFilesInContentFiles(text) {
      return yield projectsService.searchFilesInContentFiles(userStore.currentUser.currentOrganization, text);
    }),

    handleEvent(event) {
      if (event) {
        const project = self.getProjectById(event.projectId);
        switch (event.eventType) {
          case 'ProjectCreated':
            if (project || (event.event && !event.event.project)) {
              return false;
            }
            self.addProject(ProjectModel.create(event.event.project));
            return true;
          case 'ProjectDeleted':
            if (project && event.event && event.event.sequenceNr > project.lastUpdate) {
              return self.removeProject(project);
            }
            return false;
          default:
            if (project) {
              return project.handleEvent(event);
            }
            console.warn(
              `Received event ${event.eventType} for project "${event.projectId}" but this project is unknown`
            );
            return false;
        }
      }
      console.warn('projectsStore does not know how to handle event ', event);
      return false;
    },

    addProject(project) {
      self.projects.push(project);
    },

    removeProject(project) {
      try {
        destroy(project);
        return true;
      } catch (err) {
        return false;
      }
    },
  }))
  .create({ projects: [] });

export default projectsStore;
