import { destroy, flow, getParent, types } from 'mobx-state-tree';
import { caseInsensitiveSearch } from '../../../../../components/lib/SearchUtil';
import userStore from '../../../user/UserStore';
import IdeasService from '../../IdeasService';
import ExperimentModel from '../experiments/ExperimentModel';
import ExperimentStateEnum from '../experiments/ExperimentStateEnum';
import ideaStateEnum from './IdeaStateEnum';
import IdeaStateFromExperimentState from './IdeaStateFromExperimentState';

const IdeaModel = types
  .model('Idea', {
    id: types.maybe(types.identifier),
    title: types.string,
    text: types.optional(types.string, ''),
    status: types.optional(ideaStateEnum.type, ideaStateEnum.enum.IDEAS),
    experiments: types.array(ExperimentModel),
    lastUpdate: types.number,
  })
  .actions(self => ({
    _addExperiment(experiment) {
      self.experiments.unshift(experiment);
    },

    _setStatus(status) {
      self.status = status;
    },

    addExperiment: flow(function* addExperiment(projectId, ideaId, experiment) {
      const organization = userStore.currentUser.currentOrganization;
      const { data } = yield IdeasService.addExperiment(projectId, ideaId, {
        organization,
        ...experiment,
      });
      if (data && !self.getExperimentById(data.id)) {
        self._addExperiment(data);
      }
    }),

    deleteExperiment: flow(function* deleteExperiment(experiment) {
      const experimentId = experiment.id;
      yield IdeasService.deleteExperiment(experiment);
      const experimentToRemove = self.getExperimentById(experimentId);
      if (experimentToRemove) {
        destroy(experimentToRemove);
      }
    }),

    setTitle: flow(function* setTitle(title) {
      yield IdeasService.setTitle(self, title);
      self.title = title;
    }),

    setText: flow(function* setText(text) {
      yield IdeasService.setText(self, text);
      self.text = text;
    }),

    checkAlreadyProcessedEvent(event, eventHandler) {
      if (event.event.sequenceNr > self.lastUpdate) {
        const result = eventHandler(event);
        if (result) {
          self.lastUpdate = event.event.sequenceNr;
        }
        return result;
      } else {
        return false;
      }
    },

    handleEvent(event) {
      switch (event.eventType) {
        case 'IdeaTitleUpdated':
          return self.checkAlreadyProcessedEvent(event, event => {
            self.title = event.event.title;
            return true;
          });
        case 'IdeaTextUpdated':
          return self.checkAlreadyProcessedEvent(event, event => {
            self.text = event.event.text;
            return true;
          });
        case 'IdeaStatusUpdated':
          return self.checkAlreadyProcessedEvent(event, event => {
            self.status = event.event.status;
            return true;
          });
        case 'IdeaExperimentAdded':
          return self.checkAlreadyProcessedEvent(event, () => true);
        case 'IdeaExperimentRemoved':
          return self.checkAlreadyProcessedEvent(event, () => true);
        case 'ExperimentCreated':
          if (!self.getExperimentById(event.experimentId)) {
            self._addExperiment(event.event.experiment);
            return true;
          } else {
            return false;
          }
        case 'ExperimentDeleted':
          var experimentToRemove = self.getExperimentById(event.event.experimentId);
          if (experimentToRemove && event.event.sequenceNr > experimentToRemove.lastUpdate) {
            try {
              destroy(experimentToRemove);
              return true;
            } catch (err) {
              return false;
            }
          }
          return false;
        default:
          return self.handleDefaultEvent(event);
      }
    },

    handleDefaultEvent(event) {
      if (event.experimentId) {
        const experiment = self.getExperimentById(event.experimentId);
        if (experiment) {
          return experiment.handleEvent(event);
        } else {
          console.warn(
            `Received event ${event.eventType} for experiment ${event.experimentId} but this experiment is unknown`
          );
          return false;
        }
      }
      console.warn('IdeaModel does not know how to handle event ', event);
      return false;
    },
  }))
  .views(self => ({
    get nbSolutions() {
      return self.experiments.reduce(
        (nbSolutions, experiment) => nbSolutions + (experiment.state === ExperimentStateEnum.enum.ACCEPTED ? 1 : 0),
        0
      );
    },

    get nbLearnings() {
      return self.experiments.reduce((nbLearnings, experiment) => nbLearnings + experiment.nbLearnings, 0);
    },

    get state() {
      const mostAdvancedExperimentState = self.experiments.reduce(
        (state, experiment) =>
          ExperimentStateEnum.index[experiment.state] > state ? ExperimentStateEnum.index[experiment.state] : state,
        ExperimentStateEnum.index.REFUSED
      );
      return IdeaStateFromExperimentState[mostAdvancedExperimentState];
    },

    contains(text) {
      return caseInsensitiveSearch(self.title, text) || caseInsensitiveSearch(self.text, text);
    },

    getExperimentById(experimentId) {
      return self.experiments.find(experiment => experiment.id === experimentId);
    },

    getTestableExperiments() {
      return self.experiments.filter(
        experiment =>
          experiment.state === ExperimentStateEnum.enum.PPP ||
          experiment.state === ExperimentStateEnum.enum.ACCEPTED ||
          experiment.isTestable()
      );
    },

    getDecidableExperiments() {
      return self.experiments.filter(experiment => experiment.isDecidable());
    },

    getDecisionReadyExperiments() {
      return self.experiments.filter(
        experiment =>
          experiment.state === ExperimentStateEnum.enum.PPP || experiment.state === ExperimentStateEnum.enum.ACCEPTED
      );
    },

    getAcceptedExperiments() {
      return self.experiments.filter(experiment => experiment.state === ExperimentStateEnum.enum.ACCEPTED);
    },

    searchExperiments(text) {
      return self.experiments.filter(experiment => experiment.contains(text));
    },

    searchLearnings(text) {
      return self.experiments.reduce((learnings, experiment) => learnings.concat(experiment.searchLearnings(text)), []);
    },

    searchFiles(text) {
      return self.experiments.reduce((files, experiment) => files.concat(experiment.searchFiles(text)), []);
    },

    getFileFromId(fileId) {
      let result;
      self.experiments.forEach(experiment => {
        result = result || experiment.getFileFromId(fileId);
      });
      return result;
    },

    get allLearnings() {
      return self.experiments.reduce((learnings, experiment) => {
        return learnings.concat(experiment.learnings.slice());
      }, []);
    },

    get allFiles() {
      return self.experiments.reduce((files, experiment) => {
        return files.concat(experiment.files.files.slice());
      }, []);
    },

    get project() {
      return getParent(self, 3);
    },

    get projectId() {
      return self.project.id;
    },
  }));

export default IdeaModel;
