import { detach, flow, types } from 'mobx-state-tree';
import OrganizationModel from './models/OrganizationModel';
import OrganizationsService from './OrganizationsService';

const OrganizationsStore = types
  .model('Organizations', {
    initialized: false,
    organizations: types.array(OrganizationModel),
  })
  .views(self => ({
    getOrganizationById(organizationId) {
      return self.organizations.find(organization => organization.id === organizationId);
    },
    getGroupById(organizationId, groupId) {
      return self.getOrganizationById(organizationId).getGroupById(groupId);
    },
  }))
  .actions(self => ({
    refresh: flow(function* refresh() {
      self.organizations.forEach(detach);
      self.organizations = [];
      try {
        const { data } = yield OrganizationsService.all();
        if (data) {
          data.forEach(organization => self.addOrganization(organization));
        }
      } catch (err) {
        console.error('Error refreshing organizations: ', err);
      }
      self.initialized = true;
    }),

    addOrganization(organization) {
      self.organizations.push(organization);
    },
  }))
  .create({ organizations: [] });

export default OrganizationsStore;
