import { types } from 'mobx-state-tree';
import moment from 'moment-timezone';

const momentType = types.custom({
  name: 'Moment',
  fromSnapshot(value) {
    return moment(value.$date);
  },
  toSnapshot(value) {
    return { $date: value.valueOf() };
  },
  isTargetType(value) {
    let isOfType = false;
    if (typeof value === 'object') {
      isOfType = value && value.isValid && value.isValid();
    }
    return isOfType;
  },
  getValidationMessage(value) {
    let validationMessage = '';
    if (!moment(value).isValid()) {
      validationMessage = 'Invalid moment';
    }
    return validationMessage;
  },
});

export default momentType;
