/* global localStorage */
import { types } from 'mobx-state-tree';
import avatarImg from '../../../main/img/avatar.png';
import UserRoleEnum from './UserRoleEnum';
import userStateEnum from './UserStateEnum';

const UserModel = types
  .model('User', {
    id: types.maybe(types.string),
    organizations: types.array(types.string),
    currentOrganization: types.optional(types.string, ''),
    email: types.maybe(types.string),
    firstName: types.optional(types.string, ''),
    lastName: types.optional(types.string, ''),
    fullName: types.optional(types.string, ''),
    avatarURL: types.optional(types.string, avatarImg),
    roles: types.array(types.string),
    state: types.optional(userStateEnum.type, userStateEnum.enum.NotValidated),
  })
  .views(self => ({
    get displayName() {
      return self.fullName || self.email;
    },
    get isAdmin() {
      return self.roles.includes(UserRoleEnum.index.ADMIN);
    },
    get hasManyOrganizations() {
      return self.organizations.length > 1;
    },
    get isOrgAdmin() {
      return self.roles.includes(UserRoleEnum.index.ORG_ADMIN);
    },
    get hasOneOrManyAdminRoles() {
      return self.isAdmin || self.isOrgAdmin;
    },
    isMemberOfOrganization(organisationId) {
      return self.organizations.includes(organisationId);
    },
  }))
  .actions(self => ({
    removeFromOrganization(organisationId) {
      self.organizations = self.organizations.filter(organization => organization !== organisationId);
    },

    initializeSelectedOrganization() {
      if (self.currentOrganization === '') {
        if (self.organizations && self.organizations.length > 0) {
          const storedCurrentOrganization = localStorage.getItem('currentOrganization');
          if (storedCurrentOrganization && self.organizations.includes(storedCurrentOrganization)) {
            self.currentOrganization = storedCurrentOrganization;
          } else {
            self.setSelectedOrganization(self.organizations[0]);
          }
        }
      }
    },
    setSelectedOrganization(organisationId) {
      self.currentOrganization = organisationId;
      localStorage.setItem('currentOrganization', organisationId);
    },
  }));

export default UserModel;
