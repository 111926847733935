import React from 'react';
import PropTypes from 'prop-types';
import 'url-search-params-polyfill';
import i18n from '../../i18n';
import ProjectsStore from '../stores/projects/ProjectsStore';
import * as SearchResults from './SearchResults';
import './css/SearchPanel.css';

const mapProperties = SearchResults.mapProperties;
const groupBy = SearchResults.groupBy;

class SearchPanel extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  };

  state = {
    searchFilesInContentFiles: [],
    searchFilesInContentFilesError: false,
  };

  textSearched = '';
  typesSearched = [];
  statusSearched = [];
  searchResults = {
    projects: [],
    ideas: [],
    experiments: [],
    learnings: [],
    actions: [],
    files: [],
  };

  componentDidMount() {
    this.getSearchFilesInContentFiles();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.getSearchFilesInContentFiles();
    }
  }

  getSearchFilesInContentFiles() {
    const params = new URLSearchParams(this.props.location.search);
    const textSearched = params.get('text') || '';
    if (textSearched === '') {
      return;
    }
    // FIXME: If we search something and leave the page quicker than the fetch,
    // we will have the following warning in the console for SearchPanel (created by Route):
    /**
     * Warning: Can't call setState (or forceUpdate) on an unmounted component.
     * This is a no-op, but it indicates a memory leak in your application.
     * To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.
     */
    // TODO: Potential resolution with https://developer.mozilla.org/en-US/docs/Web/API/AbortController
    // https://medium.freecodecamp.org/how-to-work-with-react-the-right-way-to-avoid-some-common-pitfalls-fc9eb5e34d9e
    ProjectsStore.searchFilesInContentFiles(textSearched)
      .then(res => {
        if (res.data) {
          this.setState({ searchFilesInContentFiles: res.data.files || [] });
        }
      })
      .catch(() => {
        this.setState({ searchFilesInContentFilesError: true });
      });
  }

  renderSearchedText = () =>
    this.textSearched.length > 0 ? (
      <h2 className="searchPanel-searched">
        <i className="searchPanel-searched-icon ic-search" />
        {i18n.t('search.resultFor')}
        <span className="searchPanel-searched-text">{`"${this.textSearched}"`}</span>
      </h2>
    ) : null;

  renderProjectResults = () =>
    this.typesSearched.length === 0 || this.typesSearched.includes('project') ? (
      <div>
        <h3 className="searchPanel-title">
          <i className="ic-folder searchPanel-title-icon" />
          <div className="searchPanel-title-count">{this.searchResults.projects.length}</div>
          <div className="searchPanel-title-text">
            {i18n.t('search.projectsFound', { count: this.searchResults.projects.length })}
          </div>
        </h3>
        <div className="card searchPanel-block">{this.searchResults.projects.map(SearchResults.renderProject)}</div>
      </div>
    ) : null;

  renderIdeaResults = () =>
    this.typesSearched.length === 0 || this.typesSearched.includes('idea') ? (
      <div>
        <h3 className="searchPanel-title">
          <i className="ic-lightbulb_outline searchPanel-title-icon" />
          <div className="searchPanel-title-count">{this.searchResults.ideas.length}</div>
          <div className="searchPanel-title-text">
            {i18n.t('search.ideasFound', { count: this.searchResults.ideas.length })}
          </div>
        </h3>
        <div className="card searchPanel-block">
          {mapProperties(groupBy(this.searchResults.ideas, idea => idea.projectId), ideas => (
            <div className="searchPanel-group" key={ideas[0].projectId}>
              {SearchResults.renderProject(ideas[0].project)}
              {ideas.map(SearchResults.renderIdea(1))}
            </div>
          ))}
        </div>
      </div>
    ) : null;

  renderExperimentResults = () =>
    this.typesSearched.length === 0 || this.typesSearched.includes('experiment') ? (
      <div>
        <h3 className="searchPanel-title">
          <i className="ic-rocket searchPanel-title-icon" />
          <div className="searchPanel-title-count">{this.searchResults.experiments.length}</div>
          <div className="searchPanel-title-text">
            {i18n.t('search.experimentsFound', { count: this.searchResults.experiments.length })}
          </div>
        </h3>
        <div className="card searchPanel-block">
          {mapProperties(
            groupBy(this.searchResults.experiments, experiment => experiment.projectId),
            experimentsByProject => (
              <div className="searchPanel-group" key={experimentsByProject[0].projectId}>
                {SearchResults.renderProject(experimentsByProject[0].project)}
                {mapProperties(groupBy(experimentsByProject, experiment => experiment.ideaId), experimentsByIdea => (
                  <div className="searchPanel-group" key={experimentsByIdea[0].ideaId}>
                    {SearchResults.renderIdea(1)(experimentsByIdea[0].idea)}
                    {experimentsByIdea.map(SearchResults.renderExperiment(2))}
                  </div>
                ))}
              </div>
            )
          )}
        </div>
      </div>
    ) : null;

  renderLearningResults = () =>
    this.typesSearched.length === 0 || this.typesSearched.includes('learning') ? (
      <div>
        <h3 className="searchPanel-title">
          <i className="ic-book-open searchPanel-title-icon" />
          <div className="searchPanel-title-count">{this.searchResults.learnings.length}</div>
          <div className="searchPanel-title-text">
            {i18n.t('search.learningsFound', { count: this.searchResults.learnings.length })}
          </div>
        </h3>
        <div className="card searchPanel-block">
          {mapProperties(groupBy(this.searchResults.learnings, learning => learning.projectId), learningsByProject => (
            <div className="searchPanel-group" key={learningsByProject[0].projectId}>
              {SearchResults.renderProject(learningsByProject[0].project)}
              {mapProperties(
                groupBy(learningsByProject, learning => learning.ideaId),
                SearchResults.renderLearningsByIdea(1)
              )}
            </div>
          ))}
        </div>
      </div>
    ) : null;

  renderActionResults = () => {
    return this.typesSearched.length === 0 || this.typesSearched.includes('action') ? (
      <div>
        <h3 className="searchPanel-title">
          <i className="ic-education-quiz searchPanel-title-icon" />
          <div className="searchPanel-title-count">{this.searchResults.actions.length}</div>
          <div className="searchPanel-title-text">
            {i18n.t('search.actionsFound', { count: this.searchResults.actions.length })}
          </div>
        </h3>
        <div className="card searchPanel-block">
          {mapProperties(groupBy(this.searchResults.actions, action => action.projectId), actions => {
            actions[0].project.additionalLink = '/todo';
            return (
              <div className="searchPanel-group" key={actions[0].projectId}>
                {SearchResults.renderProject(actions[0].project)}
                {actions.map(SearchResults.renderAction(1))}
              </div>
            );
          })}
        </div>
      </div>
    ) : null;
  };

  renderFileResults = () =>
    this.typesSearched.length === 0 || this.typesSearched.includes('file') ? (
      <div>
        <h3 className="searchPanel-title">
          <i className="ic-paperclip searchPanel-title-icon" />
          <div className="searchPanel-title-count">{this.searchResults.files.length}</div>
          <div className="searchPanel-title-text">
            {i18n.t('search.filesFound', { count: this.searchResults.files.length })}
          </div>
        </h3>
        <div className="card searchPanel-block">
          {mapProperties(groupBy(this.searchResults.files, file => file.projectId), filesByProject => (
            <div className="searchPanel-group" key={filesByProject[0].projectId}>
              {SearchResults.renderProject(filesByProject[0].project)}
              {mapProperties(groupBy(filesByProject, file => file.ideaId), SearchResults.renderFilesByIdea(1))}
            </div>
          ))}
        </div>
      </div>
    ) : null;

  renderContentFileResults = () => {
    const files = this.state.searchFilesInContentFiles.map(file => ProjectsStore.getFileFromId(file.id));
    if (this.typesSearched.length === 0 || this.typesSearched.includes('file')) {
      return (
        <div>
          <h3 className="searchPanel-title">
            <i className="ic-paperclip searchPanel-title-icon" />
            <div className="searchPanel-title-count">{files.length}</div>
            <div className="searchPanel-title-text">{i18n.t('search.contentFilesFound', { count: files.length })}</div>
          </h3>
          <div className="card searchPanel-block">
            {this.state.searchFilesInContentFilesError
              ? SearchResults.renderError()
              : mapProperties(groupBy(files, file => file.projectId), filesByProject => (
                  <div className="searchPanel-group" key={filesByProject[0].projectId}>
                    {SearchResults.renderProject(filesByProject[0].project)}
                    {mapProperties(groupBy(filesByProject, file => file.ideaId), SearchResults.renderFilesByIdea(1))}
                  </div>
                ))}
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    const params = new URLSearchParams(this.props.location.search);
    const textSearched = params.get('text') || '';
    const typesSearched = params.get('type') || '';
    const statusSearched = params.get('status') || '';
    if (
      this.textSearched !== textSearched ||
      this.typesSearched.join(',') !== typesSearched ||
      this.statusSearched.join(',') !== statusSearched
    ) {
      this.textSearched = textSearched;
      this.typesSearched = typesSearched.split(',').filter(s => s.length > 0);
      this.statusSearched = statusSearched.split(',').filter(s => s.length > 0);
      this.searchResults = ProjectsStore.search(this.textSearched, this.typesSearched, this.statusSearched);
    }

    return (
      <div className="panel searchPanel">
        {this.renderSearchedText()}
        {this.renderProjectResults()}
        {this.renderIdeaResults()}
        {this.renderExperimentResults()}
        {this.renderLearningResults()}
        {this.renderActionResults()}
        {this.renderFileResults()}
        {this.renderContentFileResults()}
      </div>
    );
  }
}

export default SearchPanel;
