/*global window */

import React from 'react';
import PropTypes from 'prop-types';
import { getType, isStateTreeNode } from 'mobx-state-tree';
import { Draggable } from 'react-beautiful-dnd';
import { withRouter } from 'react-router-dom';
import i18n from '../../../i18n';
import DeleteButton from '../../global/elems/DeleteButton';
import withFeedback from '../../global/feedback/withFeedback';
import Member from '../../global/members/Member';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import {
  getColorForExperimentStateIcon,
  getExperimentStateIcon,
  getActionStatusIcon,
} from '../../global/utils/IconUtils';
import UserStore from '../../stores/user/UserStore';
import '../columns/css/BoardIdeaCard.css';

class BoardTaskCard extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
  };

  canEditDelete =
    !!UserStore.currentUser &&
    (UserStore.currentUser.hasOneOrManyAdminRoles || this.props.project.isMember(UserStore.currentUser.id));

  onDelete = event => {
    event.stopPropagation();
    if (window.confirm(`Do you really want to delete the task "${this.props.task.title}" ?`)) {
      if (this.props.task.idea) {
        this.props.task.idea.deleteExperiment(this.props.task).catch(error => {
          this.props.showErrorMessage(getErrorMessage(error), 5000);
        });
      } else {
        this.props.project.tasks.deleteAction(this.props.task).catch(error => {
          this.props.showErrorMessage(getErrorMessage(error), 5000);
        });
      }
    }
  };

  goToTask = () => {
    let url = '/todo';
    if (getType(this.props.task).name === 'Experiment') {
      url = '/ideas/' + this.props.task.ideaId + '/experiments/' + this.props.task.id;
    }
    if (getType(this.props.task).name === 'Action') {
      url = '/actions/' + this.props.task.id;
    }
    this.props.history.push(
      '/projects/' + this.props.project.id + url + '?back=/projects/' + this.props.project.id + '/todo'
    );
  };

  getStateIcon = () => {
    if (!isStateTreeNode(this.props.task)) {
      return '';
    }
    if (getType(this.props.task).name === 'Experiment') {
      return getExperimentStateIcon(this.props.task.state);
    }
    if (getType(this.props.task).name === 'Action') {
      return getActionStatusIcon(this.props.task.status);
    }
    return '';
  };

  renderAssignees(assignee) {
    const user = UserStore.colleaguesMap[assignee];
    return user ? <Member member={user} key={user.id} thumbnail={true} /> : null;
  }

  renderTask() {
    return (
      <div className="board-ideaCard-title" onClick={this.goToTask}>
        <i
          className={'board-ideaCard-experiment-state ' + this.getStateIcon()}
          style={{ color: getColorForExperimentStateIcon(this.props.task.state) }}
          title={i18n.t('experiment.' + this.props.task.state)}
        />
        {this.props.task.title}
        {this.props.task.assignees ? this.props.task.assignees.map(this.renderAssignees) : null}
      </div>
    );
  }

  renderContent = provided => (
    <div
      className="board-ideaCard"
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={provided.draggableProps.style}
    >
      <DeleteButton
        onClick={this.onDelete}
        title="Delete task"
        size="small"
        square={true}
        className="boardIdeaCard-btnTrash"
        canDelete={this.canEditDelete}
      />
      {this.renderTask()}
    </div>
  );

  render() {
    if (!this.canEditDelete) {
      return <div className="board-ideaCard">{this.renderTask()}</div>;
    }
    return (
      <Draggable draggableId={this.props.task.id} index={this.props.index}>
        {this.renderContent}
      </Draggable>
    );
  }
}

export default withRouter(withFeedback(BoardTaskCard));
