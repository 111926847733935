import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import { getColorForExperimentStateIcon, getExperimentStateIcon } from '../../global/utils/IconUtils';
import ideaStateEnum from '../../stores/projects/models/ideas/IdeaStateEnum';
import ExperimentStateEnum from '../../stores/projects/models/experiments/ExperimentStateEnum';
import UserStore from '../../stores/user/UserStore';

class ExperimentsList extends React.Component {
  static propTypes = {
    column: PropTypes.shape({
      id: PropTypes.oneOf(ideaStateEnum.keys).isRequired,
    }).isRequired,
    idea: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    project: PropTypes.shape({
      isMember: PropTypes.func.isRequired,
    }).isRequired,
    experiments: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          state: PropTypes.oneOf(ExperimentStateEnum.keys),
        })
      ),
      MobxPropTypes.observableArray,
    ]).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        projectId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    extended: true,
  };

  canAdd =
    UserStore.currentUser &&
    (UserStore.currentUser.hasOneOrManyAdminRoles || this.props.project.isMember(UserStore.currentUser.id));

  static getExperimentTab(experimentState) {
    let tab;
    switch (experimentState) {
      case ExperimentStateEnum.enum.TESTING:
      case ExperimentStateEnum.enum.PPP:
        tab = 'results';
        break;
      case ExperimentStateEnum.enum.ACCEPTED:
        tab = 'solutions';
        break;
      default:
        tab = '';
    }
    return tab;
  }

  handleStartAdding = event => {
    event.stopPropagation();
    this.props.history.push(
      `/projects/${this.props.match.params.projectId}/ideas/${this.props.idea.id}/experiments/new`
    );
  };

  renderExperiment = experiment => {
    return (
      <Link
        className="board-ideaCard-experiment text_normal"
        key={experiment.id}
        to={`/projects/${this.props.match.params.projectId}/ideas/${this.props.idea.id}/experiments/${
          experiment.id
        }/${ExperimentsList.getExperimentTab(experiment.state)}`}
        title={i18n.t('see.experiment')}
      >
        <i
          className={'board-ideaCard-experiment-state ' + getExperimentStateIcon(experiment.state)}
          style={{ color: getColorForExperimentStateIcon(experiment.state) }}
          title={i18n.t('experiment.' + experiment.state)}
        />
        <div className="board-ideaCard-experiment-text">{experiment.title}</div>
        <i className="ic-arrow-right board-ideaCard-experiment-cta" />
      </Link>
    );
  };

  renderAddButton = () => {
    if (!this.canAdd) {
      return null;
    }
    return (
      <div
        className="btn btn_primary btn_small_square btn_small"
        onClick={this.handleStartAdding}
        title={i18n.t('experiment.createIt')}
      >
        <i className="ic-plus" />
      </div>
    );
  };

  render() {
    const extendedIcon = this.state.extended ? 'ic-chevron-up' : 'ic-chevron-down';
    return (
      <div className="board-ideaCard-experiments">
        <div
          className="board-ideaCard-experiments-header"
          onClick={() =>
            this.setState({
              extended: this.props.experiments.length === 0 || !this.state.extended,
            })
          }
        >
          <i className="ic-rocket board-ideaCard-experiments-header-icon" />
          <div className="board-ideaCard-experiments-header-text">{i18n.t('experiment.plurial')}</div>
          {this.props.column.id === ideaStateEnum.enum.MVP ? this.renderAddButton() : null}
          {this.props.experiments.length > 0 ? (
            <i className={`board-ideaCard-experiments-header-cta m-l-5 ${extendedIcon}`} />
          ) : null}
        </div>
        {this.state.extended && this.props.experiments.length > 0 ? (
          <div className="board-ideaCard-experiments-content">{this.props.experiments.map(this.renderExperiment)}</div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(observer(ExperimentsList));
