import { flow, types } from 'mobx-state-tree';
import ProjectPublicGalleryModel from './ProjectPublicGalleryModel';
import PublicGalleryService from '../PublicGalleryService';

const PublicGalleryModel = types
  .model('PublicGallery', {
    id: types.maybe(types.identifier),
    name: types.maybe(types.string),
    initialized: types.optional(types.boolean, false),
    projects: types.array(ProjectPublicGalleryModel),
  })
  .actions(self => ({
    getPublicProjects: flow(function* getPublicProjects(organizationName) {
      if (!self.initialized) {
        try {
          const { data } = yield PublicGalleryService.getPublicProjects(organizationName);
          self = data;
          self.initialized = true;
        } catch (error) {
          self = {};
          self.initialized = false;
        }
      }
      return self;
    }),
  }));

const publicGalleryModel = PublicGalleryModel.create();
export default publicGalleryModel;
