import { types } from 'mobx-state-tree';
import FilesModel from '../../projects/models/files/FilesModel';

const ProjectPublicGalleryModel = types.model('ProjectPublicGallery', {
  id: types.maybe(types.identifier),
  name: types.string,
  files: types.optional(FilesModel, () => FilesModel.create()),
});

export default ProjectPublicGalleryModel;
