const unknownErrorMessage = 'Une erreur est survenue.';

export default {
  meta: {
    defaultTitle: '',
    description: '',
    applicationName: 'Reacteev Toolkit',
  },

  errors: {
    unknownError: unknownErrorMessage,
    forbidden: 'Vous n’avez pas la permission d’effectuer cette action.',
    unknownLight: 'Une erreur est survenue, merci de réessayer dans quelques instants.',
    network: 'Erreur de connexion, vérifiez votre connexion Internet et réessayez dans quelques instants.',
    IllegalArgumentException: 'Données invalides, merci de vérifier votre saisie',
    invalid: {
      data: 'Données incorrectes : {{errors}}',
      credentials: 'Adresse e-mail ou mot de passe invalides',
      current: {
        password: 'Le mot de passe est incorrect',
      },
    },
    error: {
      email: 'L’adresse email est incorrecte',
      minLength: '{{fieldName}} est trop court',
      passwords: {
        mismatch: 'Les mots de passe ne correspondent pas',
      },
    },
    token: {
      notfound: 'Le jeton n’est pas valide, il se peut que le lien soit incorrect ou que vous l’ayez déjà utilisé',
      invalid: 'Le jeton n’est pas valide, il se peut que le lien soit incorrect ou que vous l’ayez déjà utilisé',
    },
    unknown: {
      user: 'Aucun utilisateur connu avec cette adresse email',
    },
    already: {
      exists: 'Cet élément existe déjà',
    },
    nothing: {
      to: {
        do: 'Rien à faire',
      },
    },
    user: {
      disabled: 'Votre compte est désactivé, merci de contacter votre administrateur',
    },
  },

  dashboard: {
    title: 'Dashboard',
    innovationPipe: 'Pipe d’Innovation',
    newsFeed: 'Actualités',
    recentlyUploaded: 'Derniers téléchargements',
    stats: {
      projects: {
        zero: 'Projet',
        one: 'Projet',
        other: 'Projets',
      },
      ideas: {
        zero: 'Idée',
        one: 'Idée',
        other: 'Idées',
      },
      learnings: {
        zero: 'Apprentissage',
        one: 'Apprentissage',
        other: 'Apprentissages',
      },
      experiments: {
        zero: 'Experiment',
        one: 'Experiment',
        other: 'Experiments',
      },
      solvedChallenges: {
        zero: 'Challenge résolu',
        one: 'Challenge résolu',
        other: 'Challenges résolus',
      },
    },
  },

  projectBoard: {
    title: 'Projets {{organizationName}}',
  },

  projects: {
    title: 'Projets',
    IDEAS: 'Idéation',
    SOLVED: 'Projet réalisé',
    MVP: 'Prototypage',
    TEST: 'Experimentation',
    PPP: 'En décision',
    new: {
      title: 'NOUVEAU PROJET',
      name: {
        label: 'Titre',
        placeholder: 'Titre',
      },
      create: 'CRÉER',
    },
    add: 'CRÉER UN NOUVEAU PROJET',
    delete: 'Supprimer le projet',
    confirmDelete: 'Souhaitez-vous vraiment supprimer le projet "{{projectName}}" ?',
  },

  action: {
    new: {
      title: {
        placeholder: 'Votre super action',
      },
      create: 'AJOUTER',
    },
    add: {
      text: 'AJOUTER UNE ACTION',
      title: 'Ajouter une action',
    },
    description: 'Description',
    confirmDelete: 'Souhaitez-vous vraiment supprimer l’action "{{actionTitle}}" ?',
    type: {
      EMPATHIZE: 'Empathie',
      NONE: 'Aucun',
    },
  },

  task: {
    assignees: {
      add: 'AJOUTER',
      title: 'Assignés',
    },
    status: {
      BACKLOG: 'Backlog',
      TODO: 'A faire',
      INPROGRESS: 'En cours',
      DONE: 'Fait',
    },
  },

  empathize: {
    EMPATHIZE: 'Empathie',
  },

  idea: {
    IDEAS: 'Idéer',
    MVP: 'Expérimenter',
    TEST: 'Analyser',
    PPP: 'Décider',
    SOLVED: 'Déployer',
    new: {
      title: {
        placeholder: 'Votre super idée',
      },
      create: 'AJOUTER',
    },
    add: {
      text: 'AJOUTER UNE IDÉE',
      title: 'Ajouter une idée',
    },
    delete: 'Supprimer l’idée',
    description: {
      label: 'Description',
      placeholder: 'Décrivez votre idée ici...',
    },
  },

  experiment: {
    plurial: 'Experiments',
    createIt: 'Créer un experiment',
    deleteIt: 'Supprimer l’experiment',
    title: {
      label: 'Titre',
      placeholder: 'Titre',
    },
    REFUSED: 'Refusé',
    BUILDING: 'En construction',
    PIVOTED: 'Pivoté',
    TESTING: 'En test',
    PPP: 'En décision',
    ACCEPTED: 'Accepté',
    new: 'Nouvel Experiment',
    create: 'CRÉER',
    accept: 'Accepter',
    refuse: 'Refuser',
    pivot: 'Pivoter',
    confirmDelete: 'Souhaitez-vous vraiment supprimer l’experiment "{{experimentTitle}}" ?',
  },

  board: {
    title: 'Board',
    backToBoard: 'Retour au board',
    businessChallenge: {
      description: {
        label: 'Hypothèse ou croyance la plus importante',
        placeholder:
          `Quel problème business / client / consommateur / utilisateur ai-je envie de résoudre\xa0? Quel est le problème aujour’hui\xa0? POURQUOI est-ce un problème\xa0?` +
          ` Quelle est la conséquence\xa0?)
Quel est l’état futur désiré\xa0? Qu’ai-je besoin d’apprendre pour avancer\xa0? Qu’est-ce qui est incertain\xa0?`,
      },
      customers: {
        label: 'Question principale à laquelle répondre',
        placeholder: `Quel segment client ciblons-nous\xa0? Qui n’est pas une cible dans le cadre de ce business challenge\xa0?
(Evaluer la situation en utilisant de la DATA autant que possible).`,
      },
      expectation: {
        label: 'Résultat attendu',
        placeholder: `Quels sont les apprentissages, que veut-on avoir dérisqué à la fin de ce challenge\xa0?
A quoi ressemble le succès\xa0? Qu’est-ce qu’on verrait/entendrait/observerait\xa0? Quels comportements, de quels clients, changeraient ou évolueraient en cas de succès\xa0?
A quoi ressemblerait l'échec\xa0? A quoi faut-il être attentif pour l’éviter\xa0?`,
      },
      metrics: {
        label: 'Métriques',
        placeholder: `Y’a-t-il un objectif précis ou est-ce plutôt flou\xa0?
Quels KPIs/métriques doivent évoluer pour considérer que cela résout le problème\xa0?
(ex\xa0: Notoriété, Acquisition, Activation, Rétention, Revenu or Bouche-à-oreille) Commment est-ce que je mesure le succès (résultat) et le progrès\xa0?`,
      },
      context: {
        label: 'Contexte',
        placeholder: `Quel est l’état de l’art\xa0?
Quelles sont les tendances\xa0?
Qu’a-t-on déjà\xa0?
Que sait-on des plans des concurrents\xa0?
Existe-t-il déjà des produits ou services qui ciblent ou résolvent le même problème\xa0?
Est-ce que l'équipe cherche à étendre le marché ou à se battre pour des parts de marché existantes\xa0?`,
      },
      constraints: {
        label: 'Contraintes',
        placeholder: `Quelles sont les contraintes réglementaires ou techniques à prendre en compte\xa0?
Y'a-t-il une deadline\xa0?`,
      },
      resources: {
        label: 'Ressources',
        placeholder: `Etudes ou données existantes / connues sur le sujet.`,
      },
      teamCoordinator: {
        label: 'Team coordinator',
        placeholder: `Qui est votre Team coordinator\xa0?`,
      },
      businessOwner: {
        label: 'Business Owner',
        placeholder: `Qui est votre Business owner\xa0?`,
      },
      builder: {
        label: 'Builder',
        placeholder: `Qui est votre Builder\xa0?`,
      },
      catalyst: {
        label: 'Catalyst',
        placeholder: `Qui est votre Catalyst\xa0?`,
      },
    },
    errors: {
      missingExperimentCard:
        'Il n’existe pas d’Experiment pour tester cette idée. L’experiment card doit être remplie.',
      missingTestsResults:
        'Il n’y a pas d’Experiment prêt pour l’étape de décision. Les résultats des tests doivent être renseignés.',
      noDecidedExperiment: 'Aucun Experiment n’est prêt pour être envisagé comme solution.',
    },
  },

  todo: {
    title: 'À faire',
  },

  businessChallenge: {
    title: 'Business Challenge',
  },

  learningCard: {
    title: 'Carte d’apprentissage',
  },

  learnings: {
    title: 'Apprentissages',
    emptyText: 'Pas encore d’apprentissage',
    status: 'Statut',
    text: {
      label: 'Texte',
      placeholder: 'Texte',
    },
    save: 'SAUVER',
    add: 'AJOUTER',
    confirmDelete: 'Souhaitez-vous vraiment supprimer cet apprentissage "{{learningText}}" ?',
    painPoints: {
      title: 'Pain Points',
      emptyText: 'Pas encore de pain point',
    },
    gainPoints: {
      title: 'Gain Points',
      emptyText: 'Pas encore de gain point',
    },
    otherLearnings: {
      title: 'Autres Apprentissages',
      emptyText: 'Pas encore d’autre apprentissage',
    },
    tasksLearnings: 'Apprentissages des cartes',
  },

  experimentCard: {
    title: 'Experiment Card',
    learningGoal: {
      label: 'Objectif d’apprentissage',
      placeholder:
        'Que devons-nous apprendre ? Quelle est notre hypothèse la plus risquée ? Quelle est notre priorité ?',
    },
    hypothesis: {
      label: 'Hypothèses & Assumptions',
      placeholder:
        'Sont-elles falsifiables ? Sont-elles spécifiques ? Ya-t-il une causalité ? Ex : si X puis Y, est-ce pertinent pour l’objectif d’apprenstissage ?',
    },
    metrics: {
      label: 'Metrics/Data',
      placeholder: 'Qualitatives ou quantitatives ? Sont-elle actionnables ? Sont-elles mesurables ?',
    },
    invalidationCriteria: {
      label: 'Critère d’invalidation',
      placeholder: 'Si cela arrive, notre hypothèse est clairement fausse !',
    },
    earlyStopCondition: {
      label: 'Early Stop Condition',
      placeholder: 'Ci cela arrive, on arrête ! L’Experiment s’interrompt, rétro !',
    },
    plan: {
      label: 'Plan',
      placeholder:
        'Comment allons-nous collecter les données ? Est-ce spécifique ? Est-ce réalisable ? Faire le lien avec tout document l’appuyant',
    },
  },

  results: {
    title: 'Résultats',
  },

  nextSteps: {
    title: 'Prochaines étapes',
  },

  search: {
    placeholder: 'Rechercher ...',
    resultFor: 'Résultats de recherche pour ',
    projectsFound: {
      zero: 'Aucun projet trouvé',
      one: 'Projet trouvé',
      other: 'Projets trouvés',
    },
    ideasFound: {
      zero: 'Aucune idée trouvée',
      one: 'Idée trouvée',
      other: 'Idées trouvées',
    },
    experimentsFound: {
      zero: 'Aucun Experiment trouvé',
      one: 'Experiment trouvé',
      other: 'Experiments trouvés',
    },
    learningsFound: {
      zero: 'Aucun Apprentissage trouvé',
      one: 'Apprentissage trouvé',
      other: 'Apprentissages trouvés',
    },
    actionsFound: {
      zero: 'Aucune action trouvée',
      one: 'Action trouvée',
      other: 'Actions trouvées',
    },
    filesFound: {
      zero: 'Aucun fichier trouvé',
      one: 'Fichier trouvé',
      other: 'Fichiers trouvés',
    },
    contentFilesFound: {
      zero: 'Aucun contenu de fichier trouvé',
      one: 'Contenu de fichier trouvé',
      other: 'Contenus de fichiers trouvés',
    },
  },

  see: {
    project: 'Voir le projet',
    learnings: 'Voir les apprentissages',
    idea: 'Voir l’idée',
    experiment: 'Voir l’experiment',
    action: 'Voir l’action',
    file: 'Ouvrir le fichier',
  },

  numberOf: {
    members: 'Nombre de membres',
    ideas: 'Nombre d’idées',
    experiments: 'Nombre d’experiments',
    solutions: 'Nombre de solutions',
  },

  solutions: {
    title: 'Solutions',
  },

  files: {
    title: 'Fichiers',
    emptyList: 'Pas encore de fichier',
    add: 'AJOUTER',
    uploading: 'Téléchargement...',
    clickOrDrag: 'Cliquer ou déposer des fichiers ici',
    confirmDelete: 'Souhaitez-vous vraiment supprimer le fichier {{fileName}} ?',
    public: 'Public',
    callFor: {
      IDEA: 'Idée',
      PROJECT: 'Projet',
      PROPOSAL: 'Proposition',
      INFORMATION: 'Information',
    },
    callForSentence: {
      IDEA: 'Appel à idées :',
      PROJECT: 'Appel à projets :',
      PROPOSAL: 'Appel à propositions :',
      INFORMATION: 'Appel à informations :',
    },
    tasksFiles: 'Fichiers des cartes',
  },

  members: {
    title: 'Membres',
    emptyList: 'Pas encore de membre',
    new: {
      label: 'Ajouter un membre',
      placeholder: 'Nom du membre',
    },
    add: 'Ajouter',
    confirmDelete: 'Voulez-vous vraiment enlever {{memberName}} de ce projet ?',
  },

  validation: {
    requestValidation: 'Demander une validation',
    requested: 'Validation pour {{groupName}} demandée par {{fullName}}',
    approved: 'Approuvé pour {{groupName}} par {{fullName}}',
    refused: 'Refusé pour {{groupName}} par {{fullName}}',
    sendRequest: 'Envoyer',
    requestError: 'Erreur à l’envoi de la demande de validation : {{errorMessage}}',
    emailsWillBeSent: 'Validation demandée. Des emails au(x) groupe(s) {{groupNames}} vont être envoyés sous peu.',
  },

  user: {
    welcomeBack: 'Ravi de vous revoir !',
    forgotPassword: 'Oops ! J’ai oublié mon mot de passe',
    hello: 'Bonjour{{firstName}} !',
    submit: 'Envoyer',
    signOut: 'Déconnexion',
    cancel: 'Annuler',
    signIn: {
      email: 'Email',
      password: 'Mot de passe',
    },
    changePassword: {
      link: 'Mot de passe',
      title: 'Modifier votre mot de passe :',
      currentPassword: 'Mot de passe actuel',
      newPassword1: 'Nouveau mot de passe',
      newPassword2: 'Confirmer le nouveau mot de passe',
      success: 'Le mot de passe a été changé avec succès',
    },
    resetPassword: {
      request: 'Indiquez votre email, nous allons vous envoyer un lien pour réinitialiser votre mot de passe',
      invalidUrl: 'L’URL de mise à jour de mot de passe est incorrecte',
      title: 'Choisissez un nouveau mot de passe :',
      success: 'Mot de passe changé avec succès',
    },
    check: {
      inbox: 'Nous venons de vous envoyer un email pour réinitialiser votre mot de passe, ouvrez votre boite mail svp',
    },
  },

  events: {
    ProjectCreated: 'Projet "{{projectName}}" créé',
    ProjectDeleted: 'Projet "{{projectName}}" supprimé',
    ProjectFilesAdded: 'Les fichiers {{fileNames}} ont été ajoutés au projet "{{projectName}}"',
    ProjectFilesRemoved: '',
    LearningCreated: 'Nouvel apprentissage sur le projet "{{projectName}}" : {{learningText}}',
    LearningDeleted: 'Apprentissage "{{learningText}}" supprimé sur le projet "{{projectName}}"',
    IdeaCreated: 'Idée "{{ideaTitle}}" créée dans le projet "{{projectName}}"',
    IdeaDeleted: 'Idée "{{ideaTitle}}" supprimée sur le projet "{{projectName}}"',
    IdeaStatusUpdated: 'Projet "{{projectName}}" résolu !',
    ExperimentCreated: 'Nouvel experiment sur le projet "{{projectName}}": {{experimentTitle}}',
    ExperimentDeleted: 'Experiment "{{experimentTitle}}" supprimé sur le projet "{{projectName}}"',
    ExperimentResultsUpdated: 'Résultats mis à jour sur le projet "{{projectName}}": {{resultsText}}',
    ExperimentSolutionsUpdated: 'Solutions mises à jour sur le projet "{{projectName}}": {{solutionsText}}',
    ExperimentValidationRequested:
      'Validation demandée de la part de "{{groupNames}}" sur l’experiment "{{experimentTitle}}" du projet "{{projectName}}"',
    ExperimentValidationApproved:
      'Validation approuvée pour l’experiment "{{experimentTitle}}" dans le projet "{{projectName}}"',
    ExperimentValidationRefused:
      'Validation refusée pour l’experiment "{{experimentTitle}}" dans le projet "{{projectName}}"',
    ExperimentResultFilesAdded: 'Fichiers de résultats {{fileNames}} ajoutés au projet "{{projectName}}"',
    ExperimentResultFileRemoved: '',
    ExperimentSolutionFilesAdded: 'Fichiers de solution {{fileNames}} ajoutés au projet "{{projectName}}"',
    ExperimentSolutionFileRemoved: '',
  },

  admin: {
    title: 'Admin',
    organizationAdmin: 'Administration {{organizationName}} ',
    logo: {
      title: 'Logo de l’organisation',
      clickOrDrag: 'Cliquer ou glisser une image ici',
      confirmDelete: 'Voulez-vosu vraiment supprimer le logo de l’organisation ?',
    },
    scaleColumnHidden: 'Cacher la colomne Scale',
    scaleColumnHiddenLabel:
      'Si coché, la colomne Scale sera cachée, toutes les Cartes resteront dans la colonne Décider',
    exportAll: 'Exporter toutes les données',
    groups: {
      title: 'Groupes de validation',
      emptyList: 'Pas encore de groupe de validation',
      new: {
        title: 'CRÉER UN NOUVEAU GROUPE',
        name: {
          label: 'Nom',
          placeholder: 'Nom du groupe',
        },
        create: 'CRÉER',
      },
      add: 'AJOUTER',
      delete: 'Supprimer le groupe',
      confirmDelete: 'Voulez-vous vraiment supprimer le groupe {{groupName}} ?',
      nbMembers: {
        zero: '(aucun membre)',
        one: '({{count}} membre)',
        other: '({{count}} membres)',
      },
      confirmRemoveMember: 'Voulez-vous vraiment supprimer {{memberName}} de ce groupe ?',
    },
    users: {
      title: 'Utilisateurs',
      new: {
        title: 'NOUVEL UTILISATEUR',
        firstName: {
          label: 'Prénom',
          placeholder: 'Prénom',
        },
        lastName: {
          label: 'Nom',
          placeholder: 'Nom',
        },
        email: {
          label: 'Email',
          placeholder: 'email@company.com',
        },
        enableUser: {
          text: 'Activer l’utilisateur ?',
          title: 'Un utilisateur désactivé ne peut pas se connecter mais peut faire partie d’un groupe de validation.',
        },
        create: 'CRÉER',
      },
      confirmDelete: 'Voulez-vous vraiment supprimer {{memberName}} de cette organisation ?',
      add: {
        requestError: 'Erreur à l’ajout d’utilisateur. {{errorMessage}}',
      },
      remove: {
        requestError: 'Erreur à la suppression de l’utilisateur. {{errorMessage}}',
      },
      enable: {
        requestError: 'Erreur à l’activation de l’utilisateur. {{errorMessage}}',
      },
      disable: {
        requestError: 'Erreur à la désactivation de l’utilisateur. {{errorMessage}}',
      },
      errors: {
        userNotFound: 'Cet utilisateur n’existe pas',
        userNotMember: 'L’utilisateur n’est pas membre de l’organisation',
      },
      nbUsers: {
        zero: '(aucun utilisateur)',
        one: '({{count}} utilisateur)',
        other: '({{count}} utilisateurs)',
      },
      state: {
        enabled: 'Un utilisateur activé peut se connecter et utiliser l’outil.',
        disabled: 'Un utilisateur désactivé ne peut pas se connecter mais peut faire partie d’un groupe de validation.',
      },
    },
  },

  help: {
    dosAndDont: 'A FAIRE ET A EVITER',
    toolGuidelines: 'NOTICE D’UTILISATION DU REACTEEV TOOLKIT',

    interview: {
      title: 'Interview',
      text: '',
      howTo: {
        title: 'Comment',
        block1: {
          title: 'Prévoir l’interview',
          text1: 'Définir l’objectif d’apprentissage.',
          text2: 'Définir une assomption clé à propos d’un persona.',
          text3:
            'Créer un sondage filtre avec des questions simples qui permettront de valider que la personne interrogée correspond au persona ciblé.',
          text4: 'Faire un guide d’interview.',
        },
        block2: {
          title: 'Conduire l’interview',
          text1: 'Cadrer : Résumer l’objectif de l’interview avec le client interviewé.',
          text2: 'Qualifier : Poser des questions filtre pour determiner sur le client correspond à notre persona.',
          text3: 'Ouvrir : Commencer par des questions d’échauffement qui mettent le client en confiance pour parler.',
          text4: 'Ecouter : Laisser parler le client et entretenir avec des questions sur le "quoi" et le "comment".',
          text5:
            'Fermer : Conclure l’interview et demander des recommandations, ou le cas échéant, une interview de suivi.',
        },
        block3: {
          title: 'Debriefer l’interview',
          text1: 'Prendre des notes rapidement, parfois une vidéo ou un enregistrement audio peuvent aider.',
        },
        block4: {
          title:
            'Interpétrer les résultats : Etes-vous capables d’écouter et enregistrer les infos sur la base des éléments suivants?',
          text1: 'Job : Dans quel contexte le client est-il amené à rencontrer ce problème?',
          text2: 'Obstacle : What is preventing the customer from solving their problem?',
          text3: 'Objectif : S’ils résolvent leur problème, alors_____?',
          text4: 'Solution actuelle : Comment résolvent-ils ou contournent-ils leur problème ?',
          text5: 'Decision trigger : Y’a-t-il un moment précis, un moment pivot, où le client prend uné décision clé ?',
          text6: 'Interest trigger : Quelles questions ont particulièrement intéressé le client?',
          text7: 'Personnes : Y’a-t-il d’autres personnes impliquées dans le problème ou la solution ?',
          text8:
            'Emotions : Y’a-t-il quelque chose en particulier qui fait que le client exprime des émotions différentes ?',
          text9: 'Measurement : How is the customer measuring the cost of their problem?',
        },
      },
      advices: {
        text1: 'Essayez de changer l’ordre des questions au fur et à mesure des interviews',
        text2:
          'L’idée n’est pas de donner votre avis même si la vision de la personne interviewée diffère radicalement',
        text3: 'Intéressez-vous au passé. Observez le présent. Oubliez le futur',
        text4: 'Ne parlez pas de vos idées',
      },
    },

    ideate: {
      title: 'Idéer',
      text:
        'Sur la bases de vos découvertes client, commencez à générer des idées sur comment résoudre les pain points et renforcer les gains.',
      howTo: {
        title: 'Comment générer des idées?',
        block1: {
          title: 'Pour chaque pain/gain',
          text1:
            'Travailler individuellement : chaque personne note ses idées sur des post-it. ' +
            'Une idée par post-it. Objectif quantité et pas qualité. A ce stade, plus il y a d’idées, mieux c’est. ' +
            '<i>5 minutes</i>',
          text2: 'Partagez tous les post-its sur un mur. PAS de jugement pour l’instant. <i>10 minutes</i>',
          text3:
            'Sur la base de la discussion précédente (étape 2), chaque personne génère de nouvelles idées sur des post-it, ou des idées qui viennent complémenter les autres. ' +
            '<i>5 minutes</i>',
          text4:
            'Partagez vos nouvelles idées sur le mur et commencez à les regrouper en groupes d’idées. <i>5 minutes</i>',
          text5: 'Faites une ébauche rapide sur post-it de vos grandes idées. <i>5 minutes</i>',
          text6:
            'Classez vos idées sur une matrice 2*2 en fonction de leur valeur pour le client et pour le business. <i>10 minutes</i>',
          text7:
            'Chaque membre de l’équipe distribue 3 votes sur les idées qui se trouvent en haut à droite de la matrice. <i>5 minutes</i>',
        },
      },
      advices: {
        text1: 'N’ayez pas peur des idées folles. Quelle que soit l’idée qui vous vienne, notez-la sur un post-it.',
        text2: 'Vous n’avez pas besoin de savoir dessiner.',
      },
      toolGuidelines:
        'Listez les idées que vous avez sélectionnées dans votre board du Reacteev toolkit. <i>5 minutes</i>',
    },

    experiment: {
      title: 'Expérimenter',
      text:
        'Un Prototype est une version d’un nouveau produit qui permet de tester une hypothèse auprès d’un client, afin de valider avec lui la désirabilité du produit. ' +
        'Il peut prendre des formes différentes : prototype papier ou cliquable, landing page, support marketing etc.',
      howTo: {
        title: 'Comment remplir votre Experiment Card ?',
        block1: {
          title: 'Pour préparer votre experiment, remplissez l’Experiment Card',
          text1: 'Fixez-vous un objectif d’apprentissage. Que voulez-vous apprendre?',
          text2: 'Ecrivez vos hypothèses "Si x alors y"',
          text3:
            'Choisissez une métrique. Une métrique clé peut suffire. Elle doit être actionnable. Tendez vers un ratio.',
          text4: 'Condition d’échec. Si x arrive, alors cette hypothèse est clairement fausse.',
          text5: 'Condition d’arrêt anticipé. Si cela arrive, STOP.',
          text6: 'Planifiez. Listez toutes les actions à mettre en place pour valider ou invalider l’assomption.',
        },
      },
      advices: {
        text1:
          'Ne passez pas trop de temps à parfaire votre prototype. Pas la peine de passer trop de temps sur quelque chose qu’un client ne veut peut-être pas.',
        text2: 'Validez vos prototype and experiment card avec le service juridique.',
        text3:
          'N’ayez pas peur de dégrader l’image de marque de votre entreprise : vous n’allez tester que sur quelques personnes',
      },
      toolGuidelines:
        'Ajoutez le titre de votre experiment, cliquez sur "plus" et remplissez votre experiment card. ' +
        'Vous pouvez aussi ajouter des photos de votre experiment ou tout fichier relatif à cet experiment.',
    },

    analyze: {
      title: 'Analysez',
      text: 'Maintement que votre prototype est prêt, vous devez mettre en place votre experiment.',
      howTo: {
        title: 'Comment tester?',
        block1: {
          title: 'Pendant l’experiment',
          text1: 'Assurez-vous de récolter toutes les données qui vous permettront de monitorer vos métriques',
          text2: 'Apprenez de vos clients en observant leur comportement et en menant de courtes interviews',
          text3:
            'Si besoin de plus de détails, n’hésitez pas à interroger les autres personnes impliquées dans le processus',
        },
        block2: {
          title: 'Une fois que votre experiment est fait',
          text1: 'Remplissez les résultats et apprentissages',
          text2:
            'Demandez-vous : Etes-vous au desssus de votre critère de validation ? Pourquoi ? Qu’avez-vous appris des interviews client ?',
        },
      },
      advices: {
        text1: 'Testez sur au moins 5 personnes',
        text2: 'Au cours des interviews, utilisez la technique des "5 pourquoi"',
        text3:
          'Evitez l’effet WOW pour vos données, tenez-vous-en aux données brutes (n’essayez pas de faire briller les résultats)',
      },
      toolGuidelines:
        'Assurez-vous d’enregistrer tous vos apprentissages dans l’outil. ' +
        'Cela peut-être utile pour le futur. ' +
        'Ajoutez des photos ou tout autre matériel à disposition qui permettrait à vos collègues et aux décideurs de visualiser ce sur quoi vous travaillez.<br>' +
        'Enregistrez toutes vos trouvailles. Si vous avez été surpris, les autres le seront aussi.',
    },

    decide: {
      title: 'Décider',
      text: `C'est un moment crucial pour votre idée.
C’est le Management board qui va choisir les idées à déployer. Pour les aider à prendre leur décision, assurez-vous qu'ils aient toutes les cartes en main.`,
      howTo: {
        title: 'Comment',
        block1: {
          title: '',
          text1: 'Résultats de vos experiments et tous apprentissages associés.',
          text2:
            'Vos prochaines étapes : quel set-up imaginez-vous pour le projet pilote et quel est le résultat attendu.',
          text3: 'Si possible, calculez le ROI de votre pilote',
        },
      },
      advices: {
        text1: 'Ne pensez qu’aux prochaines étapes, ne faites pas de plan sur les 10 années à venir',
      },
    },
  },

  publicGallery: {
    organizationName: '{{organizationName}} & Co',
    welcome: 'Bienvenue sur la plateforme d’innovation collaborative de {{organizationName}}',
    openMarket: 'L’open market des projets et idées innovantes',
    openInnovation: {
      title: 'Projets d’Open innovation',
      text: 'Startups, Entreprises, Institutions, Etudiants, vous êtes invités à venir façonner le futur avec nous',
    },
    howToCollaborate: {
      title: 'Comment collaborer',
      text:
        'Pour idéer avec {{organizationName}} & Co, sélectionnez le type de collaboration sur lequel vous souhaitez postuler, téléchargez le fichier joint et innovez !',
      sendProposal: 'Si vous souhaitez envoyer votre propre proposition de projet',
    },
    footer: {
      text: 'Cette page est éditée par l’agence d’innovation Reacteev pour {{organizationName}} & Co.',
      legal: 'Informations légales',
      contact: 'Contact',
    },
  },
};
