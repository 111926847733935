import i18n from '../../../i18n';
import EnumLabel from '../../global/elems/EnumLabel';
import { getColorForExperimentStateIcon, getExperimentStateIcon } from '../../global/utils/IconUtils';
import ExperimentStateEnum from '../../stores/projects/models/experiments/ExperimentStateEnum';
import './css/experimentStateLabel.css';

const ExperimentStateLabel = EnumLabel(
  ExperimentStateEnum,
  value => i18n.t('experiment.' + value),
  getExperimentStateIcon,
  'experimentStateLabel',
  getColorForExperimentStateIcon
);

export default ExperimentStateLabel;
