import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from '../../i18n';

class SearchFile extends React.Component {
  static propTypes = {
    file: PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }).isRequired,
    nestingLevel: PropTypes.number,
  };

  static defaultProps = {
    nestingLevel: 0,
  };

  render() {
    const file = this.props.file;
    return (
      <div className="searchPanel-line" style={{ paddingLeft: 20 + this.props.nestingLevel * 30 }}>
        <i className="searchPanel-line-icon ic-paperclip" />
        <div className="searchPanel-line-text">{file.name}</div>
        <a title={i18n.t('see.file')} className="btn btn_primary btn_medium searchPanel-line-btn" href={file.link}>
          <i className="ic-arrow-right" />
        </a>
      </div>
    );
  }
}

export default observer(SearchFile);
