import React from 'react';
import PropTypes from 'prop-types';
import KEYBOARD from '../../../constants/KeyBoard';
import Modal from '../../global/elems/Modal';
import CheckBox from '../../global/input/CheckBox';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import i18n from '../../../i18n';
import UserStore from '../../stores/user/UserStore';
import userStateEnum from '../../stores/user/models/UserStateEnum';

class CreateUserModal extends React.Component {
  static propTypes = {
    onError: PropTypes.func,
  };

  state = {
    firstName: '',
    lastName: '',
    email: '',
    disabled: false,
  };

  open = () => this.modal.open();

  close = () => this.modal.close();

  focusInput = () => this.input.focus();

  handleFirstNameChange = event => this.setState({ firstName: event.target.value });
  handleLastNameChange = event => this.setState({ lastName: event.target.value });
  handleEmailChange = event => this.setState({ email: event.target.value });

  handleKeyDown = event => {
    if (event.key === KEYBOARD.ENTER.name) {
      this.handleCreate();
    }
    if (event.keyCode === KEYBOARD.ESCAPE.code) {
      this.close();
    }
  };

  handleCreate = () => {
    if (this.state.firstName.trim() !== '' && this.state.lastName.trim() !== '' && this.state.email.trim() !== '') {
      UserStore.addUser({
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        state: this.state.disabled ? userStateEnum.enum.Disabled : userStateEnum.enum.NotValidated,
      })
        .then(() => {
          this.close();
        })
        .catch(error => {
          const errorMessage = getErrorMessage(error);
          this.props.onError && this.props.onError(i18n.t('admin.users.add.requestError', { errorMessage }));
        });
    }
  };

  handleCheckBoxChange = checked => {
    this.setState({ disabled: !checked });
  };

  render() {
    return (
      <Modal ref={c => (this.modal = c)} onOpened={this.focusInput}>
        <h2 className="modal-title">{i18n.t('admin.users.new.title')}</h2>
        <div className="form-group">
          <label>{i18n.t('admin.users.new.firstName.label')}</label>
          <input
            ref={c => (this.input = c)}
            className="input"
            value={this.state.firstName}
            onChange={this.handleFirstNameChange}
            placeholder={i18n.t('admin.users.new.firstName.placeholder')}
            onKeyDown={this.handleKeyDown}
          />
        </div>
        <div className="form-group">
          <label>{i18n.t('admin.users.new.lastName.label')}</label>
          <input
            className="input"
            value={this.state.lastName}
            onChange={this.handleLastNameChange}
            placeholder={i18n.t('admin.users.new.lastName.placeholder')}
            onKeyDown={this.handleKeyDown}
          />
        </div>
        <div className="form-group">
          <label>{i18n.t('admin.users.new.email.label')}</label>
          <input
            type="email"
            className="input"
            value={this.state.email}
            onChange={this.handleEmailChange}
            placeholder={i18n.t('admin.users.new.email.placeholder')}
            onKeyDown={this.handleKeyDown}
          />
        </div>
        <div className="form-group">
          <CheckBox
            text={i18n.t('admin.users.new.enableUser.text')}
            title={i18n.t('admin.users.new.enableUser.title')}
            onChange={this.handleCheckBoxChange}
            value={!this.state.disabled}
          />
        </div>
        <div className="d-flex flexDirection_row">
          <div className="btn btn_ok btn_medium m-r-5" onClick={this.handleCreate}>
            {i18n.t('admin.users.new.create')}
          </div>
          <div className="btn btn_transparent btn_medium" onClick={this.close}>
            <i className="ic-x btn-icon" />
          </div>
        </div>
      </Modal>
    );
  }
}

export default CreateUserModal;
