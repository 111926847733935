import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import withFeedback from '../../feedback/withFeedback';
import UserStore from '../../../stores/user/UserStore';
import CanvasBlock from './CanvasBlock';
import './css/canvas.css';

class Canvas extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
    lines: PropTypes.arrayOf(
      PropTypes.shape({
        blocks: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
    boardId: PropTypes.string,
    data: PropTypes.objectOf(PropTypes.string),
  };

  canEdit =
    !!UserStore.currentUser &&
    (UserStore.currentUser.hasOneOrManyAdminRoles || this.props.project.isMember(UserStore.currentUser.id));

  renderBlock = block => (
    <CanvasBlock
      key={`${block.name}(${this.props.data[block.name]})`}
      boardId={this.props.boardId}
      data={this.props.data}
      canEdit={this.canEdit}
      showErrorMessage={this.props.showErrorMessage}
      {...block}
    />
  );

  renderLine = (line, index) => (
    <div className="canvas-line" key={index}>
      {line.blocks.map(this.renderBlock)}
    </div>
  );

  render() {
    return <div className="canvas">{this.props.lines.map(this.renderLine)}</div>;
  }
}

export default withFeedback(observer(Canvas));
