import { destroy, flow, getParent, types } from 'mobx-state-tree';
import userStore from '../../../user/UserStore';
import LearningModel from '../learnings/LearningModel';
import FilesModel from '../files/FilesModel';
import TaskStatusEnum from './TaskStatusEnum';

export default function composeTask(name, service, addLearningMethod, deleteLearningMethod, model) {
  const TaskModel = types
    .model('Task', {
      id: types.maybe(types.identifier),
      status: types.optional(TaskStatusEnum.type, TaskStatusEnum.enum.TODO),
      assignees: types.array(types.string),
      learnings: types.array(LearningModel),
      files: types.optional(FilesModel, () => FilesModel.create()),
      lastUpdate: types.number,
    })
    .views(self => ({
      get project() {
        return getParent(self, 3);
      },

      get projectId() {
        return self.project.id;
      },

      getLearningById(learningId) {
        return self.learnings.find(learning => learning.id === learningId);
      },

      get nbLearnings() {
        return self.learnings.length;
      },

      searchLearnings(text) {
        return self.learnings.filter(learning => learning.contains(text));
      },

      get assigneesList() {
        const assignees = self.assignees.map(assigneeId => userStore.colleaguesMap[assigneeId]);
        return assignees.filter(assignee => assignee !== undefined);
      },

      isAssignee(assigneeId) {
        return self.assignees.includes(assigneeId);
      },
    }))
    .actions(self => {
      return {
        _setStatus(status) {
          self.status = status;
          return true;
        },
        _addAssignee(assigneeId) {
          if (self.isAssignee(assigneeId)) {
            return false;
          }
          self.assignees.push(assigneeId);
          return true;
        },
        _removeAssignee(assigneeId) {
          const memberIndex = self.assignees.indexOf(assigneeId);
          if (memberIndex > -1) {
            self.assignees.splice(memberIndex, 1);
            return true;
          }
          return false;
        },

        moveTask: flow(function* moveTask(destinationStatus) {
          // Avoid the lag when we move a card between column with optimist method
          const sourceStatus = self.status;
          try {
            self._setStatus(destinationStatus);
            const { data } = yield service.setStatus(self, destinationStatus);
            return data;
          } catch (error) {
            self._setStatus(sourceStatus);
            throw new Error(error);
          }
        }),

        addAssignee: flow(function* addAssignee(assigneeId) {
          if (!self.isAssignee(assigneeId)) {
            yield service.addAssignee(self, assigneeId);
            self._addAssignee(assigneeId);
          }
        }),

        removeAssignee: flow(function* removeAssignee(assigneeId) {
          if (self.isAssignee(assigneeId)) {
            yield service.removeAssignee(self, assigneeId);
            self._removeAssignee(assigneeId);
          }
        }),

        addLearning: flow(function* addLearning(learning) {
          const organization = userStore.currentUser.currentOrganization;
          const { data } = yield addLearningMethod(self, { organization, ...learning });
          if (data && !self.getLearningById(data.id)) {
            self.learnings.push(data);
          }
        }),

        deleteLearning: flow(function* deleteLearning(learning) {
          const learningId = learning.id;
          yield deleteLearningMethod(self, learning);
          const learningToRemove = self.getLearningById(learningId);
          if (learningToRemove) {
            destroy(learningToRemove);
          }
        }),

        handleAssigneeAddedEvent(event) {
          return self.checkAlreadyProcessedEvent(event, event => {
            return self._addAssignee(event.event.assigneeId);
          });
        },
        handleAssigneeRemovedEvent(event) {
          return self.checkAlreadyProcessedEvent(event, event => {
            return self._removeAssignee(event.event.assigneeId);
          });
        },

        handleLearningCreatedEvent(event) {
          if (self.getLearningById(event.learningId)) {
            return false;
          } else {
            self.learnings.push(event.event.learning);
            return true;
          }
        },

        handleLearningDeletedEvent(event) {
          const learningToRemove = self.getLearningById(event.learningId);
          if (learningToRemove) {
            try {
              destroy(learningToRemove);
              return true;
            } catch (err) {
              return false;
            }
          }
          return false;
        },

        checkAlreadyProcessedEvent(event, eventHandler) {
          if (event.event.sequenceNr > self.lastUpdate) {
            const result = eventHandler(event);
            if (result) {
              self.lastUpdate = event.event.sequenceNr;
            }
            return result;
          } else {
            return false;
          }
        },

        handleDefaultEvent(event) {
          if (event.learningId) {
            const learning = self.getLearningById(event.learningId);
            if (learning) {
              return learning.handleEvent(event);
            } else {
              console.warn(
                `TaskModel received event ${event.eventType} for learning ${
                  event.learningId
                } but this learning is unknown`
              );
              return false;
            }
          } else {
            console.warn('TaskModel does not know how to handle event ', event);
            return false;
          }
        },
      };
    });

  return types
    .compose(
      TaskModel,
      model
    )
    .named(name);
}
