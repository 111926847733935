import { types } from 'mobx-state-tree';
import OrganizationsStore from '../../organizations/OrganizationsStore';
import projectStore from '../../projects/ProjectsStore';
import i18n from '../../../../i18n';
import ideaStateEnum from '../../projects/models/ideas/IdeaStateEnum';

const EventModel = types
  .model('Event', {
    organizationId: types.maybeNull(types.string),
    projectName: types.maybeNull(types.string),
    ideaTitle: types.maybeNull(types.string),
    experimentTitle: types.maybeNull(types.string),
    learningText: types.maybeNull(types.string),
    projectId: types.maybe(types.string),
    ideaId: types.maybe(types.string),
    experimentId: types.maybe(types.string),
    learningId: types.maybe(types.string),
    eventType: types.string,
    event: types.frozen(),
  })
  .views(self => ({
    get project() {
      return projectStore.getProjectById(self.projectId);
    },
    get experiment() {
      if (self.project && self.project.getExperimentById) {
        return self.project.getExperimentById(self.experimentId);
      }
      return undefined;
    },

    get eventProjectName() {
      return self.event.project ? self.event.project.name : '';
    },

    get titleInExperiment() {
      return self.experiment ? self.experiment.title : undefined;
    },

    get labelProperties() {
      return {
        projectName: self.projectName || (self.project ? self.project.name : self.eventProjectName),
        ideaTitle: self.ideaTitle || (self.event.idea ? self.event.idea.title : undefined),
        experimentTitle:
          self.experimentTitle || (self.event.experiment ? self.event.experiment.title : self.titleInExperiment),
        learningText: self.learningText || (self.event.learning ? self.event.learning.text : undefined),
      };
    },

    get label() {
      let fileNames;
      let groupNames;
      switch (self.eventType) {
        case 'ProjectCreated':
          return i18n.t('events.' + self.eventType, self.labelProperties);
        case 'ProjectDeleted':
          return i18n.t('events.' + self.eventType, self.labelProperties);
        case 'ProjectFilesAdded':
        case 'ExperimentResultFilesAdded':
        case 'ExperimentSolutionFilesAdded':
          fileNames = self.event.files.files.map(file => file.name);
          return i18n.t('events.' + self.eventType, {
            ...self.labelProperties,
            fileNames,
          });
        case 'LearningCreated':
          return i18n.t('events.' + self.eventType, self.labelProperties);
        case 'LearningDeleted':
          return i18n.t('events.' + self.eventType, self.labelProperties);
        case 'IdeaCreated':
          return i18n.t('events.' + self.eventType, self.labelProperties);
        case 'IdeaDeleted':
          return i18n.t('events.' + self.eventType, self.labelProperties);
        case 'IdeaStatusUpdated':
          if (self.event.status === ideaStateEnum.enum.SOLVED) {
            return i18n.t('events.' + self.eventType, self.labelProperties);
          }
          return '';
        case 'ExperimentCreated':
          return i18n.t('events.' + self.eventType, self.labelProperties);
        case 'ExperimentDeleted':
          return i18n.t('events.' + self.eventType, self.labelProperties);
        case 'ExperimentResultsUpdated':
          return i18n.t('events.' + self.eventType, {
            ...self.labelProperties,
            resultsText: self.event.results.text,
          });
        case 'ExperimentSolutionsUpdated':
          return i18n.t('events.' + self.eventType, {
            ...self.labelProperties,
            solutionsText: self.event.solutions.text,
          });
        case 'ExperimentValidationRequested':
          if (self.event.groups) {
            groupNames = self.event.groups
              .map(groupId => OrganizationsStore.getGroupById(self.organizationId, groupId))
              .filter(group => !!group)
              .map(group => group.name);
            return i18n.t('events.' + self.eventType, {
              ...self.labelProperties,
              groupNames: groupNames,
            });
          }
          return '';
        case 'ExperimentValidationApproved':
        case 'ExperimentValidationRefused':
          return i18n.t('events.' + self.eventType, self.labelProperties);
        case 'ProjectFilesRemoved':
        case 'ExperimentResultFileRemoved':
        case 'ExperimentSolutionFileRemoved':
          return i18n.t('events.' + self.eventType, self.labelProperties);
        default:
          return '';
      }
    },
  }));

export default EventModel;
