import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import DropDown from '../input/DropDown';

export default function EnumLabel(Enum, getLabelForValue, getIconForValue, className = '', getColorForValue = null) {
  return class EnumLabel extends React.Component {
    static propTypes = {
      editable: PropTypes.bool,
      handleSelectChange: requiredIf(PropTypes.func, props => props.editable),
      value: PropTypes.oneOf(Enum.keys),
    };

    render() {
      return (
        <DropDown
          className={className}
          editable={this.props.editable}
          handleSelectChange={this.props.handleSelectChange}
          value={{
            id: this.props.value,
            text: getLabelForValue(this.props.value),
            iconClass: getIconForValue(this.props.value),
            fontColor: getColorForValue ? getColorForValue(this.props.value) : null,
            backgroundColor: getColorForValue ? getColorForValue(this.props.value) : null,
          }}
          values={Enum.keys.map(value => {
            return {
              id: value,
              text: getLabelForValue(value),
              iconClass: getIconForValue(value),
              fontColor: getColorForValue ? getColorForValue(value) : null,
              backgroundColor: getColorForValue ? getColorForValue(value) : null,
            };
          })}
        />
      );
    }
  };
}
