import React from 'react';
import { observer } from 'mobx-react';

class SearchError extends React.Component {
  render() {
    return (
      <div className="searchPanel-line">
        <div className="searchPanel-line-text textAlign-center">
          <i>
            Oups, something is happening on our server!
            <br />
            Please try it again in a few minutes.
          </i>
        </div>
      </div>
    );
  }
}

export default observer(SearchError);
