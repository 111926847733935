import React from 'react';
import PropTypes from 'prop-types';
import './css/checkBox.css';

class CheckBox extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.bool,
    text: PropTypes.string,
  };

  handleChange = event => {
    let checked = event.target.checked;
    if (this.props.onChange) {
      this.props.onChange(checked);
    }
  };

  render() {
    return (
      <label className="switch" title={this.props.title}>
        <input type="checkbox" checked={this.props.value} onChange={this.handleChange} />
        <span className="slider round" />
        {this.props.text ? <span className="checkboxText">{this.props.text}</span> : null}
      </label>
    );
  }
}

export default CheckBox;
