import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import i18n from '../../../i18n';
import { getActionWorkflowIcon } from '../../global/utils/IconUtils';
import TaskStatusEnum from '../../stores/projects/models/tasks/TaskStatusEnum';

class EmpathizeCard extends React.Component {
  static propTypes = {
    action: PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.oneOf(TaskStatusEnum.keys),
      title: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
    projectId: PropTypes.string.isRequired,
  };

  goToAction = () => {
    this.props.history.push(
      '/projects/' +
        this.props.projectId +
        '/actions/' +
        this.props.action.id +
        '?back=/projects/' +
        this.props.projectId
    );
  };

  render() {
    return (
      <div>
        <div className="board-ideaCard">
          <div className="board-ideaCard-title" onClick={this.goToAction}>
            <i className="board-ideaCard-experiment-state ic-assignment" title="action" />
            <i
              className={'board-ideaCard-experiment-state ' + getActionWorkflowIcon(this.props.action.status)}
              title={i18n.t('task.status.' + this.props.action.status)}
            />
            {this.props.action.title}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EmpathizeCard);
