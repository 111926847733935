import { createEnhancedEnum } from '../../../../global/utils/EnumBuilder';

const EXPERIMENT_STATES = {
  REFUSED: -2,
  PIVOTED: -1,
  BUILDING: 0,
  TESTING: 1,
  PPP: 2,
  ACCEPTED: 3,
};

const ExperimentStateEnum = createEnhancedEnum('ExperimentState', EXPERIMENT_STATES);

export default ExperimentStateEnum;
