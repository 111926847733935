import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { RIEInput } from '@attently/riek';
import i18n from '../../../i18n';
import DeleteButton from '../../global/elems/DeleteButton';
import Description from '../../global/elems/Description';
import Modal from '../../global/elems/Modal';
import withFeedback from '../../global/feedback/withFeedback';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import projectsStore from '../../stores/projects/ProjectsStore';
import UserStore from '../../stores/user/UserStore';
import './css/ideaModal.css';

class IdeaModal extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        projectId: PropTypes.string.isRequired,
        ideaId: PropTypes.string.isRequired,
      }),
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    showErrorMessage: PropTypes.func.isRequired,
  };

  project = null;
  idea = null;

  UNSAFE_componentWillMount() {
    this.project =
      this.props.match.params && this.props.match.params.projectId
        ? projectsStore.getProjectById(this.props.match.params.projectId) || null
        : null;
    this.idea =
      this.project && this.props.match.params.ideaId
        ? this.project.getIdeaById(this.props.match.params.ideaId) || null
        : null;
    this.canEditOrRemove =
      UserStore.currentUser.hasOneOrManyAdminRoles || this.project.isMember(UserStore.currentUser.id);
  }

  handleTitleChange = value =>
    this.idea.setTitle(value.title).catch(error => {
      this.props.showErrorMessage(getErrorMessage(error), 5000);
    });

  handleBack = () => this.props.history.push('/projects/' + this.project.id);

  handleDelete = () => {
    this.project.ideas.deleteIdea(this.idea).catch(error => {
      this.props.showErrorMessage(getErrorMessage(error), 5000);
    });
    this.handleBack();
  };

  render() {
    if (this.idea === null) {
      this.handleBack();
      return null;
    }

    return (
      <Modal
        className="ideaModal"
        drawer={true}
        withRouter={true}
        history={this.props.history}
        backLabel={i18n.t('board.backToBoard')}
        onBack={this.handleBack}
      >
        <div className="modal-title d-flex flexDirection_row alignItems_center">
          <i className="ic-lightbulb_outline m-r-15 text_big" />
          <h2 className="m-0 d-flex flexDirection_row alignItems_center">
            <RIEInput
              value={this.idea.title}
              propName="title"
              change={this.handleTitleChange}
              validate={value => value.trim() !== ''}
              classEditing="input input_medium"
              isDisabled={!this.canEditOrRemove}
            />
            <DeleteButton
              onClick={this.handleDelete}
              title={i18n.t('idea.delete')}
              square={true}
              className="ideaModal-btnTrash"
              canDelete={this.canEditOrRemove}
            />
          </h2>
        </div>
        <div className="modal-content">
          <Description
            label={i18n.t('idea.description.label')}
            placeholder={i18n.t('idea.description.placeholder')}
            defaultValue={this.idea.text}
            onChange={this.idea.setText}
            canEdit={this.canEditOrRemove}
            showErrorMessage={this.props.showErrorMessage}
          />
        </div>
      </Modal>
    );
  }
}

export default withFeedback(observer(IdeaModal));
