import { createEnhancedEnum } from '../../../../global/utils/EnumBuilder';

const LEARNING_STATUS = {
  VALIDATED: 0,
  REJECTED: 1,
  ASSUMPTION: 2,
};

const LearningStatusEnum = createEnhancedEnum('LearningStatus', LEARNING_STATUS);

export default LearningStatusEnum;
