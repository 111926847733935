import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Members from '../../global/members/Members';
import AddButton from '../../global/elems/AddButton';
import i18n from '../../../i18n';
import UserStore from '../../stores/user/UserStore';

class BoardMembers extends React.Component {
  static propTypes = {
    project: PropTypes.shape({
      members: PropTypes.object,
      membersList: PropTypes.arrayOf(PropTypes.object),
      addMember: PropTypes.func,
      removeMember: PropTypes.func,
    }),
  };

  state = {
    adding: false,
  };

  handleStartAdding = () => this.setState({ adding: true });

  handleCancelAdding = () => this.setState({ adding: false });

  addMember = memberId => {
    return this.props.project.addMember(memberId);
  };

  removeMember = memberId => {
    return this.props.project.removeMember(memberId);
  };

  renderAddButton = isEmptyList => {
    return UserStore.currentUser && UserStore.currentUser.hasOneOrManyAdminRoles ? (
      <AddButton
        isEmptyList={isEmptyList}
        buttonText={i18n.t('members.add')}
        onClick={this.handleStartAdding}
        value={i18n.t('members.add')}
      />
    ) : null;
  };

  render() {
    return (
      <div className="board-members">
        <div className="board-members-blocks">
          <div className="members-block">
            <h3 className="members-title d-flex flexDirection_row alignItems_center">
              <i className="ic-users m-r-10" />
              {i18n.t('members.title')}
              {this.renderAddButton()}
            </h3>
            <Members
              members={this.props.project.membersList}
              membersList={UserStore.enabledColleagues}
              addMember={this.addMember}
              removeMember={this.removeMember}
              adding={this.state.adding}
              startAdding={this.handleStartAdding}
              cancelAdding={this.handleCancelAdding}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default observer(BoardMembers);
