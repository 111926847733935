import { destroy, flow, types } from 'mobx-state-tree';
import LearningModel from './LearningModel';
import learningTypeEnum from './LearningTypeEnum';
import LearningsService from '../../LearningsService';
import userStore from '../../../user/UserStore';

const LearningsModel = types
  .model('Learnings', {
    pains: types.array(LearningModel),
    gains: types.array(LearningModel),
    others: types.array(LearningModel),
  })
  .views(self => ({
    getLearningById(learningId) {
      return (
        self.others.find(learning => learning.id === learningId) ||
        self.pains.find(learning => learning.id === learningId) ||
        self.gains.find(learning => learning.id === learningId)
      );
    },

    get nbLearnings() {
      return self.pains.length + self.gains.length + self.others.length;
    },

    searchLearnings(text) {
      return [self.pains, self.gains, self.others].reduce(
        (learningsFound, learnings) => learningsFound.concat(learnings.filter(learning => learning.contains(text))),
        []
      );
    },
  }))
  .actions(self => {
    const LIST_FOR_TYPE = {
      [learningTypeEnum.enum.PAIN]: 'pains',
      [learningTypeEnum.enum.GAIN]: 'gains',
      [learningTypeEnum.enum.OTHER]: 'others',
    };
    return {
      addLearning: flow(function* addLearning(projectId, learning) {
        const organization = userStore.currentUser.currentOrganization;
        const { data } = yield LearningsService.addProjectLearning(projectId, { organization, ...learning });
        return self._addLearning(data);
      }),

      _addLearning(learning) {
        if (learning && learning.type) {
          const list = LIST_FOR_TYPE[learning.type];
          if (list && !self.getLearningById(learning.id)) {
            self[list].push(learning);
            return true;
          }
        }
        return false;
      },

      deleteLearning: flow(function* deleteLearning(learning) {
        yield LearningsService.deleteProjectLearning(learning);
        return self._removeLearning(learning.id);
      }),

      _removeLearning(learningId) {
        const learningToRemove = self.getLearningById(learningId);
        if (learningToRemove) {
          try {
            destroy(learningToRemove);
            return true;
          } catch (err) {
            return false;
          }
        }
        return false;
      },

      handleEvent(event) {
        if (event) {
          switch (event.eventType) {
            case 'LearningCreated':
              if (event.event) {
                return self._addLearning(event.event.learning);
              }
              console.warn('LearningsModel did not get learning in the event ', event.event);
              return false;
            case 'LearningDeleted':
              if (event.learningId) {
                return self._removeLearning(event.learningId);
              }
              console.warn('LearningsModel did not get learningId in the event ', event);
              return false;
            default:
              return self.handleDefaultEvent(event);
          }
        }
        console.warn('LearningsModel does not know how to handle event ', event);
        return false;
      },

      handleDefaultEvent(event) {
        const learning = self.getLearningById(event.learningId);
        if (learning) {
          return learning.handleEvent(event);
        }
        console.warn(`Received event ${event.eventType} for learning ${event.learningId} but this learning is unknown`);
        return false;
      },
    };
  });

export default LearningsModel;
