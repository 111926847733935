import { createEnhancedEnum } from '../../../global/utils/EnumBuilder';

const USER_ROLES = {
  ADMIN: 'admin',
  ORG_ADMIN: 'orgAdmin',
};

const UserRoleEnum = createEnhancedEnum('UserRole', USER_ROLES);

export default UserRoleEnum;
