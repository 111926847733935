import React from 'react';
import PropTypes from 'prop-types';
import './css/feedback.css';

export default class Feedback extends React.PureComponent {
  static propTypes = {
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    feedbackMessage: PropTypes.string,
    feedbackClass: PropTypes.string,
  };

  render() {
    const feedbackMessage = this.props.feedbackMessage || this.props.successMessage || this.props.errorMessage;
    const feedbackClass = this.props.feedbackClass || (this.props.successMessage ? 'success' : 'error');
    return feedbackMessage ? (
      <div className={`feedback ${feedbackClass}`}>
        <div className="feedback-label m-t-5 m-b-5">{feedbackMessage}</div>
      </div>
    ) : null;
  }
}
