import React from 'react';
import Loader from './Loader';
import './FullScreenLoader.css';

const FullScreenLoader = () => (
  <div className="fullscreen-loader">
    <Loader size={96} />
  </div>
);

export default FullScreenLoader;
