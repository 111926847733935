import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import learningStatusEnum from '../../stores/projects/models/learnings/LearningStatusEnum';
import { getColorForLearningStatusIcon, getLearningStatusIcon } from '../../global/utils/IconUtils';
import './css/learningStatusSelector.css';

class LearningStatusSelector extends React.Component {
  static propTypes = {
    learning: PropTypes.shape({
      status: PropTypes.oneOf(learningStatusEnum.keys),
    }).isRequired,
    onChange: PropTypes.func,
  };

  renderLearningStatus = status => {
    const selected = this.props.learning.status === status;
    const color = getColorForLearningStatusIcon(status);
    const style = selected
      ? {
          color: '#FFF',
          borderColor: color,
          backgroundColor: color,
        }
      : { color };
    return (
      <label
        key={status + '-input'}
        className={'tab tab_small fontSize_normalSmall status-selector ' + (selected ? 'tab_selected' : '')}
        style={style}
        onClick={() => !selected && this.props.onChange(status)}
        title={status.toLowerCase()}
      >
        <input type="radio" name="learning-status-selector" value={status.toLowerCase()} />
        <i className={getLearningStatusIcon(status)} />
      </label>
    );
  };

  render() {
    return <div className="tabs">{learningStatusEnum.keys.map(this.renderLearningStatus)}</div>;
  }
}

export default observer(LearningStatusSelector);
