/* global localStorage */
import { flow, types } from 'mobx-state-tree';
import userService from './UserService';
import projectsStore from '../projects/ProjectsStore';
import organizationsStore from '../organizations/OrganizationsStore';
import UserModel from './models/UserModel';
import UserStateEnum from './models/UserStateEnum';

const UserStore = types
  .model('UserStore', {
    isLogged: types.maybe(types.boolean),
    currentUser: types.maybe(UserModel),
    colleagues: types.array(UserModel),
  })
  .views(self => ({
    get colleaguesMap() {
      return self.colleagues.reduce((map, user) => {
        map[user.id] = user;
        return map;
      }, {});
    },
    get enabledColleagues() {
      return self.colleagues.filter(user => user.state !== UserStateEnum.enum.Disabled);
    },
  }))
  .actions(self => ({
    getUser: flow(function* getUser() {
      if (self.isLogged !== true) {
        try {
          const { data } = yield userService.getUser();
          self.updateUserData(data);
          return true;
        } catch (err) {
          self.eraseUserData();
          return false;
        }
      } else {
        yield true;
      }
    }),

    removeOneColleague(userId) {
      self.colleagues = self.colleagues.filter(user => user.id !== userId);
    },

    removeFromOrganization: flow(function* removeFromOrganization(userId) {
      const currentOrganization = self.currentUser.currentOrganization;
      const user = self.colleagues.find(user => user.id === userId);

      if (!user) {
        throw new Error('admin.users.errors.userNotFound');
      }
      if (!user.isMemberOfOrganization(currentOrganization)) {
        throw new Error('admin.users.errors.userNotMember');
      }

      yield userService.removeFromOrganization(currentOrganization, userId);
      user.removeFromOrganization(currentOrganization);
      self.removeOneColleague(userId);
    }),

    addOneColleague(user, organizationId) {
      user.organizations = [organizationId];
      user.fullName = user.firstName + ' ' + user.lastName;
      const newUser = UserModel.create(user);
      self.colleagues.push(newUser);
    },

    addUser: flow(function* addUser(user) {
      const currentOrganization = self.currentUser.currentOrganization;
      const { data } = yield userService.addUser(currentOrganization, user);
      self.addOneColleague(data, currentOrganization);
      return user;
    }),

    enableUser: flow(function* enableUser(userId) {
      const { data } = yield userService.enableUser(userId);
      self.colleaguesMap[userId].state = data;
    }),

    disableUser: flow(function* disableUser(userId) {
      const { data } = yield userService.disableUser(userId);
      self.colleaguesMap[userId].state = data;
    }),

    login: flow(function* login(loginObject) {
      try {
        const { data } = yield userService.signIn(loginObject);
        return self.refreshUserData(data);
      } catch (err) {
        console.warn('Error in login', err);
        self.eraseUserData();
        throw err;
      }
    }),

    forgotPassword: flow(function* forgotPassword(email) {
      const { data } = yield userService.forgotPassword(email);
      return data;
    }),

    logout: flow(function* logout() {
      try {
        yield userService.signOut();
        self.eraseUserData();
        projectsStore.close();
      } catch (err) {
        console.warn('Error in logout', err);
      }
    }),

    changePassword: flow(function* changePassword(changePasswordObject) {
      const { data } = yield userService.changePassword(changePasswordObject);
      self.updateUserData(data);
    }),

    resetPassword: flow(function* resetPassword(tokenId, resetPasswordObject) {
      const { data } = yield userService.resetPassword(tokenId, resetPasswordObject);
      return data;
    }),

    refreshUserData: flow(function* refreshUserData(data) {
      self.updateUserData(data);
      organizationsStore.refresh();
      yield projectsStore.refreshOrganizationProjects(self.currentUser.currentOrganization);
      self.refreshColleagues();
      return self.currentUser;
    }),

    refreshColleagues: flow(function* refreshColleagues() {
      try {
        const { data } = yield userService.getColleagues(self.currentUser.currentOrganization);
        // TODO: remove the following filter, need to be implemented in the backend
        self.colleagues = data
          .filter(user => user.organizations.includes(self.currentUser.currentOrganization))
          .map(user => UserModel.create(user));
      } catch (err) {
        console.warn('Error in refreshColleagues', err);
        self.colleagues = [];
      }
    }),

    updateUserData(jsonData) {
      self.currentUser = UserModel.create(jsonData);
      self.currentUser.initializeSelectedOrganization();
      // Update the colleagues before leaving the loader
      // to call them with data in pages (using refresh)
      self.refreshColleagues();
      self.isLogged = true;
    },

    eraseUserData() {
      self.isLogged = false;
      self.currentUser = undefined;
      self.colleagues = [];
      localStorage.removeItem('currentOrganization');
    },
  }));

const userStore = UserStore.create();

userStore.getUser().then(loggedIn => {
  if (loggedIn) {
    organizationsStore.refresh();
    projectsStore.refreshOrganizationProjects(userStore.currentUser.currentOrganization);
  }
});

export default userStore;
