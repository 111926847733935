/*global FormData */

import {
  executeFormDataRequest,
  executeRequest,
  executeRequestWithBody,
  jsRoutes,
} from '../../../components/lib/RequestUtil';

class FilesService {
  updateOrganizationLogo(organizationId, file) {
    return this.createFiles(jsRoutes.controllers.Organizations.updateLogo(organizationId).url, [file]);
  }

  createProjectFiles(organizationId, projectId, files) {
    return this.createFiles(jsRoutes.controllers.Projects.addFiles(organizationId, projectId).url, files);
  }

  createActionFiles(projectId, actionId, files) {
    return this.createFiles(jsRoutes.controllers.Actions.addFiles(projectId, actionId).url, files);
  }

  createExperimentFiles(projectId, ideaId, experimentId, files) {
    return this.createFiles(jsRoutes.controllers.Experiments.addFiles(projectId, ideaId, experimentId).url, files);
  }

  createExperimentResultFiles(projectId, ideaId, experimentId, files) {
    return this.createFiles(
      jsRoutes.controllers.Experiments.addResultFiles(projectId, ideaId, experimentId).url,
      files
    );
  }

  createExperimentSolutionFiles(projectId, ideaId, experimentId, files) {
    return this.createFiles(
      jsRoutes.controllers.Experiments.addSolutionFiles(projectId, ideaId, experimentId).url,
      files
    );
  }

  createFiles(url, files) {
    const fd = new FormData();
    let i = 0;
    files.forEach(file => {
      fd.append(`file${i}`, file, file.name);
      i++;
    });
    return executeFormDataRequest(url, fd, {});
  }

  deleteOrganizationLogo(organizationId) {
    const { url, method } = jsRoutes.controllers.Organizations.deleteLogo(organizationId);
    return executeRequest(url, { method });
  }

  deleteProjectFile(projectId, fileId) {
    const { url, method } = jsRoutes.controllers.Projects.removeFile(projectId, fileId);
    return executeRequest(url, { method });
  }

  deleteActionFile(projectId, actionId, fileId) {
    const { url, method } = jsRoutes.controllers.Actions.removeFile(projectId, actionId, fileId);
    return executeRequest(url, { method });
  }

  deleteExperimentFile(projectId, ideaId, experimentId, fileId) {
    const { url, method } = jsRoutes.controllers.Experiments.removeFile(projectId, ideaId, experimentId, fileId);
    return executeRequest(url, { method });
  }

  deleteExperimentResultFile(projectId, ideaId, experimentId, fileId) {
    const { url, method } = jsRoutes.controllers.Experiments.removeResultFile(projectId, ideaId, experimentId, fileId);
    return executeRequest(url, { method });
  }

  deleteExperimentSolutionFile(projectId, ideaId, experimentId, fileId) {
    const { url, method } = jsRoutes.controllers.Experiments.removeSolutionFile(
      projectId,
      ideaId,
      experimentId,
      fileId
    );
    return executeRequest(url, { method });
  }

  togglePublicFile(project, file, value) {
    const { url, method } = jsRoutes.controllers.Projects.markFilePublic(project.organization, project.id, file.id);
    return executeRequestWithBody(url, { value }, { method });
  }

  setCallFor(project, file, value) {
    const { url, method } = jsRoutes.controllers.Projects.setCallForPublicFile(
      project.organization,
      project.id,
      file.id
    );
    return executeRequestWithBody(url, { value }, { method });
  }
}

const filesService = new FilesService();
export default filesService;
