import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import learningTypeEnum from '../../stores/projects/models/learnings/LearningTypeEnum';
import LearningsList from '../learnings/LearningsList';

class ActionLearnings extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
    project: PropTypes.shape({}).isRequired,
    action: PropTypes.shape({
      id: PropTypes.string.isRequired,
      learnings: PropTypes.any,
      addLearning: PropTypes.func.isRequired,
      deleteLearning: PropTypes.func.isRequired,
    }).isRequired,
  };

  handleAdd = learning =>
    this.props.action.addLearning(learning).catch(error => {
      this.props.showErrorMessage(getErrorMessage(error), 5000);
    });

  handleDelete = learning =>
    this.props.action.deleteLearning(learning).catch(error => {
      this.props.showErrorMessage(getErrorMessage(error), 5000);
    });

  render() {
    return (
      <LearningsList
        name={i18n.t('learnings.title')}
        emptyText={i18n.t('learnings.emptyText')}
        iconName="ic-book-open"
        project={this.props.project}
        learnings={this.props.action.learnings}
        learningType={learningTypeEnum.enum.OTHER}
        onAdd={this.handleAdd}
        onDelete={this.handleDelete}
      />
    );
  }
}

export default ActionLearnings;
