import { destroy, flow, types } from 'mobx-state-tree';
import ActionsService from '../../ActionsService';
import ActionModel from './ActionModel';
import userStore from '../../../user/UserStore';

const ActionsModel = types
  .model('Actions', {
    actions: types.array(ActionModel),
  })
  .views(self => ({
    getActionById(actionId) {
      return self.actions.find(action => action.id === actionId);
    },

    get actionsList() {
      let actions = {};
      self.actions.forEach(function(action) {
        if (actions[action.status]) {
          actions[action.status].push(action);
        } else {
          actions[action.status] = [action];
        }
      });
      return actions;
    },

    searchActions(text) {
      return self.actions.filter(action => action.contains(text));
    },

    searchFiles(text) {
      return self.actions.reduce((files, action) => files.concat(action.searchFiles(text)), []);
    },

    getFileFromId(fileId) {
      let result;
      self.actions.forEach(action => {
        result = result || action.getFileFromId(fileId);
      });
      return result;
    },

    get allFiles() {
      return self.actions.reduce((files, action) => files.concat(action.files.files.slice()), []);
    },

    searchLearnings(text) {
      return self.actions.reduce((learnings, action) => learnings.concat(action.searchLearnings(text)), []);
    },

    get allLearnings() {
      return self.actions.reduce((learnings, action) => learnings.concat(action.learnings.slice()), []);
    },
  }))
  .actions(self => ({
    _addAction(action) {
      if (self.getActionById(action.id)) {
        return false;
      }
      self.actions.push(action);
      return true;
    },

    addAction: flow(function* addAction(projectId, action) {
      const organization = userStore.currentUser.currentOrganization;
      const { data } = yield ActionsService.addAction(projectId, {
        organization,
        ...action,
      });
      if (data) {
        self._addAction(data);
      }
    }),

    deleteAction: flow(function* deleteAction(action) {
      const actionId = action.id;
      yield ActionsService.deleteAction(action);
      const actionToRemove = self.getActionById(actionId);
      if (actionToRemove) {
        destroy(actionToRemove);
      }
    }),

    handleEvent(event) {
      switch (event.eventType) {
        case 'ActionCreated':
          return self._addAction(event.event.action);
        case 'ActionDeleted':
          var actionToRemove = self.getActionById(event.event.actionId);
          if (actionToRemove && event.event.sequenceNr > actionToRemove.lastUpdate) {
            try {
              destroy(actionToRemove);
              return true;
            } catch (err) {
              return false;
            }
          }
          return false;
        default:
          return self.handleDefaultEvent(event);
      }
    },

    handleDefaultEvent(event) {
      if (event.actionId) {
        const action = self.getActionById(event.actionId);
        if (action) {
          return action.handleEvent(event);
        } else {
          console.warn(
            `Received event ${event.eventType} for action ${event.event.actionId} but this action is unknown`
          );
          return false;
        }
      }
      console.warn('ActionsModel does not know how to handle event ', event);
      return false;
    },
  }));

export default ActionsModel;
