import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import KEYBOARD from '../../../constants/KeyBoard';
import LearningStatusSelector from './LearningStatusSelector';
import learningStatusEnum from '../../stores/projects/models/learnings/LearningStatusEnum';
import LearningTypeEnum from '../../stores/projects/models/learnings/LearningTypeEnum';
import SubmitAndCancelButton from '../../global/elems/SubmitAndCancelButton';

class LearningForm extends React.Component {
  static propTypes = {
    learning: PropTypes.shape({
      text: PropTypes.string,
      status: PropTypes.oneOf(learningStatusEnum.keys),
    }),
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    learningType: PropTypes.oneOf(LearningTypeEnum.keys),
  };

  state = {
    text: this.props.learning ? this.props.learning.text : '',
    status: this.props.learning ? this.props.learning.status : learningStatusEnum.enum.ASSUMPTION,
  };

  setFocus = ref => ref && ref.focus();

  handleTextChange = event => this.setState({ text: event.target.value });

  handleStatusChange = status => this.setState({ status });

  handleKeyDown = event => {
    if (event.key === KEYBOARD.ENTER.name) {
      this.handleSave();
    }
    if (event.keyCode === KEYBOARD.ESCAPE.code) {
      this.handleCancel();
    }
  };

  handleCancel = () => this.props.onCancel();

  handleSave = () => {
    if (this.state.text.trim() !== '') {
      this.props.onSave({
        type: this.props.learningType,
        text: this.state.text,
        status: this.state.status,
      });
    } else {
      this.handleCancel();
    }
  };

  render() {
    return (
      <div className="learnings-block-form p-20 size_fullAbsolute">
        <div className="form-group">
          <label>{i18n.t('learnings.status')}</label>
          <LearningStatusSelector learning={this.state} onChange={this.handleStatusChange} />
        </div>
        <div className="form-group">
          <label>{i18n.t('learnings.text.label')}</label>
          <input
            ref={this.setFocus}
            className="input"
            value={this.state.text}
            onChange={this.handleTextChange}
            placeholder={i18n.t('learnings.text.placeholder')}
            onKeyDown={this.handleKeyDown}
          />
        </div>
        <SubmitAndCancelButton
          actionLabel={i18n.t(this.props.learning ? 'learnings.save' : 'learnings.add')}
          handleCancel={this.handleCancel}
          handleSubmit={this.handleSave}
        />
      </div>
    );
  }
}

export default LearningForm;
