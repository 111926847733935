import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import withFeedback from '../../global/feedback/withFeedback';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import BoardColumn from './BoardColumn';
import EmpathizeEnum from '../../stores/projects/models/empathize/EmpathizeEnum';
import ideaStateEnum from '../../stores/projects/models/ideas/IdeaStateEnum';
import ExperimentStateEnum from '../../stores/projects/models/experiments/ExperimentStateEnum';
import './css/BoardColumns.css';

class BoardColumns extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
    project: PropTypes.shape({
      ideas: PropTypes.object,
      getIdeaById: PropTypes.func.isRequired,
      organizationModel: PropTypes.shape({
        scaleColumnHidden: PropTypes.bool,
      }),
    }),
  };

  static COLUMNS = [
    {
      id: EmpathizeEnum.enum.EMPATHIZE,
      name: i18n.t('empathize.' + EmpathizeEnum.enum.EMPATHIZE),
      iconName: 'ic-heart',
      isDropDisabled: true,
    },
    {
      id: ideaStateEnum.enum.IDEAS,
      name: i18n.t('idea.' + ideaStateEnum.enum.IDEAS),
      iconName: 'ic-lightbulb_outline',
      helpId: 'ideate',
    },
    {
      id: ideaStateEnum.enum.MVP,
      name: i18n.t('idea.' + ideaStateEnum.enum.MVP),
      iconName: 'ic-rocket',
      helpId: 'experiment',
    },
    {
      id: ideaStateEnum.enum.TEST,
      name: i18n.t('idea.' + ideaStateEnum.enum.TEST),
      iconName: 'ic-test',
      helpId: 'analyze',
    },
    {
      id: ideaStateEnum.enum.PPP,
      name: i18n.t('idea.' + ideaStateEnum.enum.PPP),
      iconName: 'ic-test-quiz',
      helpId: 'decide',
    },
    {
      id: ideaStateEnum.enum.SOLVED,
      name: i18n.t('idea.' + ideaStateEnum.enum.SOLVED),
      iconName: 'ic-check',
    },
  ];

  moveToMVP = (idea, sourceState) => {
    if (sourceState === ideaStateEnum.enum.TEST) {
      idea.experiments.forEach(
        experiment =>
          experiment.state === ExperimentStateEnum.enum.TESTING &&
          experiment.setState(ExperimentStateEnum.enum.BUILDING)
      );
    }
    return true;
  };

  moveToTEST = idea => {
    const testableExperiments = idea.getTestableExperiments();
    if (testableExperiments.length === 0) {
      this.props.showErrorMessage(i18n.t('board.errors.missingExperimentCard'), 5000);
      return false;
    }
    testableExperiments.forEach(experiment => experiment.setState(ExperimentStateEnum.enum.TESTING));
    return true;
  };

  moveToPPP = (idea, sourceState) => {
    if (sourceState === ideaStateEnum.enum.SOLVED) {
      idea.getAcceptedExperiments().forEach(experiment => experiment.setState(ExperimentStateEnum.enum.PPP));
    } else {
      const decidableExperiments = idea.getDecidableExperiments();
      if (decidableExperiments.length === 0) {
        this.props.showErrorMessage(i18n.t('board.errors.missingTestsResults'), 5000);
        return false;
      }
      decidableExperiments.forEach(experiment => experiment.setState(ExperimentStateEnum.enum.PPP));
    }
    return true;
  };

  moveToSOLVED = idea => {
    const decidedExperiments = idea.getDecisionReadyExperiments();
    if (decidedExperiments.length === 0) {
      this.props.showErrorMessage(i18n.t('board.errors.noDecidedExperiment'), 5000);
      return false;
    }
    decidedExperiments.forEach(experiment => experiment.setState(ExperimentStateEnum.enum.ACCEPTED));
    return true;
  };

  handleDragEnd = result => {
    const { source, destination } = result;
    if (!destination) {
      // dropped outside the list
      return;
    }

    if (source.droppableId === destination.droppableId) {
      // dropped in same column, do nothing
      return;
    }

    const idea = this.props.project.getIdeaById(result.draggableId);
    if (!idea) {
      return;
    }

    switch (destination.droppableId) {
      case ideaStateEnum.enum.MVP:
        if (!this.moveToMVP(idea, source.droppableId)) {
          return;
        }
        break;
      case ideaStateEnum.enum.TEST: {
        if (!this.moveToTEST(idea)) {
          return;
        }
        break;
      }
      case ideaStateEnum.enum.PPP:
        if (!this.moveToPPP(idea, source.droppableId)) {
          return;
        }
        break;
      case ideaStateEnum.enum.SOLVED: {
        if (!this.moveToSOLVED(idea)) {
          return;
        }
        break;
      }
      default:
    }

    this.props.project.ideas.moveIdea(idea.id, idea.status, destination.droppableId, destination.index).catch(error => {
      this.props.showErrorMessage(getErrorMessage(error), 5000);
    });
  };

  renderColumn = column => <BoardColumn project={this.props.project} column={column} key={column.id} />;

  render() {
    const scaleColumnVisible = !this.props.project.organizationModel.scaleColumnHidden;
    const columns = scaleColumnVisible
      ? BoardColumns.COLUMNS
      : BoardColumns.COLUMNS.filter(column => column.id !== ideaStateEnum.enum.SOLVED);
    return (
      <div className="boardColumns">
        <DragDropContext onDragEnd={this.handleDragEnd}>{columns.map(this.renderColumn)}</DragDropContext>
      </div>
    );
  }
}

export default withFeedback(BoardColumns);
