import React from 'react';
import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';
import { getErrorMessage } from '../../global/utils/ErrorHandling';

class Description extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange: requiredIf(PropTypes.func, props => props.canEdit),
    canEdit: PropTypes.bool,
    showErrorMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: '',
    placeholder: '',
    defaultValue: '',
    canEdit: false,
  };

  state = {
    descriptionValue: this.props.defaultValue,
  };

  handleChange = event => this.setState({ descriptionValue: event.target.value });

  handleOnBlur = event => {
    if (this.props.defaultValue !== event.target.value) {
      return this.props
        .onChange(event.target.value)
        .catch(error => this.props.showErrorMessage(getErrorMessage(error), 5000));
    } else {
      return null;
    }
  };

  render() {
    return (
      <div>
        <h3>
          <i className="ic-edit-3 m-r-10" />
          {this.props.label}
        </h3>
        <textarea
          className="m-b-20"
          rows={10}
          value={this.state.descriptionValue}
          onChange={this.handleChange}
          onBlur={this.handleOnBlur}
          placeholder={this.props.placeholder}
          readOnly={!this.props.canEdit}
        />
      </div>
    );
  }
}

export default Description;
