/* global document */

import React from 'react';
import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';
import KEYBOARD from '../../../constants/KeyBoard';

class Modal extends React.Component {
  static propTypes = {
    withRouter: PropTypes.bool,
    overlayClassName: PropTypes.string,
    drawer: PropTypes.bool,
    className: PropTypes.string,
    backLabel: PropTypes.string,
    onBack: requiredIf(PropTypes.func, props => props.backLabel),
    children: requiredIf(PropTypes.node, props => props.withRouter),
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
    history: requiredIf(
      PropTypes.shape({
        goBack: PropTypes.func.isRequired,
      }),
      props => props.withRouter
    ),
  };

  state = {
    displayed: this.props.withRouter || false,
  };

  handleEscapeKeyDown = event => {
    if (event.keyCode === KEYBOARD.ESCAPE.code) {
      this.close();
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleEscapeKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEscapeKeyDown);
  }

  open = () => this.setState({ displayed: true }, this.props.onOpened);

  close = () => {
    if (this.props.onBack) {
      this.props.onBack();
    } else {
      this.props.withRouter ? this.props.history.goBack() : this.setState({ displayed: false }, this.props.onClosed);
    }
  };

  handleModalClick = event => event.stopPropagation();

  renderBackButton() {
    return (
      <div className="modal-back" onClick={this.props.onBack}>
        <i className="ic-arrow-left m-r-10" />
        {this.props.backLabel}
      </div>
    );
  }

  render() {
    if (!this.state.displayed) {
      return null;
    }
    return (
      <div className={'overlay ' + (this.props.overlayClassName || '')} onClick={this.close}>
        <div
          className={(this.props.drawer ? 'drawer ' : 'modal ') + (this.props.className || '')}
          onClick={this.handleModalClick}
        >
          {this.props.backLabel ? this.renderBackButton() : null}
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Modal;
