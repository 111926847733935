import React from 'react';
import PropTypes from 'prop-types';
import modelOf from '../../../components/propTypes/modelOf';
import { observer } from 'mobx-react';
import Canvas from '../../global/elems/canvas/Canvas';
import withFeedback from '../../global/feedback/withFeedback';
import i18n from '../../../i18n';
import userStore from '../../stores/user/UserStore';
import ExperimentValidationModal from './ExperimentValidationModal';
import OrganizationModel from '../../stores/organizations/models/OrganizationModel';
import './css/experiment.css';

class ExperimentCard extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
    showSuccessMessage: PropTypes.func.isRequired,
    experiment: PropTypes.shape({
      id: PropTypes.string.isRequired,
      experimentCard: PropTypes.shape({
        isFilled: PropTypes.func.isRequired,
      }),
      validationStatus: PropTypes.shape({
        get: PropTypes.func.isRequired,
      }),
      project: PropTypes.shape({
        isMember: PropTypes.func.isRequired,
        organizationModel: modelOf(OrganizationModel).isRequired,
      }).isRequired,
    }),
  };

  static CANVAS_LINES = [
    {
      blocks: [
        {
          name: 'learningGoal',
          label: i18n.t('experimentCard.learningGoal.label'),
          iconName: 'ic-target',
          placeholder: i18n.t('experimentCard.learningGoal.placeholder'),
        },
      ],
    },
    {
      blocks: [
        {
          name: 'hypothesis',
          label: i18n.t('experimentCard.hypothesis.label'),
          iconName: 'ic-assignment',
          placeholder: i18n.t('experimentCard.hypothesis.placeholder'),
          style: { flex: 1.6 },
        },
      ],
    },
    {
      blocks: [
        {
          name: 'metrics',
          label: i18n.t('experimentCard.metrics.label'),
          iconName: 'ic-network_check',
          placeholder: i18n.t('experimentCard.metrics.placeholder'),
        },
        {
          name: 'invalidationCriteria',
          label: i18n.t('experimentCard.invalidationCriteria.label'),
          iconName: 'ic-do_not_disturb_alt',
          placeholder: i18n.t('experimentCard.invalidationCriteria.placeholder'),
        },
        {
          name: 'earlyStopCondition',
          label: i18n.t('experimentCard.earlyStopCondition.label'),
          iconName: 'ic-alert-octagon',
          placeholder: i18n.t('experimentCard.earlyStopCondition.placeholder'),
        },
      ],
    },
    {
      blocks: [
        {
          name: 'plan',
          label: i18n.t('experimentCard.plan.label'),
          iconName: 'ic-sketch',
          placeholder: i18n.t('experimentCard.plan.placeholder'),
        },
      ],
    },
  ];

  showErrorMessage = errorMessage => {
    this.props.showErrorMessage(errorMessage, 5000);
  };

  showSuccessMessage = successMessage => {
    this.props.showSuccessMessage(successMessage, 5000);
    this.createModal && this.createModal.close();
  };

  requestValidationClick = () => this.createModal.open();

  renderValidationStatus = ([group, validation]) => {
    const user = userStore.colleaguesMap[validation.identity];
    const fullName = user && user.displayName ? user.displayName : validation.identity;
    const groupElement = this.props.experiment.project.organizationModel.groups.find(el => el.id === group);
    const groupName = groupElement ? groupElement.name : group;
    return (
      <p key={group}>
        {i18n.t('validation.' + validation.status.toLowerCase(), {
          groupName,
          fullName,
        })}
        {user ? <img src={user.avatarURL} className="user-avatar" alt={user.fullName} /> : null}
      </p>
    );
  };

  renderValidationActions = notYetRequestedGroups => {
    if (notYetRequestedGroups.length <= 0) {
      return null;
    } else {
      return (
        <div>
          <button className="btn btn_primary" onClick={this.requestValidationClick}>
            {i18n.t('validation.requestValidation')}
          </button>
          <ExperimentValidationModal
            ref={createModal => (this.createModal = createModal)}
            experiment={this.props.experiment}
            groups={notYetRequestedGroups}
            onError={this.showErrorMessage}
            onSuccess={this.showSuccessMessage}
          />
        </div>
      );
    }
  };

  render() {
    const canEdit =
      userStore.currentUser &&
      (userStore.currentUser.hasOneOrManyAdminRoles ||
        this.props.experiment.project.isMember(userStore.currentUser.id));

    const notYetRequestedGroups = this.props.experiment.project.organizationModel.nonEmptyGroups.filter(
      group => !this.props.experiment.validationStatus.get(group.id)
    );

    return (
      <div className="experimentCard">
        <div className="validation-form">
          <div className="validation-status">
            {Array.from(this.props.experiment.validationStatus || {}).map(this.renderValidationStatus)}
          </div>
          {canEdit && this.props.experiment.experimentCard.isFilled() ? (
            <div className="validation-actions">{this.renderValidationActions(notYetRequestedGroups)}</div>
          ) : null}
        </div>
        <Canvas
          boardId="experimentCard"
          project={this.props.experiment.project}
          data={this.props.experiment.experimentCard}
          lines={ExperimentCard.CANVAS_LINES}
        />
      </div>
    );
  }
}

export default withFeedback(observer(ExperimentCard));
