import React from 'react';
import i18n from '../../i18n';
import OrganizationsStore from '../stores/organizations/OrganizationsStore';
import UserStore from '../stores/user/UserStore';
import BoardsList from './BoardsList';

class BoardsPanel extends React.Component {
  render() {
    const organizationName = OrganizationsStore.getOrganizationById(UserStore.currentUser.currentOrganization).name;

    return (
      <div className="panel">
        <BoardsList
          iconName="ic-user"
          name={i18n.t('projectBoard.title', {
            organizationName: organizationName,
          })}
          starred={false}
        />
      </div>
    );
  }
}

export default BoardsPanel;
