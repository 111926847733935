/*global document */

import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import './css/dropDown.css';

class DropDown extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    editable: PropTypes.bool,
    handleSelectChange: requiredIf(PropTypes.func, props => props.editable),
    value: PropTypes.shape({
      backgroundColor: PropTypes.string,
      fontColor: PropTypes.string,
      iconClass: PropTypes.string,
      id: PropTypes.string.isRequired,
      text: PropTypes.string,
    }).isRequired,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        backgroundColor: PropTypes.string,
        fontColor: PropTypes.string,
        iconClass: PropTypes.string,
        id: PropTypes.string.isRequired,
        text: PropTypes.string,
      })
    ).isRequired,
  };

  preventNext = false;

  state = {
    opened: false,
  };

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  handleOutsideClick = () => {
    if (this.preventNext) {
      this.preventNext = false;
      return;
    }
    this.state.opened && this.setState({ opened: false });
  };

  handleToggle = event => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    this.preventNext = true;
    this.props.editable && this.setState({ opened: !this.state.opened });
  };

  handleSelect = (event, newValueId) => {
    event.stopPropagation();
    this.props.handleSelectChange(newValueId);
    this.setState({ opened: false });
  };

  getColorStyle = (colorProp, value) => {
    if (value) {
      const style = { style: {} };
      style.style[colorProp] = value;
      return style;
    } else {
      return {};
    }
  };

  renderOption = value => (
    <div
      key={value.id}
      className="dropdown-dropdown-item"
      onClick={event => this.handleSelect(event, value.id)}
      {...this.getColorStyle('color', value.fontColor)}
    >
      {value.iconClass ? <i className={'m-r-5 ' + value.iconClass} /> : null}
      {value.text}
    </div>
  );

  renderDropdown() {
    return (
      <div className="dropdown-dropdown">
        {this.props.values.filter(value => value.text !== this.props.value.text).map(this.renderOption)}
      </div>
    );
  }

  render() {
    const openedIcon = this.state.opened ? 'up' : 'down';

    return (
      <div className={`dropdown_wrapper ${this.props.className}`}>
        <div
          className={`dropdown${this.props.editable ? ' cursor_pointer' : ''}`}
          onClick={this.handleToggle}
          {...this.getColorStyle('backgroundColor', this.props.value.backgroundColor)}
        >
          {this.props.value.iconClass ? <i className={`m-r-10 ${this.props.value.iconClass}`} /> : null}
          {this.props.value.text}
          {this.props.editable && this.props.values.length > 0 ? (
            <i className={`dropdown-chevron ic-chevron-${openedIcon} m-l-10`} />
          ) : null}
        </div>
        {this.state.opened && this.props.values.length > 0 ? this.renderDropdown() : null}
      </div>
    );
  }
}

export default DropDown;
