import i18n from '../../i18n';

const HelpsTexts = {
  interview: {
    title: i18n.t('help.interview.title'),
    text: i18n.t('help.interview.text'),
    howTo: {
      title: i18n.t('help.interview.howTo.title'),
      blocks: [
        {
          title: i18n.t('help.interview.howTo.block1.title'),
          texts: [
            i18n.t('help.interview.howTo.block1.text1'),
            i18n.t('help.interview.howTo.block1.text2'),
            i18n.t('help.interview.howTo.block1.text3'),
            i18n.t('help.interview.howTo.block1.text4'),
          ],
        },
        {
          title: i18n.t('help.interview.howTo.block2.title'),
          texts: [
            i18n.t('help.interview.howTo.block2.text1'),
            i18n.t('help.interview.howTo.block2.text2'),
            i18n.t('help.interview.howTo.block2.text3'),
            i18n.t('help.interview.howTo.block2.text4'),
            i18n.t('help.interview.howTo.block2.text5'),
          ],
        },
        {
          title: i18n.t('help.interview.howTo.block3.title'),
          text: i18n.t('help.interview.howTo.block3.text1'),
        },
        {
          title: i18n.t('help.interview.howTo.block4.title'),
          texts: [
            i18n.t('help.interview.howTo.block4.text1'),
            i18n.t('help.interview.howTo.block4.text2'),
            i18n.t('help.interview.howTo.block4.text3'),
            i18n.t('help.interview.howTo.block4.text4'),
            i18n.t('help.interview.howTo.block4.text5'),
            i18n.t('help.interview.howTo.block4.text6'),
            i18n.t('help.interview.howTo.block4.text7'),
            i18n.t('help.interview.howTo.block4.text8'),
            i18n.t('help.interview.howTo.block4.text9'),
          ],
        },
      ],
    },
    advices: [
      { type: 'DO', text: i18n.t('help.interview.advices.text1') },
      { type: 'DONT', text: i18n.t('help.interview.advices.text2') },
      { type: 'DO', text: i18n.t('help.interview.advices.text3') },
      { type: 'DONT', text: i18n.t('help.interview.advices.text4') },
    ],
  },

  ideate: {
    title: i18n.t('help.ideate.title'),
    text: i18n.t('help.ideate.text'),
    howTo: {
      title: i18n.t('help.ideate.howTo.title'),
      blocks: [
        {
          title: i18n.t('help.ideate.howTo.block1.title'),
          isHtml: true,
          texts: [
            i18n.t('help.ideate.howTo.block1.text1'),
            i18n.t('help.ideate.howTo.block1.text2'),
            i18n.t('help.ideate.howTo.block1.text3'),
            i18n.t('help.ideate.howTo.block1.text4'),
            i18n.t('help.ideate.howTo.block1.text5'),
            i18n.t('help.ideate.howTo.block1.text6'),
            i18n.t('help.ideate.howTo.block1.text7'),
          ],
        },
      ],
    },
    advices: [
      { type: 'DO', text: i18n.t('help.ideate.advices.text1') },
      { type: '', text: i18n.t('help.ideate.advices.text2') },
    ],
    toolGuidelines: i18n.t('help.ideate.toolGuidelines'),
  },

  experiment: {
    title: i18n.t('help.experiment.title'),
    text: i18n.t('help.experiment.text'),
    howTo: {
      title: i18n.t('help.experiment.howTo.title'),
      blocks: [
        {
          title: i18n.t('help.experiment.howTo.block1.title'),
          texts: [
            i18n.t('help.experiment.howTo.block1.text1'),
            i18n.t('help.experiment.howTo.block1.text2'),
            i18n.t('help.experiment.howTo.block1.text3'),
            i18n.t('help.experiment.howTo.block1.text4'),
            i18n.t('help.experiment.howTo.block1.text5'),
            i18n.t('help.experiment.howTo.block1.text6'),
          ],
        },
      ],
    },
    advices: [
      { type: 'DONT', text: i18n.t('help.experiment.advices.text1') },
      { type: 'DO', text: i18n.t('help.experiment.advices.text2') },
      { type: 'DONT', text: i18n.t('help.experiment.advices.text3') },
    ],
    toolGuidelines: i18n.t('help.experiment.toolGuidelines'),
  },

  analyze: {
    title: i18n.t('help.analyze.title'),
    text: i18n.t('help.analyze.text'),
    howTo: {
      title: i18n.t('help.analyze.howTo.title'),
      blocks: [
        {
          title: i18n.t('help.analyze.howTo.block1.title'),
          texts: [
            i18n.t('help.analyze.howTo.block1.text1'),
            i18n.t('help.analyze.howTo.block1.text2'),
            i18n.t('help.analyze.howTo.block1.text3'),
          ],
        },
        {
          title: i18n.t('help.analyze.howTo.block2.title'),
          texts: [i18n.t('help.analyze.howTo.block2.text1'), i18n.t('help.analyze.howTo.block2.text2')],
        },
      ],
    },
    advices: [
      { type: 'DO', text: i18n.t('help.analyze.advices.text1') },
      { type: 'DO', text: i18n.t('help.analyze.advices.text2') },
      { type: 'DONT', text: i18n.t('help.analyze.advices.text3') },
    ],
    toolGuidelines: i18n.t('help.analyze.toolGuidelines'),
  },

  decide: {
    title: i18n.t('help.decide.title'),
    text: i18n.t('help.decide.text'),
    howTo: {
      title: i18n.t('help.decide.howTo.title'),
      blocks: [
        {
          title: i18n.t('help.decide.howTo.block1.title'),
          texts: [
            i18n.t('help.decide.howTo.block1.text1'),
            i18n.t('help.decide.howTo.block1.text2'),
            i18n.t('help.decide.howTo.block1.text3'),
          ],
        },
      ],
    },
    advices: [{ type: 'DO', text: i18n.t('help.decide.advices.text1') }],
  },
};

export default HelpsTexts;
