import i18n from '../../../i18n';

const unknownError = i18n.t('errors.unknownError');

const getErrorMessage = error => {
  const errorKey = error.error ? 'errors.' + error.error : error.message;
  let errors = '';
  if (error.errors && typeof error.errors === 'object') {
    for (let errorField in error.errors) {
      if (error.errors.hasOwnProperty(errorField)) {
        const fieldError = error.errors[errorField][0];
        errors = i18n.t(`errors.${fieldError}`, { fieldName: errorField });
        break;
      }
    }
  }
  if (errors === '' && !error.errors && !error.error) {
    console.error('getErrorMessage', error);
  }

  return i18n.t(errorKey, { errors, defaultValue: unknownError });
};

export { getErrorMessage };
