const unknownErrorMessage = 'An error has occurred.';

export default {
  meta: {
    defaultTitle: '',
    description: '',
    applicationName: 'Reacteev Toolkit',
  },

  errors: {
    unknownError: unknownErrorMessage,
    forbidden: 'You do not have permission to do that.',
    unknownLight: 'An error has occurred, please try again in a few seconds.',
    network: 'Connection error, please verify your Internet connectivity and try again.',
    IllegalArgumentException: 'Invalid data, please check your input',
    invalid: {
      data: 'Invalid input: {{errors}}',
      credentials: 'Invalid credentials',
      current: {
        password: 'Current password is incorrect',
      },
    },
    error: {
      email: 'Not a valid email address',
      minLength: '{{fieldName}} is too short',
      passwords: {
        mismatch: 'Passwords do not match',
      },
    },
    token: {
      notfound: 'The token is not valid, either the link is not correct or you already used it',
      invalid: 'The token is not valid, either the link is not correct or you already used it',
    },
    unknown: {
      user: 'We don’t know any user with this email address',
    },
    already: {
      exists: 'This item already exists',
    },
    nothing: {
      to: {
        do: 'Nothing to do',
      },
    },
    user: {
      disabled: 'Your account is disabled, please contact your administrator',
    },
  },

  dashboard: {
    title: 'Dashboard',
    innovationPipe: 'Innovation Pipe',
    newsFeed: 'News feed',
    recentlyUploaded: 'Recently uploaded',
    stats: {
      projects: {
        zero: 'Project',
        one: 'Project',
        other: 'Projects',
      },
      ideas: {
        zero: 'Idea',
        one: 'Idea',
        other: 'Ideas',
      },
      learnings: {
        zero: 'Learning',
        one: 'Learning',
        other: 'Learnings',
      },
      experiments: {
        zero: 'Experiment',
        one: 'Experiment',
        other: 'Experiments',
      },
      solvedChallenges: {
        zero: 'Challenge Solved',
        one: 'Challenge Solved',
        other: 'Challenges Solved',
      },
    },
  },

  projectBoard: {
    title: '{{organizationName}} Projects',
  },

  projects: {
    title: 'Projects',
    IDEAS: 'Ideation',
    SOLVED: 'Project Done',
    MVP: 'Building Prototypes',
    TEST: 'Experimenting',
    PPP: 'Deciding',
    new: {
      title: 'NEW PROJECT',
      name: {
        label: 'Title',
        placeholder: 'Title',
      },
      create: 'CREATE',
    },
    add: 'CREATE NEW PROJECT',
    delete: 'Delete project',
    confirmDelete: 'Do you really want to delete the project "{{projectName}}" ?',
  },

  action: {
    new: {
      title: {
        placeholder: 'Your awesome action',
      },
      create: 'ADD',
    },
    add: {
      text: 'ADD AN ACTION',
      title: 'Add an action',
    },
    description: 'Description',
    confirmDelete: 'Do you really want to delete the action {{actionTitle}}?',
    type: {
      EMPATHIZE: 'Empathize',
      NONE: 'None',
    },
  },

  task: {
    assignees: {
      add: 'ADD',
      title: 'Assignees',
    },
    status: {
      BACKLOG: 'Backlog',
      TODO: 'To do',
      INPROGRESS: 'In progress',
      DONE: 'Done',
    },
  },

  empathize: {
    EMPATHIZE: 'Empathize',
  },

  idea: {
    IDEAS: 'Ideate',
    MVP: 'Experiment',
    TEST: 'Analyze',
    PPP: 'Decide',
    SOLVED: 'Scale',
    new: {
      title: {
        placeholder: 'Your awesome idea',
      },
      create: 'ADD',
    },
    add: {
      text: 'ADD AN IDEA',
      title: 'Add an idea',
    },
    delete: 'Delete idea',
    description: {
      label: 'Description',
      placeholder: 'Describe your idea here...',
    },
  },

  experiment: {
    plurial: 'Experiments',
    createIt: 'Create an experiment',
    deleteIt: 'Delete the experiment',
    title: {
      label: 'Title',
      placeholder: 'Title',
    },
    REFUSED: 'Refused',
    BUILDING: 'Building',
    PIVOTED: 'Pivoted',
    TESTING: 'Testing',
    PPP: 'In decision',
    ACCEPTED: 'Accepted',
    new: 'New Experiment',
    create: 'CREATE',
    accept: 'Accept',
    refuse: 'Refuse',
    pivot: 'Pivot',
    confirmDelete: 'Do you really want to delete the experiment {{experimentTitle}}?',
  },

  board: {
    title: 'Board',
    backToBoard: 'Back to the board',
    businessChallenge: {
      description: {
        label: 'Major Assumption or Belief',
        placeholder: `What business / customer / consumer / user problem am I trying to solve? What is the problem today? (WHY is it a problem? What’s the consequence?)
What is the desired future state? What do I need to LEARN to move forward? What’s uncertain?`,
      },
      customers: {
        label: 'Key question to be answered',
        placeholder: `What is the customer segment we are targeting? Who are we not targeting as part of this business challenge?
(Evaluate the situation based on DATA as much as possible).`,
      },
      expectation: {
        label: 'Desired Outcome',
        placeholder: `What are the learnings / de-risking we want to have at the end of the challenge?
What does success look like? What would we see/hear/observe? Which behaviors of which customers would change / evolve if we are successful?
What would failure look like? What should we look to avoid?`,
      },
      metrics: {
        label: 'Metrics',
        placeholder: `Is there a specific goal or is it more fuzzy?
What KPIs/metric should move as a result of solving this problem?
(e.g. Awareness, Acquisition, Activation, Retention, Revenue or Referral) How do I measure success (outcome) and progress?`,
      },
      context: {
        label: 'Context',
        placeholder: `What is the current state of art?
What are the trends?
What data do we already have?
What do we know about competitions' plans?
Are there substitutes products or services targeting/solving the same problem?
Is the team expanding the market or competing for existing market share?`,
      },
      constraints: {
        label: 'Constraints',
        placeholder: `Are there technical or regulatory constraints to take into account?
Is there any deadline?`,
      },
      resources: {
        label: 'Resources',
        placeholder: `Previous survey or data on the subject.`,
      },
      teamCoordinator: {
        label: 'Team Coordinator',
        placeholder: `Who is your team coordinator?`,
      },
      businessOwner: {
        label: 'Business Owner',
        placeholder: `Who is your business owner?`,
      },
      builder: {
        label: 'Builder',
        placeholder: `Who is your builder?`,
      },
      catalyst: {
        label: 'Catalyst',
        placeholder: `Who is your catalyst?`,
      },
    },
    errors: {
      missingExperimentCard: 'There is no experiment to test for that idea. The experiment card must be filled.',
      missingTestsResults: 'There is no experiment ready for the decision step. The tests results must be filled.',
      noDecidedExperiment: 'There is no experiment ready to be considered as a solution.',
    },
  },

  todo: {
    title: 'Todo',
  },

  businessChallenge: {
    title: 'Business Challenge',
  },

  learningCard: {
    title: 'Learning Card',
  },

  learnings: {
    title: 'Learnings',
    emptyText: 'No learnings yet',
    status: 'Status',
    text: {
      label: 'Text',
      placeholder: 'Text',
    },
    save: 'SAVE',
    add: 'ADD',
    confirmDelete: 'Do you really want to delete the learning "{{learningText}}"?',
    painPoints: {
      title: 'Pain Points',
      emptyText: 'No pain point yet',
    },
    gainPoints: {
      title: 'Gain Points',
      emptyText: 'No gain point yet',
    },
    otherLearnings: {
      title: 'Other Learnings',
      emptyText: 'No other learning yet',
    },
    tasksLearnings: 'Learnings from cards',
  },

  experimentCard: {
    title: 'Experiment Card',
    learningGoal: {
      label: 'Learning Goal',
      placeholder: 'What do we need to learn? What is our riskiest assumption? What is our one priority?',
    },
    hypothesis: {
      label: 'Hypothesis & Assumptions',
      placeholder:
        'Is it falsifiable? Is it specific? Is it causal? E.g. if X then Y, is it relevant to the learning goal?',
    },
    metrics: {
      label: 'Metrics/Data',
      placeholder: 'Qualitative or quantitative? Is it actionable? Is it measurable?',
    },
    invalidationCriteria: {
      label: 'Invalidation Criteria',
      placeholder: 'If this happens, our hypothesis is clearly false!',
    },
    earlyStopCondition: {
      label: 'Early Stop Condition',
      placeholder: 'If this happens, stop! Experiment is broken, retro!',
    },
    plan: {
      label: 'Plan',
      placeholder: 'How will you collect the data? Is it specific? Is it achievable? Link to any supporting document',
    },
  },

  results: {
    title: 'Results',
  },

  nextSteps: {
    title: 'Next steps',
  },

  search: {
    placeholder: 'Search ...',
    resultFor: 'Search results for ',
    projectsFound: {
      zero: 'No Projects Found',
      one: 'Project Found',
      other: 'Projects Found',
    },
    ideasFound: {
      zero: 'No Ideas Found',
      one: 'Idea Found',
      other: 'Ideas Found',
    },
    experimentsFound: {
      zero: 'No Experiments Found',
      one: 'Experiment Found',
      other: 'Experiments Found',
    },
    learningsFound: {
      zero: 'No Learnings Found',
      one: 'Learning Found',
      other: 'Learnings Found',
    },
    actionsFound: {
      zero: 'No Actions Found',
      one: 'Action Found',
      other: 'Actions Found',
    },
    filesFound: {
      zero: 'No Files Found',
      one: 'File Found',
      other: 'Files Found',
    },
    contentFilesFound: {
      zero: 'No Content Files Found',
      one: 'Content File Found',
      other: 'Content Files Found',
    },
  },

  see: {
    project: 'See the project',
    learnings: 'See all learnings',
    idea: 'See the idea',
    experiment: 'See the experiment',
    action: 'See the action',
    file: 'Open file',
  },

  numberOf: {
    members: 'Number of members',
    ideas: 'Number of ideas',
    experiments: 'Number of experiments',
    solutions: 'Number of solutions',
  },

  solutions: {
    title: 'Solutions',
  },

  files: {
    title: 'Files',
    emptyList: 'No files yet',
    add: 'ADD',
    uploading: 'Uploading...',
    clickOrDrag: 'Click or drag files here',
    confirmDelete: 'Do you really want to delete the file {{fileName}}?',
    public: 'Public',
    callFor: {
      IDEA: 'Idea',
      PROJECT: 'Project',
      PROPOSAL: 'Proposal',
      INFORMATION: 'Information',
    },
    callForSentence: {
      IDEA: 'Call for ideas:',
      PROJECT: 'Call for projects:',
      PROPOSAL: 'Call for proposal:',
      INFORMATION: 'Call for information:',
    },
    tasksFiles: 'Files from cards',
  },

  members: {
    title: 'Members',
    emptyList: 'No members yet',
    new: {
      label: 'Add member',
      placeholder: 'Member’s name',
    },
    add: 'ADD',
    confirmDelete: 'Do you really want to remove {{memberName}} from this project?',
  },

  validation: {
    requestValidation: 'Request validation',
    requested: 'Validation from {{groupName}} requested by {{fullName}}',
    approved: 'Approved for {{groupName}} by {{fullName}}',
    refused: 'Refused for {{groupName}} by {{fullName}}',
    sendRequest: 'Send',
    requestError: 'Error sending validation request: {{errorMessage}}',
    emailsWillBeSent: 'Validation requested. Emails to group(s) {{groupNames}} will be sent shortly.',
  },

  user: {
    welcomeBack: 'Welcome back!',
    forgotPassword: 'Oops! I forgot my password',
    hello: 'Hello{{firstName}}!',
    submit: 'Submit',
    signOut: 'Sign Out',
    cancel: 'Cancel',
    signIn: {
      email: 'Email',
      password: 'Password',
    },
    changePassword: {
      link: 'Change Password',
      title: 'Change your password:',
      currentPassword: 'Current password',
      newPassword1: 'New password',
      newPassword2: 'Confirm new password',
      success: 'Password changed successfully',
    },
    resetPassword: {
      request: 'Enter your email, we’ll send you a link to reset your password',
      invalidUrl: 'Invalid URL for password reset!',
      title: 'Choose a new password:',
      success: 'Password changed successfully',
    },
    check: {
      inbox: 'We sent you an email to reset your password, please check your inbox',
    },
  },

  events: {
    ProjectCreated: 'Project "{{projectName}}" created',
    ProjectDeleted: 'Project "{{projectName}}" deleted',
    ProjectFilesAdded: 'Files {{fileNames}} added in project "{{projectName}}"',
    ProjectFilesRemoved: '',
    LearningCreated: 'New learning on project "{{projectName}}": {{learningText}}',
    LearningDeleted: 'Learning "{{learningText}}" deleted on project "{{projectName}}"',
    IdeaCreated: 'Idea "{{ideaTitle}}" created in project "{{projectName}}"',
    IdeaDeleted: 'Idea "{{ideaTitle}}" deleted on project "{{projectName}}"',
    IdeaStatusUpdated: 'Project "{{projectName}}" solved!',
    ExperimentCreated: 'New experiment on project "{{projectName}}": {{experimentTitle}}',
    ExperimentDeleted: 'Experiment "{{experimentTitle}}" deleted on project "{{projectName}}"',
    ExperimentResultsUpdated: 'Results updated on project "{{projectName}}": {{resultsText}}',
    ExperimentSolutionsUpdated: 'Solutions updated on project "{{projectName}}": {{solutionsText}}',
    ExperimentValidationRequested:
      'Validation requested from "{{groupNames}}" on the "{{experimentTitle}}" experiment in project "{{projectName}}"',
    ExperimentValidationApproved:
      'Validation approved for the experiment "{{experimentTitle}}" in project "{{projectName}}"',
    ExperimentValidationRefused:
      'Validation refused for the experiment "{{experimentTitle}}" in project "{{projectName}}"',
    ExperimentResultFilesAdded: 'Result files {{fileNames}} added in project "{{projectName}}"',
    ExperimentResultFileRemoved: '',
    ExperimentSolutionFilesAdded: 'Solution files {{fileNames}} added in project "{{projectName}}"',
    ExperimentSolutionFileRemoved: '',
  },

  admin: {
    title: 'Admin',
    organizationAdmin: '{{organizationName}} administration',
    logo: {
      title: 'Organization Logo',
      clickOrDrag: 'Click or drag image here',
      confirmDelete: 'Do you really want to delete organization logo?',
    },
    scaleColumnHidden: 'Hide Scale column',
    scaleColumnHiddenLabel: 'When checked, Scale column will be hidden, all cards will remain in Decide column',
    exportAll: 'Export all data',
    groups: {
      title: 'Validation Groups',
      emptyList: 'No groups yet',
      new: {
        title: 'CREATE NEW GROUP',
        name: {
          label: 'Name',
          placeholder: 'Group name',
        },
        create: 'CREATE',
      },
      add: 'ADD',
      delete: 'Delete group',
      confirmDelete: 'Do you really want to remove group {{groupName}}?',
      nbMembers: {
        zero: '(no member)',
        one: '({{count}} member)',
        other: '({{count}} members)',
      },
      confirmRemoveMember: 'Do you really want to remove {{memberName}} from this group?',
    },
    users: {
      title: 'Users',
      new: {
        title: 'NEW USER',
        firstName: {
          label: 'First Name',
          placeholder: 'First Name',
        },
        lastName: {
          label: 'Last Name',
          placeholder: 'Last Name',
        },
        email: {
          label: 'Email',
          placeholder: 'email@company.com',
        },
        enableUser: {
          text: 'Enable user?',
          title: 'A disabled user cannot login but can be in a Validation Group.',
        },
        create: 'CREATE',
      },
      confirmDelete: 'Do you really want to remove {{memberName}} from this organization?',
      add: {
        requestError: 'Error adding user. {{errorMessage}}',
      },
      remove: {
        requestError: 'Error removing user. {{errorMessage}}',
      },
      enable: {
        requestError: 'Error enabling user. {{errorMessage}}',
      },
      disable: {
        requestError: 'Error disabling user. {{errorMessage}}',
      },
      errors: {
        userNotFound: 'This user does not exist',
        userNotMember: 'User is not a member of organization',
      },
      nbUsers: {
        zero: '(no user)',
        one: '({{count}} user)',
        other: '({{count}} users)',
      },
      state: {
        enabled: 'An enabled user can login and use the tool.',
        disabled: 'A disabled user cannot login but can be in a Validation Group.',
      },
    },
  },

  help: {
    dosAndDont: 'DO’S AND DON’T',
    toolGuidelines: 'REACTEEV TOOL GUIDELINES',

    interview: {
      title: 'Interview',
      text: '',
      howTo: {
        title: 'How to',
        block1: {
          title: 'Plan the interview',
          text1: 'Define the learning goal.',
          text2: 'Define key asumption about the cutomer persona.',
          text3:
            'Create a screener survey of simple questions that will identify if the potential interviewee matches the target customer persona.',
          text4: 'Make an interview guide.',
        },
        block2: {
          title: 'Conduct the interview',
          text1: 'Frame: Summarize the  purpose of the interview with the customer.',
          text2: 'Qualify: Ask a screener  question to determine if the customer is relevant to your customer persona.',
          text3: 'Open: Warm up questions get the customer comfortable talking.',
          text4: 'Listen: Let the customer talk and follow up with   “what” and “how” questions.',
          text5: 'Close: Wrap up the interview and ask for referrals, or if applicable, a follow-up interview.',
        },
        block3: {
          title: 'Debrief the interview',
          text1: 'Make notes promptly; sometimes a video or audio recording can be helpful.',
        },
        block4: {
          title: 'Interpreting Results: Are you able to listen and record data based on the following?',
          text1: 'Job: What activities are making the customer run into  the problem?',
          text2: 'Obstacle: What is preventing the customer from solving their problem?',
          text3: 'Goal: If they solve their problem, then  _____?',
          text4: 'Current solution: How are they solving their problem?',
          text5: 'Decision trigger: Were  there pivotal moments where the customer made key decisions?',
          text6: 'Interest trigger: Which questions did the customer express interest in?',
          text7: 'Persons: Are there any other people involved withn the problem or solution?',
          text8: 'Emotions: Is there anything specific that causes the customer to express different emotions?',
          text9: 'Measurement: How is the customer measuring the cost of their problem?',
        },
      },
      advices: {
        text1: 'Try to run questions in a different order in different interviews',
        text2: 'Do not sell your vision in case the interviewee’s vision differs drastically',
        text3: 'Ask about the past. Observe the present. Forget about the future',
        text4: 'Do not talk about your idea',
      },
    },

    ideate: {
      title: 'Ideate',
      text:
        'Based on your customer discovery start generating ideas on how to solve the pain points and reinforce the gains.',
      howTo: {
        title: 'How to generate ideas?',
        block1: {
          title: 'For each pain/gain',
          text1:
            'Generate ideas individually using post-its. One idea per post-it. Aim quantity over quality. At this stage the more ideas the better. <i>5 minutes</i>',
          text2: 'Share on the wall all the post-its. No judgements for the moment. <i>10 minutes</i>',
          text3:
            'Based on the previous discussion (step 2), generate individually on post-its new ideas or ideas that could compliment others. <i>5 minutes</i>',
          text4: 'Share your new ideas on the wall and start clustering them into bigger ideas. <i>5 minutes</i>',
          text5: 'Quickly sketch your clusters on post-its. <i>5 minutes</i>',
          text6:
            'Evaluate your ideas via 2*2 matrix against their value for customer and for business. <i>10 minutes</i>',
          text7:
            'Each team member gets 3 dots to vote for his preferred idea on the top right hand corner. <i>5 minutes</i>',
        },
      },
      advices: {
        text1: 'Do not be scared of crazy ideas. Whatever comes in mind, just note it on a post-it.',
        text2: 'You don’t need to know how to draw.',
      },
      toolGuidelines: 'List your selected ideas on your Reacteev toolkit board. <i>5 minutes</i>',
    },

    experiment: {
      title: 'Experiment',
      text:
        'Prototype is a version of a new product which allows to test a hypothesis with a customer in order to validate the desirability of the product. ' +
        'It can take different shapes such as paper or clickable prototypes, landing pages, marketing materials etc.',
      howTo: {
        title: 'How to fill in your experiment card?',
        block1: {
          title: 'In order to prepare your experiment fill in the experiment card',
          text1: 'Set up a learning goal. What do you want to learn?',
          text2: 'Write your hypothesis “If x then y”',
          text3: 'Choose a metric. One key metric should be enough. It must be actionable. Try to have a ratio.',
          text4: 'Fail condition. If x happens this hypothesis is clearly false.',
          text5: 'Early stop condition. If it happens STOP.',
          text6: 'Plan. List all the actions you need to do in order to validate or invalidate your assumption.',
        },
      },
      advices: {
        text1:
          'Do not spend too much time perfecting your prototype. It’s not worth spending time on something customer potentially doesn’t want.',
        text2: 'Validate your prototype and the experiment card with your legal department.',
        text3: 'Do not be scared to damage your brand’s image as you are testing only with few people.',
      },
      toolGuidelines:
        'Add the title of your experiment, click “plus” and fill in your experiment card. You can also add pictures of your experiment or any files related to the experiment.',
    },

    analyze: {
      title: 'Analyze',
      text: 'Now that your prototype is ready, you need to set up your experiment.',
      howTo: {
        title: 'How to test?',
        block1: {
          title: 'During the experiment',
          text1: 'Make sure to collect all the data to monitor your metrics',
          text2: 'Understand your customer behavior through observations and short interviews',
          text3: 'Ask the third parties involved for more details',
        },
        block2: {
          title: 'Once your experiment has been done',
          text1: 'Fill in the results and learning',
          text2:
            'Ask yourself: Are you above your validation criteria? Why? What are the learnings from the customer interviews?',
        },
      },
      advices: {
        text1: 'Test min with 5 people',
        text2: 'During short interviews ask “5 why”',
        text3: 'Avoid WOW data effect, stick to the raw data. (don’t polish your results)',
      },
      toolGuidelines:
        'Make sure to save all your leanings in the tool. ' +
        'It might be useful for the future. ' +
        'Add pictures and other available materials to enable your colleagues and deciders to visualise what you are working on.<br>Keep all your “aha” moments. ' +
        'If you were surprised the others will be too.',
    },

    decide: {
      title: 'Decide',
      text: `This is a crucial moment for your idea.
Management board is in charge of choosing the ideas to scale. To help them make this decision, make sure they have all necessary data.`,
      howTo: {
        title: 'How to',
        block1: {
          title: '',
          text1: 'Results of your experiment and all the learning related to it.',
          text2: 'Your next steps: how are you planning to set up the pilot and what is your expected outcome.',
          text3: 'If possible calculate ROI of your pilot',
        },
      },
      advices: {
        text1: 'Think only about next steps, don’t plan for 10 years ahead',
      },
    },
  },

  publicGallery: {
    organizationName: '{{organizationName}} & Co',
    welcome: 'Welcome to {{organizationName}}’s collaborative innovation platform',
    openMarket: 'The open market for innovative ideas and projects',
    openInnovation: {
      title: 'Open innovation projects',
      text: 'Startups, Corporates, Institutions, Students, you are invited to shape the future with us:',
    },
    howToCollaborate: {
      title: 'How to collaborate',
      text:
        'To ideate with {{organizationName}} & Co, select the call for collaboration you want to apply to, download the attached file and innovate!',
      sendProposal: 'If you want to send your own project proposal',
    },
    footer: {
      text: 'This page is edited by the innovation agency Reacteev for {{organizationName}} & Co.',
      legal: 'Legal informations',
      contact: 'Contact',
    },
  },
};
