import React from 'react';
import './css/DashboardPanel.css';
import StatsCards from './StatsCards';
import NewsFeed from './NewsFeed';
import Carousel from './Carousel';
import i18n from '../../i18n';

class DashboardPanel extends React.Component {
  render() {
    return (
      <div className="panel">
        <div className="dashboardPanel-block">
          <h3 className="dashboardPanel-title">
            <i className="dashboardPanel-title-icon ic-activity" />
            <div className="dashboardPanel-title-text">{i18n.t('dashboard.innovationPipe')}</div>
          </h3>
          <StatsCards />
          <div className="dashboardPanel-columns">
            <NewsFeed />
            <Carousel />
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardPanel;
