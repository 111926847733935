import React from 'react';
import { observer } from 'mobx-react';
import i18n from '../../i18n';
import eventsStore from '../stores/events/EventsStore';
import userStore from '../stores/user/UserStore';

class NewsFeed extends React.Component {
  renderEvent = (event, index) => {
    const user = userStore.colleaguesMap[event.event.identity.id] || {};

    return (
      <a className="dashboardPanel-block-content-line" href={event.link} key={index}>
        <i className={`dashboardPanel-block-content-line-icon ${event.iconName}`} />
        {user ? (
          <i>
            <img src={user.avatarURL} className="member-avatar" alt={user.fullName} title={user.fullName} />
          </i>
        ) : null}
        <div className="dashboardPanel-block-content-line-date">
          {new Date(event.event.timestamp.$date).toLocaleString()}
        </div>
        <div className="dashboardPanel-block-content-line-text">{event.label}</div>
        {event.link ? <i className="dashboardPanel-block-content-line-cta ic-arrow-right" /> : null}
      </a>
    );
  };

  render() {
    return (
      <div className="card dashboardPanel-block-content">
        <h3>{i18n.t('dashboard.newsFeed')}</h3>
        {eventsStore.news().map(this.renderEvent)}
      </div>
    );
  }
}

export default observer(NewsFeed);
