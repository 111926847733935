/*global window */

import React from 'react';
import PropTypes from 'prop-types';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import Autocomplete from 'react-autocomplete';
import { caseInsensitiveSearch } from '../../../components/lib/SearchUtil';
import i18n from '../../../i18n';
import CheckBox from '../../global/input/CheckBox';
import UserStore from '../../stores/user/UserStore';
import userStateEnum from '../../stores/user/models/UserStateEnum';
import AddButton from '../elems/AddButton';
import DeleteButton from '../elems/DeleteButton';
import withFeedback from '../feedback/withFeedback';
import { getErrorMessage } from '../utils/ErrorHandling';
import Member from './Member';
import './css/members.css';
import SubmitAndCancelButton from '../elems/SubmitAndCancelButton';

class Members extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
    members: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), MobxPropTypes.observableArray]),
    membersList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), MobxPropTypes.observableArray]),
    addMember: PropTypes.func,
    removeMember: PropTypes.func,
    confirmDeleteMessage: PropTypes.string,
    allowAddRemoveSelf: PropTypes.bool,
    enableUser: PropTypes.func,
    disableUser: PropTypes.func,
    adding: PropTypes.bool,
    startAdding: PropTypes.func,
    cancelAdding: PropTypes.func,
  };

  static defaultProps = {
    confirmDeleteMessage: 'members.confirmDelete',
    allowAddRemoveSelf: false,
    adding: false,
  };

  state = {
    selectedMemberId: '',
    selectedMemberName: '',
    membersToAdd: [],
  };

  userId = UserStore.currentUser ? UserStore.currentUser.id : undefined;
  canAddOrDelete = UserStore.currentUser && UserStore.currentUser.hasOneOrManyAdminRoles;

  static getDerivedStateFromProps(props) {
    if (!props.membersList) {
      return null;
    }
    const membersToAdd = props.membersList.reduce((members, memberFromList) => {
      if (memberFromList !== null) {
        const existingMember = props.members
          .filter(member => member !== undefined && member !== null)
          .find(member => member.id === memberFromList.id);
        if (!existingMember) {
          members.push(memberFromList);
        }
      }
      return members;
    }, []);
    return { membersToAdd: membersToAdd };
  }

  handleCreate = () => {
    if (this.state.selectedMemberId) {
      this.props.addMember(this.state.selectedMemberId).catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
    }
    this.setState({
      selectedMemberId: '',
      selectedMemberName: '',
    });
    this.props.cancelAdding();
  };

  selectUser = user => {
    this.setState({
      selectedMemberId: user.id,
      selectedMemberName: user.fullName || user.email,
    });
  };

  onDelete = member => event => {
    event.preventDefault();
    event.stopPropagation();
    if (window.confirm(i18n.t(this.props.confirmDeleteMessage, { memberName: member.fullName || member.email }))) {
      this.props.removeMember(member.id).catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
    }
  };

  handleCheckBoxChange = userId => checked => {
    if (checked) {
      this.props.enableUser(userId);
    } else {
      this.props.disableUser(userId);
    }
  };

  renderEnabledToggle = member => {
    if (this.props.enableUser && this.props.disableUser) {
      if (member.id === this.userId) {
        return <div className="switch" />;
      } else {
        const enabled = member.state !== userStateEnum.enum.Disabled;
        const title = enabled ? 'admin.users.state.enabled' : 'admin.users.state.disabled';
        return (
          <CheckBox text="" title={i18n.t(title)} onChange={this.handleCheckBoxChange(member.id)} value={enabled} />
        );
      }
    } else {
      return null;
    }
  };

  renderMember = member => {
    return member ? (
      <div
        className="members-block-content-line text_normal"
        key={member.id}
        title={`${member.fullName} <${member.email}>`}
      >
        {this.renderEnabledToggle(member)}
        <Member member={member} className="members-block-content-line-text" />
        <DeleteButton
          onClick={this.onDelete(member)}
          title="Remove member"
          className="members-block-btnTrash"
          canDelete={
            !!this.props.removeMember &&
            this.canAddOrDelete &&
            (this.props.allowAddRemoveSelf || member.id !== this.userId)
          }
        />
      </div>
    ) : null;
  };

  renderEmptyList = () => (
    <div className="members-block-emptyList d-flex alignItems_center justifyContent_center flex_1 flexDirection_column">
      {i18n.t('members.emptyList')}
      {this.renderAddButton(true)}
    </div>
  );

  renderForm = () => (
    <div className="members-block-form p-20 size_fullAbsolute">
      <div className="form-group">
        <label>{i18n.t('members.new.label')}</label>
        <Autocomplete
          items={this.state.membersToAdd}
          sortItems={(a, b) => ((a.fullName || a.email) > (b.fullName || b.email) ? 1 : -1)}
          menuStyle={{
            borderRadius: '3px',
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
            background: 'white',
            padding: '2px 0',
            fontSize: '90%',
            position: 'fixed',
            overflow: 'auto',
            maxHeight: '40%', // TODO: don't cheat, let it flow to the bottom
            zIndex: 100,
          }}
          wrapperStyle={{ display: 'block' }}
          inputProps={{
            className: 'input',
            placeholder: i18n.t('members.new.placeholder'),
          }}
          shouldItemRender={(user, value) => caseInsensitiveSearch(user.fullName, value)}
          getItemValue={user => user.fullName || user.email}
          renderItem={(user, highlighted) => (
            <Member
              key={user.id}
              member={user}
              className={'member-dropdown-item' + (highlighted ? ' highlighted' : '')}
              onClick={() => this.selectUser(user)}
            />
          )}
          value={this.state.selectedMemberName}
          onChange={e =>
            this.setState({
              selectedMemberId: '',
              selectedMemberName: e.target.value,
            })
          }
          onSelect={(value, user) => this.selectUser(user)}
        />
      </div>
      <SubmitAndCancelButton
        actionLabel={i18n.t('members.add')}
        handleCancel={this.cancelAdding}
        handleSubmit={this.handleCreate}
      />
    </div>
  );

  renderAddButton = isEmptyList => {
    return this.canAddOrDelete ? (
      <AddButton isEmptyList={isEmptyList} buttonText={i18n.t('members.add')} onClick={this.props.startAdding} />
    ) : null;
  };

  render() {
    return (
      <div className="card members-block-content">
        {this.props.members && this.props.members.length > 0
          ? this.props.members.map(this.renderMember)
          : this.renderEmptyList()}
        {this.props.adding ? this.renderForm() : null}
      </div>
    );
  }
}

export default withFeedback(observer(Members));
