import React from 'react';
import PropTypes from 'prop-types';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import DropDown from '../global/input/DropDown';
import OrganizationsStore from '../stores/organizations/OrganizationsStore';
import UserStore from '../stores/user/UserStore';
import projectsStore from '../stores/projects/ProjectsStore';
import './css/headerUserOrganization.css';

class HeaderUserOrganization extends React.Component {
  static propTypes = {
    editable: PropTypes.bool,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    organizations: MobxPropTypes.observableArray.isRequired,
  };

  state = {
    organizations: [],
  };

  componentDidMount() {
    const organizations = this.props.organizations.map(organizationId => {
      const organization = OrganizationsStore.getOrganizationById(organizationId);
      return { id: organization.id, text: organization.name };
    });
    this.setState({ organizations: organizations });
  }

  handleSelectChange = organizationId => {
    UserStore.currentUser.setSelectedOrganization(organizationId);
    projectsStore.refreshOrganizationProjects(organizationId);
    UserStore.refreshColleagues();
    this.props.history.push('/');
  };

  render() {
    return (
      <DropDown
        className="header-organization-dropdown_wrapper"
        editable={this.props.editable}
        handleSelectChange={this.handleSelectChange}
        value={{
          id: UserStore.currentUser.currentOrganization,
          text: OrganizationsStore.getOrganizationById(UserStore.currentUser.currentOrganization).name,
        }}
        values={this.state.organizations}
      />
    );
  }
}

export default withRouter(observer(HeaderUserOrganization));
