import { types, flow } from 'mobx-state-tree';
import moment from 'moment-timezone';
import { caseInsensitiveSearch } from '../../../../components/lib/SearchUtil';
import momentType from '../../../global/types/momentType';
import OrganizationsStore from '../../organizations/OrganizationsStore';
import userStore from '../../user/UserStore';
import ProjectsService from '../ProjectsService';
import ActionsModel from './actions/ActionsModel';
import FilesModel from './files/FilesModel';
import IdeasModel from './ideas/IdeasModel';
import LearningsModel from './learnings/LearningsModel';
import ContextModel from './ContextModel';

function concatObjects(list, initialValue = {}) {
  return Object.entries(list).reduce((acc, [key, value]) => {
    if (undefined === acc[key]) {
      return Object.assign({}, acc, { [key]: value });
    }
    return Object.assign({}, acc, { [key]: acc[key].concat(value) });
  }, initialValue);
}

const ProjectModel = types
  .model('Project', {
    id: types.identifier,
    organization: types.optional(types.string, ''),
    name: types.string,
    date: types.optional(momentType, moment),
    starred: types.optional(types.boolean, false),
    members: types.array(types.string),
    learnings: types.optional(LearningsModel, () => LearningsModel.create()),
    ideas: types.optional(IdeasModel, () => IdeasModel.create()),
    context: types.optional(ContextModel, () => ContextModel.create()),
    files: types.optional(FilesModel, () => FilesModel.create()),
    tasks: types.optional(ActionsModel, () => ActionsModel.create()),
    lastUpdate: types.number,
  })
  .actions(self => ({
    setName: flow(function* setName(name) {
      yield ProjectsService.setName(self, name);
      self.name = name;
    }),

    addMember: flow(function* addMember(memberId) {
      if (!self.members.includes(memberId)) {
        yield ProjectsService.addMember(self, memberId);
        if (!self.members.includes(memberId)) {
          self.members.push(memberId);
        }
      }
    }),

    removeMember: flow(function* removeMember(memberId) {
      if (self.members.includes(memberId)) {
        yield ProjectsService.removeMember(self, memberId);
        const memberIndex = self.members.indexOf(memberId);
        if (memberIndex > -1) {
          self.members.splice(memberIndex, 1);
        }
      }
    }),

    checkAlreadyProcessedEvent(event, eventHandler) {
      if (event.event.sequenceNr > self.lastUpdate) {
        const result = eventHandler(event);
        if (result) {
          self.lastUpdate = event.event.sequenceNr;
        }
        return result;
      } else {
        return false;
      }
    },

    handleEvent(event) {
      switch (event.eventType) {
        case 'ProjectNameUpdated':
          return self.checkAlreadyProcessedEvent(event, event => {
            self.name = event.event.name;
            return true;
          });
        case 'ProjectContextUpdated':
          return self.checkAlreadyProcessedEvent(event, self.context.handleEvent);
        case 'ProjectMemberAdded':
          return self.checkAlreadyProcessedEvent(event, event => {
            const userId = event.event.userId;
            if (self.members.includes(userId)) {
              return false;
            } else {
              self.members.push(userId);
              return true;
            }
          });
        case 'ProjectMemberRemoved':
          return self.checkAlreadyProcessedEvent(event, event => {
            const userId = event.event.userId;
            const memberIndex = self.members.indexOf(userId);
            if (memberIndex > -1) {
              self.members.splice(memberIndex, 1);
              return true;
            } else {
              return false;
            }
          });
        case 'ProjectFilesAdded':
          return self.checkAlreadyProcessedEvent(event, self.files.handleEvent);
        case 'ProjectFileMarkedPublic':
          return self.checkAlreadyProcessedEvent(event, self.files.handleEvent);
        case 'ProjectFileCallForUpdated':
          return self.checkAlreadyProcessedEvent(event, self.files.handleEvent);
        case 'ProjectFileRemoved':
          return self.checkAlreadyProcessedEvent(event, self.files.handleEvent);
        case 'ProjectLearningAdded':
          return self.checkAlreadyProcessedEvent(event, () => true);
        case 'ProjectLearningRemoved':
          return self.checkAlreadyProcessedEvent(event, () => true);
        case 'ProjectIdeaAdded':
          return self.checkAlreadyProcessedEvent(event, () => true);
        case 'ProjectIdeaRemoved':
          return self.checkAlreadyProcessedEvent(event, () => true);
        case 'ProjectActionAdded':
          return self.checkAlreadyProcessedEvent(event, () => true);
        case 'ProjectActionRemoved':
          return self.checkAlreadyProcessedEvent(event, () => true);
        default:
          if (event.ideaId) {
            return self.ideas.handleEvent(event);
          } else if (event.actionId) {
            return self.tasks.handleEvent(event);
          } else if (event.learningId) {
            return self.learnings.handleEvent(event);
          } else {
            console.warn('ProjectModel does not know how to handle event ', event);
            return false;
          }
      }
    },
  }))
  .views(self => ({
    get state() {
      return self.ideas.state;
    },

    get membersList() {
      const members = self.members.map(memberId => userStore.colleaguesMap[memberId]);
      return members.filter(member => member !== undefined);
    },

    get tasksList() {
      let tasksList = {};

      const actionsList = self.tasks.actionsList;
      tasksList = concatObjects(actionsList);

      const experimentList = self.ideas.experimentsList;
      tasksList = concatObjects(experimentList, tasksList);

      return tasksList;
    },

    getExperimentById(experimentId) {
      return self.ideas.getExperimentById(experimentId);
    },
    getActionById(actionId) {
      return self.tasks.getActionById(actionId);
    },

    get nbExperiments() {
      return self.ideas.nbExperiments;
    },

    get nbIdeas() {
      return self.ideas.nbIdeas;
    },

    get nbSolutions() {
      return self.ideas.nbSolutions;
    },

    getIdeaById(ideaId) {
      return self.ideas.getIdeaById(ideaId);
    },

    isMember(userId) {
      return self.members.includes(userId);
    },

    contains(text) {
      return caseInsensitiveSearch(self.name, text) || self.context.contains(text);
    },

    searchIdeas(text, statuses) {
      return self.ideas.searchIdeas(text, statuses);
    },

    searchExperiments(text) {
      return self.ideas.searchExperiments(text);
    },

    get nbLearnings() {
      return self.learnings.nbLearnings + self.ideas.nbLearnings;
    },

    searchLearnings(text) {
      const projectLearnings = self.learnings.searchLearnings(text);
      const experimentLearnings = self.ideas.searchLearnings(text);
      const tasksLearnings = self.tasks.searchLearnings(text);
      return projectLearnings.concat(experimentLearnings).concat(tasksLearnings);
    },

    searchActions(text) {
      return self.tasks.searchActions(text);
    },

    searchFiles(text) {
      const projectFiles = self.files.searchFiles(text);
      const experimentFiles = self.ideas.searchFiles(text);
      const tasksFiles = self.tasks.searchFiles(text);
      return projectFiles.concat(experimentFiles).concat(tasksFiles);
    },

    getFileFromId(fileId) {
      return self.files.getFileFromId(fileId) || self.ideas.getFileFromId(fileId) || self.tasks.getFileFromId(fileId);
    },

    get isVisible() {
      return userStore.currentUser.isAdmin || userStore.currentUser.organizations.includes(self.organization);
    },
    get isWritable() {
      return userStore.currentUser.isAdmin || self.isMember(userStore.currentUser.id);
    },
    get organizationModel() {
      return OrganizationsStore.getOrganizationById(self.organization);
    },

    get experimentsLearnings() {
      return self.ideas.allLearnings;
    },

    get tasksLearnings() {
      return self.tasks.allLearnings;
    },

    get experimentsFiles() {
      return self.ideas.allFiles;
    },

    get tasksFiles() {
      return self.tasks.allFiles;
    },
  }));

export default ProjectModel;
