import { flow, getParent, types } from 'mobx-state-tree';
import { caseInsensitiveSearch } from '../../../../../components/lib/SearchUtil';
import experimentsService from '../../ExperimentsService';

const ExperimentResultsModel = types
  .model('ExperimentResults', {
    text: types.optional(types.string, ''),
    nextSteps: types.optional(types.string, ''),
  })
  .views(self => ({
    isFilled() {
      return self.text.trim() !== '';
    },
    get experiment() {
      return getParent(self);
    },
    contains(text) {
      return caseInsensitiveSearch(self.text, text) || caseInsensitiveSearch(self.nextSteps, text);
    },
  }))
  .actions(self => ({
    set: flow(function* set(properties) {
      yield experimentsService.setResults(self.experiment, { ...self, ...properties });
      Object.assign(self, properties);
    }),

    handleEvent(event) {
      if (event && event.event && event.eventType === 'ExperimentResultsUpdated') {
        Object.assign(self, event.event.results);
        return true;
      }
      console.warn('ExperimentResultsModel does not know how to handle event ', event);
      return false;
    },
  }));

export default ExperimentResultsModel;
