import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';
import TaskStatusLabel from '../board/tasks/TaskStatusLabel';

class SearchAction extends React.Component {
  static propTypes = {
    nestingLevel: PropTypes.number,
    action: PropTypes.shape({
      id: PropTypes.string.isRequired,
      projectId: PropTypes.string.isRequired,
      text: PropTypes.string,
    }).isRequired,
  };

  render() {
    const action = this.props.action;
    const link = '/projects/' + action.projectId + '/actions/' + action.id;
    return (
      <div className="searchPanel-line" style={{ paddingLeft: 20 + this.props.nestingLevel * 30 }}>
        <i className="searchPanel-line-icon ic-assignment" />
        <div className="searchPanel-line-text">{action.title}</div>
        <TaskStatusLabel value={action.status} />
        <Link title={i18n.t('see.action')} className="btn btn_primary btn_medium searchPanel-line-btn" to={link}>
          <i className="ic-arrow-right" />
        </Link>
      </div>
    );
  }
}

export default observer(SearchAction);
