import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import withFeedback from '../../global/feedback/withFeedback';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import TodoBoardColumn from './TodoBoardColumn';
import TaskStatusEnum from '../../stores/projects/models/tasks/TaskStatusEnum';
import { getActionWorkflowIcon } from '../../global/utils/IconUtils';
import './../columns/css/BoardColumns.css';

class TodoBoardColumns extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
    project: PropTypes.shape({
      tasks: PropTypes.shape({
        addAction: PropTypes.func.isRequired,
      }).isRequired,
      getActionById: PropTypes.func.isRequired,
      getExperimentById: PropTypes.func.isRequired,
    }),
  };

  static COLUMNS = [
    {
      id: TaskStatusEnum.enum.BACKLOG,
      name: i18n.t('task.status.' + TaskStatusEnum.enum.BACKLOG),
      iconName: getActionWorkflowIcon(TaskStatusEnum.enum.BACKLOG),
    },
    {
      id: TaskStatusEnum.enum.TODO,
      name: i18n.t('task.status.' + TaskStatusEnum.enum.TODO),
      iconName: getActionWorkflowIcon(TaskStatusEnum.enum.TODO),
    },
    {
      id: TaskStatusEnum.enum.INPROGRESS,
      name: i18n.t('task.status.' + TaskStatusEnum.enum.INPROGRESS),
      iconName: getActionWorkflowIcon(TaskStatusEnum.enum.INPROGRESS),
    },
    {
      id: TaskStatusEnum.enum.DONE,
      name: i18n.t('task.status.' + TaskStatusEnum.enum.DONE),
      iconName: getActionWorkflowIcon(TaskStatusEnum.enum.DONE),
    },
  ];

  handleDragEnd = result => {
    const { destination } = result;
    if (!destination) {
      // dropped outside the list
      return;
    }

    const experiment = this.props.project.getExperimentById(result.draggableId);
    if (experiment) {
      experiment.moveTask(destination.droppableId).catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
    }
    const action = this.props.project.getActionById(result.draggableId);
    if (action) {
      action.moveTask(destination.droppableId).catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
    }
  };

  renderColumn = column => <TodoBoardColumn project={this.props.project} column={column} key={column.id} />;

  render() {
    return (
      <div className="boardColumns">
        <DragDropContext onDragEnd={this.handleDragEnd}>
          {TodoBoardColumns.COLUMNS.map(this.renderColumn)}
        </DragDropContext>
      </div>
    );
  }
}

export default withFeedback(TodoBoardColumns);
