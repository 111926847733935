/* global window */

import React from 'react';
import { observer } from 'mobx-react';
import i18n from '../../i18n';
import Coverflow from '../global/coverflow/Coverflow';
import eventsStore from '../stores/events/EventsStore';

class Carousel extends React.Component {
  state = {
    active: -1,
  };

  goToNextImage = () => {
    this.setState({ active: (this.state.active + 1) % eventsStore.latestImages.length });
  };

  scheduleScroll = () => {
    this.timer = window.setInterval(this.goToNextImage, 5000);
  };

  render() {
    const images = eventsStore.latestImages;
    if (images.length > 0) {
      if (!this.timer) {
        this.scheduleScroll();
      }
      if (this.state.active < 0) {
        window.setTimeout(() => {
          this.setState({ active: 0 });
        }, 200);
      }
    }

    return (
      <div>
        <div className="card dashboardPanel-block-content">
          <h3>{i18n.t('dashboard.recentlyUploaded')}</h3>
          <div className="carousel">
            <Coverflow
              width="auto"
              height="300"
              displayQuantityOfSide={1}
              clickable={true}
              enableScroll={true}
              infiniteScroll={true}
              active={this.state.active}
            >
              {images.map((file, idx) => (
                <img
                  key={'file' + idx}
                  src={file.url}
                  data-action={file.url}
                  alt={file.name}
                  style={{
                    display: 'block',
                    width: '100%',
                  }}
                />
              ))}
            </Coverflow>
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    if (this.timer) {
      window.clearInterval(this.timer);
      this.timer = null;
    }
  }
}

export default observer(Carousel);
