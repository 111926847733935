/*global window */

import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import i18n from '../../i18n';
import DeleteButton from '../global/elems/DeleteButton';
import { getColorForProjectStateIcon, getProjectStateIcon } from '../global/utils/IconUtils';
import modelOf from '../../components/propTypes/modelOf';
import projectsStore from '../stores/projects/ProjectsStore';
import ProjectModel from '../stores/projects/models/ProjectModel';
import UserStore from '../stores/user/UserStore';
import './css/BoardCard.css';

class BoardCard extends React.Component {
  static propTypes = {
    project: modelOf(ProjectModel).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }),
  };

  canDelete = !!UserStore.currentUser && UserStore.currentUser.hasOneOrManyAdminRoles;

  onClick = () =>
    this.props.history.push(
      '/projects/' + this.props.project.id + (this.props.project.context.isFilled() ? '' : '/business-challenge')
    );

  onDelete = event => {
    event.stopPropagation();
    if (window.confirm(i18n.t('projects.confirmDelete', { projectName: this.props.project.name }))) {
      projectsStore.deleteProject(this.props.project);
    }
  };

  render() {
    const projectState = this.props.project.state;
    return (
      <div className="boardCard-wrapper">
        <div className="boardCard card" onClick={this.onClick}>
          <DeleteButton
            onClick={this.onDelete}
            title={i18n.t('projects.delete')}
            className="boardCard-btnTrash"
            canDelete={this.canDelete}
          />
          <div className="boardCard-title">{this.props.project.name}</div>
          <div className="boardCard-subtitle">{this.props.project.date.format('MM-YYYY')}</div>
          <div className="boardCard-state" title={i18n.t('projects.' + projectState)}>
            <i
              className={'boardCard-state-icon ' + getProjectStateIcon(projectState)}
              style={{ color: getColorForProjectStateIcon(projectState) }}
            />
          </div>
          <div className="boardCard-infos">
            <div className="boardCard-info" title={i18n.t('numberOf.members')}>
              <i className="boardCard-info-icon ic-users" />
              {this.props.project.members.length}
            </div>
            <div className="boardCard-info" title={i18n.t('numberOf.ideas')}>
              <i className="boardCard-info-icon ic-lightbulb_outline" />
              {this.props.project.nbIdeas}
            </div>
            <div className="boardCard-info" title={i18n.t('numberOf.experiments')}>
              <i className="boardCard-info-icon ic-rocket" />
              {this.props.project.nbExperiments}
            </div>
            <div className="boardCard-info" title={i18n.t('numberOf.solutions')}>
              <i className="boardCard-info-icon ic-check-circle" />
              {this.props.project.nbSolutions}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(observer(BoardCard));
