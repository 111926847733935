import { destroy, detach, flow, getParent, types } from 'mobx-state-tree';
import ideaStateEnum from './IdeaStateEnum';
import IdeaModel from './IdeaModel';
import IdeasService from '../../IdeasService';
import userStore from '../../../user/UserStore';

const IdeasModel = types
  .model(
    'Ideas',
    ideaStateEnum.keys.reduce(
      (model, ideaState) =>
        Object.assign(model, {
          [ideaState]: types.array(IdeaModel),
        }),
      {}
    )
  )
  .views(self => ({
    get projectId() {
      return getParent(self, 3).id;
    },

    get nbIdeas() {
      return ideaStateEnum.keys.reduce((nbIdeas, ideaState) => nbIdeas + self[ideaState].length, 0);
    },

    get state() {
      for (let i = ideaStateEnum.keys.length - 1; i >= 0; i--) {
        if (self[ideaStateEnum.keys[i]].length > 0) {
          return ideaStateEnum.keys[i];
        }
      }
      return ideaStateEnum.keys[0];
    },

    get nbLearnings() {
      return ideaStateEnum.keys.reduce(
        (nbLearnings, ideaState) =>
          nbLearnings + self[ideaState].reduce((nbLearnings, idea) => nbLearnings + idea.nbLearnings, 0),
        0
      );
    },

    get nbExperiments() {
      return ideaStateEnum.keys.reduce(
        (nbExperiments, ideaState) =>
          nbExperiments + self[ideaState].reduce((nbExperiments, idea) => nbExperiments + idea.experiments.length, 0),
        0
      );
    },
    get experimentsList() {
      let experiments = {};
      ideaStateEnum.keys.filter(ideaState => self[ideaState].length > 0).forEach(ideaState =>
        self[ideaState].filter(idea => idea.experiments.length > 0).forEach(idea =>
          idea.experiments.forEach(function(experiment) {
            if (experiments[experiment.status]) {
              experiments[experiment.status].push(experiment);
            } else {
              experiments[experiment.status] = [experiment];
            }
          })
        )
      );
      return experiments;
    },

    get allLearnings() {
      return ideaStateEnum.keys.reduce(
        (learnings, ideaState) =>
          learnings.concat(self[ideaState].reduce((learnings, idea) => learnings.concat(idea.allLearnings), [])),
        []
      );
    },

    get allFiles() {
      return ideaStateEnum.keys.reduce(
        (files, ideaState) => files.concat(self[ideaState].reduce((files, idea) => files.concat(idea.allFiles), [])),
        []
      );
    },

    getExperimentById(experimentId) {
      let experiment;
      ideaStateEnum.keys.filter(ideaState => self[ideaState].length > 0).some(ideaState =>
        self[ideaState].filter(idea => idea.experiments.length > 0).some(function(idea) {
          experiment = idea.experiments.find(experiment => experiment.id === experimentId);
          return !!experiment;
        })
      );
      return experiment;
    },

    get nbSolutions() {
      return ideaStateEnum.keys.reduce(
        (nbSolutions, ideaState) =>
          nbSolutions + self[ideaState].reduce((nbSolutions, idea) => nbSolutions + idea.nbSolutions, 0),
        0
      );
    },

    searchIdeas(text, statuses) {
      const statusesToSearch = statuses.length === 0 ? ideaStateEnum.keys : statuses;
      return ideaStateEnum.keys
        .filter(state => statusesToSearch.includes(state))
        .reduce((ideas, ideaState) => ideas.concat(self[ideaState].filter(idea => idea.contains(text))), []);
    },

    searchExperiments(text) {
      return ideaStateEnum.keys.reduce(
        (experiments, ideaState) =>
          experiments.concat(
            self[ideaState].reduce((experiments, idea) => experiments.concat(idea.searchExperiments(text)), [])
          ),
        []
      );
    },

    searchLearnings(text) {
      return ideaStateEnum.keys.reduce(
        (learnings, ideaState) =>
          learnings.concat(
            self[ideaState].reduce((learnings, idea) => learnings.concat(idea.searchLearnings(text)), [])
          ),
        []
      );
    },

    searchFiles(text) {
      return ideaStateEnum.keys.reduce(
        (files, ideaState) =>
          files.concat(self[ideaState].reduce((files, idea) => files.concat(idea.searchFiles(text)), [])),
        []
      );
    },

    getFileFromId(fileId) {
      let result;
      ideaStateEnum.keys.forEach(ideaState => {
        self[ideaState].forEach(idea => {
          result = result || idea.getFileFromId(fileId);
        });
      });
      return result;
    },

    getIdeaById(ideaId) {
      return ideaStateEnum.keys.reduce(
        (idea, ideaState) => idea || self[ideaState].find(idea => idea.id === ideaId),
        undefined
      );
    },
  }))
  .actions(self => {
    return {
      _addIdea(idea, ideaState) {
        if (self.getIdeaById(idea.id)) {
          return false;
        }
        self[ideaState].unshift(idea);
        return true;
      },

      addIdea: flow(function* addIdea(projectId, idea) {
        const organization = userStore.currentUser.currentOrganization;
        const { data } = yield IdeasService.addIdea(projectId, {
          organization,
          ...idea,
        });
        if (data) {
          self._addIdea(data, ideaStateEnum.enum.IDEAS);
        }
      }),

      deleteIdea: flow(function* deleteIdea(idea) {
        const ideaId = idea.id;
        yield IdeasService.deleteIdea(idea);
        const ideaToRemove = self.getIdeaById(ideaId);
        if (ideaToRemove) {
          destroy(ideaToRemove);
        }
      }),

      moveIdea: flow(function* moveIdea(ideaId, sourceState, destinationState, destinationIndex) {
        // Avoid the lag when we move a card between column with optimist method
        if (!sourceState || !destinationState) {
          console.warn(
            `Source (${sourceState}) or destination (${destinationState}) state are not defined, no possible move for the ideaId "${ideaId}"`
          );
          return;
        }
        const sourceIndex = self[sourceState].findIndex(idea => idea.id === ideaId);
        try {
          if (sourceIndex >= 0 && (sourceState !== destinationState || sourceIndex !== destinationIndex)) {
            const idea = self[sourceState][sourceIndex];
            detach(idea);
            self[destinationState].splice(destinationIndex, 0, idea);
            yield IdeasService.setStatus(idea, destinationState);
            idea._setStatus(destinationState);
          }
        } catch (error) {
          const idea = self[destinationState][destinationIndex];
          detach(idea);
          self[sourceState].splice(sourceIndex, 0, idea);
          throw new Error(error);
        }
      }),

      _updateIdeaStatus(event) {
        var ideaId = event.event.ideaId;
        var ideaMoved = self.getIdeaById(ideaId);
        if (ideaMoved && event.event.sequenceNr > ideaMoved.lastUpdate) {
          const sourceIndex = self[ideaMoved.status].findIndex(idea => idea.id === ideaId);
          if (sourceIndex >= 0 && ideaMoved.status !== event.event.status) {
            const idea = self[ideaMoved.status][sourceIndex];
            if (idea.handleEvent(event)) {
              detach(idea);
              self[event.event.status].splice(0, 0, idea);
              return true;
            }
            return false;
          }
          return false;
        }
        console.warn(`Received IdeaStatusUpdated but idea with id ${event.event.ideaId} does not exists`);
        return false;
      },

      handleEvent(event) {
        switch (event.eventType) {
          case 'IdeaCreated':
            return self._addIdea(event.event.idea, event.event.idea.status);
          case 'IdeaDeleted':
            var ideaToRemove = self.getIdeaById(event.event.ideaId);
            if (ideaToRemove && event.event.sequenceNr > ideaToRemove.lastUpdate) {
              try {
                destroy(ideaToRemove);
                return true;
              } catch (err) {
                return false;
              }
            }
            return false;
          case 'IdeaStatusUpdated':
            return self._updateIdeaStatus(event);
          default:
            return self.handleDefaultEvent(event);
        }
      },

      handleDefaultEvent(event) {
        const idea = self.getIdeaById(event.ideaId);
        if (idea) {
          return idea.handleEvent(event);
        } else {
          console.warn(`Received event ${event.eventType} for idea ${event.event.ideaId} but this idea is unknown`);
          return false;
        }
      },
    };
  });

export default IdeasModel;
