import { flow, getParent, types } from 'mobx-state-tree';
import { caseInsensitiveSearch } from '../../../../../components/lib/SearchUtil';
import learningTypeEnum from './LearningTypeEnum';
import learningStatusEnum from './LearningStatusEnum';
import LearningsService from '../../LearningsService';
import userStore from '../../../user/UserStore';

const LearningModel = types
  .model('Learning', {
    id: types.maybe(types.identifier),
    type: learningTypeEnum.type,
    status: types.optional(learningStatusEnum.type, learningStatusEnum.enum.ASSUMPTION),
    text: types.string,
    link: types.maybe(types.string),
    lastUpdate: types.number,
  })
  .views(self => ({
    hasLink() {
      return !!self.link && self.link.trim() !== '';
    },

    contains(text) {
      return caseInsensitiveSearch(self.text, text);
    },

    get project() {
      try {
        let maybeProject = getParent(self, 3);
        if (maybeProject.$treenode.type.name === 'Project') {
          return maybeProject;
        }
        maybeProject = getParent(self, 5);
        if (maybeProject.$treenode.type.name === 'Project') {
          return maybeProject;
        }
      } catch (err) {
        console.warn(err);
      }
      return getParent(self, 7);
    },

    get projectId() {
      return self.project.id;
    },

    get idea() {
      const maybeIdea = getParent(self, 4);
      return maybeIdea.$treenode.type.name === 'Idea' ? maybeIdea : undefined;
    },

    get ideaId() {
      return self.idea ? self.idea.id : undefined;
    },

    get experiment() {
      const maybeExperiment = getParent(self, 2);
      return maybeExperiment.$treenode.type.name === 'Experiment' ? maybeExperiment : undefined;
    },

    get experimentId() {
      return self.experiment ? self.experiment.id : undefined;
    },

    get action() {
      const maybeAction = getParent(self, 2);
      return maybeAction.$treenode.type.name === 'Action' ? maybeAction : undefined;
    },

    get actionId() {
      return self.action ? self.action.id : undefined;
    },
  }))
  .actions(self => ({
    setText(text) {
      self.text = text;
    },

    setStatus(status) {
      self.status = status;
    },

    save: flow(function* save(properties) {
      const organization = userStore.currentUser.currentOrganization;
      const updateProperties = {
        organization,
        ...self,
        ...properties,
      };
      if (self.experiment) {
        yield LearningsService.saveExperimentLearning(self, self.experiment, updateProperties);
      } else if (self.action) {
        yield LearningsService.saveActionLearning(self, self.action, updateProperties);
      } else {
        yield LearningsService.saveProjectLearning(self, updateProperties);
      }
      self.setText(properties.text);
      self.setStatus(properties.status);
    }),

    handleEvent(event) {
      if (event && event.event && event.eventType) {
        switch (event.eventType) {
          case 'LearningUpdated':
            if (event.event.sequenceNr > self.lastUpdate) {
              self.text = event.event.text;
              self.status = event.event.status;
              self.lastUpdate = event.event.sequenceNr;
              return true;
            } else {
              return false;
            }
          default:
        }
      }
      console.warn('LearningModel does not know how to handle event ', event);
      return false;
    },
  }));

export default LearningModel;
