import { flow, getParent, types } from 'mobx-state-tree';
import { caseInsensitiveSearch } from '../../../../../components/lib/SearchUtil';
import userStore from '../../../user/UserStore';
import experimentsService from '../../ExperimentsService';
import LearningsService from '../../LearningsService';
import FilesModel from '../files/FilesModel';
import ideaStateEnum from '../ideas/IdeaStateEnum';
import composeTask from '../tasks/composeTask';
import ValidationStatusEnum from '../validation/ValidationStatusEnum';
import ValidationStatusModel from '../validation/ValidationStatusModel';
import ExperimentCardModel from './ExperimentCardModel';
import ExperimentResultsModel from './ExperimentResultsModel';
import ExperimentStateEnum from './ExperimentStateEnum';
import ExperimentSolutionsModel from './ExperimentSolutionsModel';

const ExperimentModel = composeTask(
  'Experiment',
  experimentsService,
  LearningsService.addExperimentLearning,
  LearningsService.deleteExperimentLearning,
  types
    .model({
      state: types.optional(ExperimentStateEnum.type, ExperimentStateEnum.enum.BUILDING),
      title: types.string,
      results: types.optional(ExperimentResultsModel, () => ExperimentResultsModel.create()),
      solutions: types.optional(ExperimentSolutionsModel, () => ExperimentSolutionsModel.create()),
      experimentCard: types.optional(ExperimentCardModel, () => ExperimentCardModel.create()),
      validationStatus: types.map(ValidationStatusModel),
      resultFiles: types.optional(FilesModel, () => FilesModel.create()),
      solutionFiles: types.optional(FilesModel, () => FilesModel.create()),
      lastUpdate: types.number,
    })
    .views(self => ({
      isTestable() {
        return self.state === ExperimentStateEnum.enum.BUILDING && self.experimentCard.isFilled();
      },

      isDecidable() {
        return self.state === ExperimentStateEnum.enum.TESTING && self.results.isFilled();
      },

      contains(text) {
        return (
          caseInsensitiveSearch(self.title, text) ||
          self.results.contains(text) ||
          self.solutions.contains(text) ||
          self.experimentCard.contains(text)
        );
      },

      searchFiles(text) {
        const files = self.files.searchFiles(text);
        const resultFiles = self.resultFiles.searchFiles(text);
        const solutionFiles = self.solutionFiles.searchFiles(text);
        return files.concat(resultFiles).concat(solutionFiles);
      },

      getFileFromId(fileId) {
        return (
          self.files.getFileFromId(fileId) ||
          self.resultFiles.getFileFromId(fileId) ||
          self.solutionFiles.getFileFromId(fileId)
        );
      },

      get idea() {
        return getParent(self, 2);
      },

      get ideaId() {
        return self.idea.id;
      },

      get project() {
        return getParent(self, 5);
      },

      get projectId() {
        return self.project.id;
      },
    }))
    .actions(self => ({
      setTitle: flow(function* setTitle(title) {
        yield experimentsService.setTitle(self, title);
        self.title = title;
      }),

      setState: flow(function* setState(state) {
        const idea = getParent(self, 2);
        const ideaState = idea.state;
        yield experimentsService.setState(self, state);
        self.state = state;
        if (self.state === ExperimentStateEnum.enum.ACCEPTED) {
          const ideas = getParent(self, 4);
          if (ideaState !== ideaStateEnum.enum.SOLVED) {
            ideas.moveIdea(idea.id, ideaState, ideaStateEnum.enum.SOLVED, 0);
          }
        } else if (
          self.state === ExperimentStateEnum.enum.REJECTED ||
          self.state === ExperimentStateEnum.enum.PIVOTED
        ) {
          const ideas = getParent(self, 4);
          const dest =
            self.state === ExperimentStateEnum.enum.PIVOTED ? ideaStateEnum.enum.MVP : ideaStateEnum.enum.IDEAS;
          if (ideaState !== dest && idea.getDecisionReadyExperiments().length === 0) {
            ideas.moveIdea(idea.id, ideaState, dest, 0);
          }
        }
        return self;
      }),

      requestValidation: flow(function* requestValidation(groups) {
        const { data } = yield experimentsService.requestValidation(self, groups);
        data.forEach(group => {
          if (!self.validationStatus.get(group)) {
            self.validationStatus.set(
              group,
              ValidationStatusModel.create({
                status: ValidationStatusEnum.enum.REQUESTED,
                identity: userStore.currentUser.id,
              })
            );
          }
        });
        return data;
      }),

      handleEvent(event) {
        switch (event.eventType) {
          case 'ExperimentStateUpdated':
            return self.checkAlreadyProcessedEvent(event, event => {
              self.state = event.event.state;
              // TODO: Do we need to update state on parent Idea?
              return true;
            });
          case 'ExperimentTitleUpdated':
            return self.checkAlreadyProcessedEvent(event, event => {
              self.title = event.event.title;
              return true;
            });
          case 'ExperimentStatusUpdated':
            return self.checkAlreadyProcessedEvent(event, event => {
              self._setStatus(event.event.status);
              return true;
            });
          case 'ExperimentResultsUpdated':
            return self.checkAlreadyProcessedEvent(event, self.results.handleEvent);
          case 'ExperimentSolutionsUpdated':
            return self.checkAlreadyProcessedEvent(event, self.solutions.handleEvent);
          case 'ExperimentCardUpdated':
            return self.checkAlreadyProcessedEvent(event, self.experimentCard.handleEvent);
          case 'ExperimentValidationRequested':
            return self.checkAlreadyProcessedEvent(event, event => {
              event.event.groups.forEach(group => {
                self.validationStatus.set(
                  group,
                  ValidationStatusModel.create({
                    status: ValidationStatusEnum.REQUESTED,
                    identity: event.event.identity.id,
                  })
                );
              });
            });
          case 'ExperimentValidationApproved':
            return self.checkAlreadyProcessedEvent(event, self.validationStatus.get(event.event.group).handleEvent);
          case 'ExperimentValidationRefused':
            return self.checkAlreadyProcessedEvent(event, self.validationStatus.get(event.event.group).handleEvent);
          case 'ExperimentLearningAdded':
            return self.checkAlreadyProcessedEvent(event, () => true);
          case 'ExperimentLearningRemoved':
            return self.checkAlreadyProcessedEvent(event, () => true);
          case 'LearningCreated':
            return self.handleLearningCreatedEvent(event);
          case 'LearningDeleted':
            return self.handleLearningDeletedEvent(event);
          case 'ExperimentFilesAdded':
            return self.checkAlreadyProcessedEvent(event, self.files.handleEvent);
          case 'ExperimentFileRemoved':
            return self.checkAlreadyProcessedEvent(event, self.files.handleEvent);
          case 'ExperimentResultFilesAdded':
            return self.checkAlreadyProcessedEvent(event, self.resultFiles.handleEvent);
          case 'ExperimentResultFileRemoved':
            return self.checkAlreadyProcessedEvent(event, self.resultFiles.handleEvent);
          case 'ExperimentSolutionFilesAdded':
            return self.checkAlreadyProcessedEvent(event, self.solutionFiles.handleEvent);
          case 'ExperimentSolutionFileRemoved':
            return self.checkAlreadyProcessedEvent(event, self.solutionFiles.handleEvent);
          case 'ExperimentAssigneeAdded':
            return self.handleAssigneeAddedEvent(event);
          case 'ExperimentAssigneeRemoved':
            return self.handleAssigneeRemovedEvent(event);
          default:
            return self.handleDefaultEvent(event);
        }
      },
    }))
);

export default ExperimentModel;
