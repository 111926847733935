import React from 'react';
import scrollToTopOnMount from '../lib/routing/scrollToTopOnMount';
import Status from '../lib/routing/Status';
import styles from './NotFound.css';

const NotFound = () => (
  <Status code={404}>
    <div className={styles.root}>404 Not Found</div>
  </Status>
);

export default scrollToTopOnMount(NotFound);
