import React from 'react';
import './css/Header.css';
import { NavLink } from 'react-router-dom';
import SearchBar from './SearchBar';
import i18n from '../../i18n';
import HeaderUser from './HeaderUser';

class Header extends React.Component {
  render() {
    return (
      <div className="header">
        <i className="ic-logo header-logo" />
        <NavLink
          className="header-item"
          activeClassName="header-item_active"
          to="/"
          exact
          title={i18n.t('dashboard.title')}
        >
          <i className="ic-dashboard3 header-item-icon" />
          <span className="header-item-label">{i18n.t('dashboard.title')}</span>
        </NavLink>
        <NavLink
          activeClassName="header-item_active"
          className="header-item"
          to="/projects"
          exact
          title={i18n.t('projects.title')}
        >
          <i className="ic-grid header-item-icon" />
          <span className="header-item-label">{i18n.t('projects.title')}</span>
        </NavLink>
        <SearchBar />
        <div className="header-spacer" />
        <HeaderUser />
      </div>
    );
  }
}

export default Header;
