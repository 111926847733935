/*global window */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './css/coverflow.css';

let TOUCH = {
  move: false,
  lastX: 0,
  sign: 0,
  lastMove: 0,
};

let TRANSITIONS = ['transitionend', 'oTransitionEnd', 'otransitionend', 'MSTransitionEnd', 'webkitTransitionEnd'];

let HandleAnimationState = function() {
  this._removePointerEvents();
};

class Coverflow extends Component {
  /**
   * Life cycle events
   */
  constructor(props) {
    super(props);
    this.state = {
      current: this._center(),
      move: 0,
      width: this.props.width || 'auto',
      height: this.props.height || 'auto',
    };
    this.updateDimensionsBind = this.updateDimensions.bind(this); //bind function once
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    displayQuantityOfSide: PropTypes.number.isRequired,
    navigation: PropTypes.bool,
    enableHeading: PropTypes.bool,
    enableScroll: PropTypes.bool,
    clickable: PropTypes.bool,
    currentFigureScale: PropTypes.number,
    otherFigureScale: PropTypes.number,
    active: PropTypes.number,
    media: PropTypes.object,
    infiniteScroll: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
  };

  static defaultProps = {
    navigation: false,
    enableHeading: true,
    enableScroll: true,
    clickable: true,
    currentFigureScale: 1.5,
    otherFigureScale: 0.8,
    media: {},
    infiniteScroll: false,
  };

  figures = [];

  componentDidMount() {
    this.updateDimensions();
    let length = React.Children.count(this.props.children);

    TRANSITIONS.forEach(event => {
      for (let i = 0; i < length; i++) {
        this.figures[i].addEventListener(event, HandleAnimationState.bind(this));
      }
    });

    const eventListener = window && window.addEventListener;

    if (eventListener) {
      window.addEventListener('resize', this.updateDimensionsBind);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.active !== nextProps.active) {
      this.updateDimensions(nextProps.active);
    }
  }

  componentWillUnmount() {
    let length = React.Children.count(this.props.children);

    TRANSITIONS.forEach(event => {
      for (let i = 0; i < length; i++) {
        this.figures[i].removeEventListener(event, HandleAnimationState.bind(this));
      }
    });

    const removeListener = window && window.removeEventListener;

    if (removeListener) {
      window.removeEventListener('resize', this.updateDimensionsBind);
    }
  }

  updateDimensions(active) {
    const { displayQuantityOfSide } = this.props;
    let length = React.Children.count(this.props.children);
    let center = this._center();
    let state = {
      width: this.node.offsetWidth,
      height: this.node.offsetHeight,
    };
    let baseWidth = state.width / (displayQuantityOfSide * 2 + 1);
    let activeImg = typeof active === 'number' ? active : this.props.active;
    if (typeof activeImg === 'number' && ~~activeImg < length) {
      activeImg = ~~activeImg;
      let move = 0;
      move = baseWidth * (center - activeImg);

      state = Object.assign({}, state, {
        current: active,
        move,
      });
    }
    this.setState(state);
  }

  render() {
    const { enableScroll, navigation, infiniteScroll, media } = this.props;
    const { width, height, current } = this.state;
    let renderPrevBtn = infiniteScroll ? true : current > 0;
    let renderNextBtn = infiniteScroll ? true : current < this.props.children.length - 1;
    return (
      <div
        ref={node => (this.node = node)}
        className="coverflow-container"
        style={Object.keys(media).length !== 0 ? media : { width: `${width}px`, height: `${height}px` }}
        onWheel={enableScroll ? this._handleWheel.bind(this) : null}
        onTouchStart={this._handleTouchStart.bind(this)}
        onTouchMove={this._handleTouchMove.bind(this)}
        onKeyDown={this._keyDown.bind(this)}
        tabIndex="-1"
      >
        <div className="coverflow">
          <div className="preloader" />
          <div className="stage" ref={stage => (this.stage = stage)}>
            {this._renderFigureNodes()}
          </div>
          {navigation && (
            <div className="actions">
              {renderPrevBtn && (
                <button type="button" className="button" onClick={() => this._handlePrevFigure()}>
                  Previous
                </button>
              )}
              {renderNextBtn && (
                <button type="button" className="button" onClick={() => this._handleNextFigure()}>
                  Next
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  /**
   * Private methods
   */
  _center() {
    let length = React.Children.count(this.props.children);
    return Math.floor(length / 2);
  }

  _keyDown(e) {
    if (e.keyCode === 37) {
      this._handlePrevFigure();
    } else if (e.keyCode === 39) {
      this._handleNextFigure();
    }
  }

  _handleFigureStyle(index, current) {
    const { displayQuantityOfSide } = this.props;
    const { width } = this.state;
    let style = {};
    let baseWidth = width / (displayQuantityOfSide * 2 + 1);
    let length = React.Children.count(this.props.children);
    let offset = length % 2 === 0 ? -width / 10 : 0;
    // Handle opacity
    let depth = displayQuantityOfSide - Math.abs(current - index);
    let opacity = depth === 1 ? 0.95 : 0.5;
    opacity = depth === 2 ? 0.92 : opacity;
    opacity = depth === 3 ? 0.9 : opacity;
    opacity = current === index ? 1 : opacity;
    // Handle translateX
    if (index === current) {
      style['width'] = `${baseWidth}px`;
      style['transform'] = `translateX(${this.state.move + offset}px)  scale(${this.props.currentFigureScale}`;
      style['zIndex'] = `${10 - depth}`;
      style['opacity'] = opacity;
    } else if (index < current) {
      // Left side
      style['width'] = `${baseWidth}px`;
      style['transform'] = `translateX(${this.state.move + offset}px) rotateY(40deg) scale(${
        this.props.otherFigureScale
      }`;
      style['zIndex'] = `${10 - depth}`;
      style['opacity'] = opacity;
    } else if (index > current) {
      // Right side
      style['width'] = `${baseWidth}px`;
      style['transform'] = ` translateX(${this.state.move + offset}px) rotateY(-40deg) scale(${
        this.props.otherFigureScale
      })`;
      style['zIndex'] = `${10 - depth}`;
      style['opacity'] = opacity;
    }
    return style;
  }

  _handleFigureClick = (index, action, e) => {
    if (!this.props.clickable) {
      e.preventDefault();
      return;
    }

    this.stage.style['pointerEvents'] = 'none';
    if (this.state.current === index) {
      // If on the active figure
      if (typeof action === 'string') {
        // If action is a URL (string), follow the link
        window.open(action, '_blank');
      }

      this._removePointerEvents();
    } else {
      // Move to the selected figure
      e.preventDefault();
      const { displayQuantityOfSide } = this.props;
      const { width } = this.state;
      let baseWidth = width / (displayQuantityOfSide * 2 + 1);
      let distance = this._center() - index;
      let move = distance * baseWidth;
      this.setState({ current: index, move });
      this._removePointerEvents();
    }
  };

  _renderFigureNodes = () => {
    const { enableHeading } = this.props;
    const { current } = this.state;
    return React.Children.map(this.props.children, (child, index) => {
      let figureElement = React.cloneElement(child, { className: 'cover' });
      let style = this._handleFigureStyle(index, current);
      return (
        <figure
          className="figure"
          key={index}
          onClick={e => this._handleFigureClick(index, figureElement.props['data-action'], e)}
          style={style}
          ref={figure => (this.figures[index] = figure)}
        >
          {figureElement}
          {enableHeading && <div className="text">{figureElement.props.alt}</div>}
        </figure>
      );
    });
  };

  _removePointerEvents() {
    this.stage.style['pointerEvents'] = 'auto';
  }

  _hasPrevFigure = () => {
    return this.state.current - 1 >= 0;
  };

  _hasNextFigure = () => {
    return this.state.current + 1 < this.props.children.length;
  };

  _handlePrevFigure = () => {
    const { displayQuantityOfSide, infiniteScroll } = this.props;
    const { width } = this.state;
    let { current } = this.state;
    let baseWidth = width / (displayQuantityOfSide * 2 + 1);
    let distance = this._center() - (current - 1 < 0 ? this.props.children.length - 1 : current - 1);
    let move = distance * baseWidth;

    if (current - 1 >= 0) {
      this.setState({ current: current - 1, move });
      TOUCH.lastMove = move;
    }
    if (current - 1 < 0 && infiniteScroll) {
      this.setState({ current: this.props.children.length - 1, move });
      TOUCH.lastMove = move;
    }
  };

  _handleNextFigure = () => {
    const { displayQuantityOfSide, infiniteScroll } = this.props;
    const { width } = this.state;
    let { current } = this.state;
    let baseWidth = width / (displayQuantityOfSide * 2 + 1);
    let distance = this._center() - (current + 1 >= this.props.children.length ? 0 : current + 1);
    let move = distance * baseWidth;

    if (current + 1 < this.props.children.length) {
      this.setState({ current: current + 1, move });
      TOUCH.lastMove = move;
    }
    if (current + 1 >= this.props.children.length && infiniteScroll) {
      this.setState({ current: 0, move });
      TOUCH.lastMove = move;
    }
  };

  _handleWheel(e) {
    let delta;
    if (Math.abs(e.deltaY) === 125) {
      delta = e.deltaY * -120;
    } else if (e.deltaY < 0) {
      delta = -600000;
    } else {
      delta = 600000;
    }
    let count = Math.ceil(Math.abs(delta) / 120);

    if (count > 0) {
      const sign = Math.abs(delta) / delta;
      let func = null;

      if (sign > 0 && this._hasPrevFigure()) {
        e.preventDefault();
        func = this._handlePrevFigure();
      } else if (sign < 0 && this._hasNextFigure()) {
        e.preventDefault();
        func = this._handleNextFigure();
      }

      if (typeof func === 'function') {
        for (let i = 0; i < count; i++) {
          func();
        }
      }
    }
  }

  _handleTouchStart(e) {
    TOUCH.lastX = e.nativeEvent.touches[0].clientX;
    TOUCH.lastMove = this.state.move;
  }

  _handleTouchMove(e) {
    e.preventDefault();
    const { displayQuantityOfSide } = this.props;
    const { width } = this.state;

    let clientX = e.nativeEvent.touches[0].clientX;
    let lastX = TOUCH.lastX;
    let baseWidth = width / (displayQuantityOfSide * 2 + 1);
    let move = clientX - lastX;
    let totalMove = TOUCH.lastMove - move;
    let sign = Math.abs(move) / move;

    if (Math.abs(totalMove) >= baseWidth) {
      let fn = null;
      if (sign > 0) {
        fn = this._handlePrevFigure();
      } else if (sign < 0) {
        fn = this._handleNextFigure();
      }
      if (typeof fn === 'function') {
        fn();
      }
    }
  }
}

Coverflow.displayName = 'Coverflow';

export default Coverflow;
