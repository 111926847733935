import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from '../../../i18n';
import withFeedback from '../../global/feedback/withFeedback';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import UserStore from '../../stores/user/UserStore';
import Files from '../files/Files';

class Solutions extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    projectId: PropTypes.string,
    project: PropTypes.shape({
      isMember: PropTypes.func.isRequired,
    }),
    ideaId: PropTypes.string,
    experiment: PropTypes.shape({
      id: PropTypes.string,
      solutions: PropTypes.shape({
        text: PropTypes.string,
        set: PropTypes.func.isRequired,
      }),
      solutionFiles: PropTypes.shape({
        createExperimentSolutionFiles: PropTypes.func.isRequired,
        deleteExperimentSolutionFile: PropTypes.func.isRequired,
      }),
    }),
  };

  state = {
    solutionsValue:
      this.props.experiment && this.props.experiment.solutions ? this.props.experiment.solutions.text : '',
  };

  canEdit =
    !!UserStore.currentUser &&
    (UserStore.currentUser.hasOneOrManyAdminRoles || this.props.project.isMember(UserStore.currentUser.id));

  handleChange = event => this.setState({ solutionsValue: event.target.value });

  handleBlur = event => {
    if (!this.canEdit) {
      return null;
    }
    if (this.props.experiment.solutions.text !== event.target.value) {
      return this.props.experiment.solutions.set({ text: event.target.value }).catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
    } else {
      return null;
    }
  };

  createFiles = files => {
    return this.props.experiment.solutionFiles.createExperimentSolutionFiles(
      this.props.projectId,
      this.props.ideaId,
      this.props.experiment.id,
      files
    );
  };

  deleteFile = file => {
    return this.props.experiment.solutionFiles
      .deleteExperimentSolutionFile(this.props.projectId, this.props.ideaId, this.props.experiment.id, file)
      .catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
  };

  render() {
    return (
      <div>
        <h3>{i18n.t('solutions.title')}</h3>
        <textarea
          rows={10}
          value={this.state.solutionsValue}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          placeholder={this.props.placeholder}
          readOnly={!this.canEdit}
        />
        <Files
          files={this.props.experiment.solutionFiles}
          project={this.props.project}
          createFiles={this.createFiles}
          deleteFile={this.deleteFile}
        />
      </div>
    );
  }
}

export default withFeedback(observer(Solutions));
