import { createEnhancedEnum } from '../../../global/utils/EnumBuilder';

const USER_STATES = {
  Disabled: -1,
  NotValidated: 0,
  ValidIdentity: 1,
  ValidEmail: 2,
};

const UserStateEnum = createEnhancedEnum('UserState', USER_STATES);

export default UserStateEnum;
