import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';
import IdeaStateLabel from '../board/idea/IdeaStateLabel';
import IdeaStateEnum from '../stores/projects/models/ideas/IdeaStateEnum';

class SearchIdea extends React.Component {
  static propTypes = {
    nestingLevel: PropTypes.number,
    idea: PropTypes.shape({
      id: PropTypes.string.isRequired,
      projectId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      status: PropTypes.oneOf(IdeaStateEnum.keys),
    }).isRequired,
  };

  static defaultProps = {
    nestingLevel: 0,
  };

  render() {
    const idea = this.props.idea;
    return (
      <div className="searchPanel-line" style={{ paddingLeft: 20 + this.props.nestingLevel * 30 }}>
        <i className="searchPanel-line-icon ic-lightbulb_outline" />
        <div className="searchPanel-line-text">{idea.title}</div>
        <IdeaStateLabel value={idea.state || idea.status} />
        <Link
          title={i18n.t('see.idea')}
          className="btn btn_primary btn_medium searchPanel-line-btn"
          to={'/projects/' + idea.projectId + '/ideas/' + idea.id}
        >
          <i className="ic-arrow-right" />
        </Link>
      </div>
    );
  }
}

export default observer(SearchIdea);
