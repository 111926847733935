import React from 'react';
import { observer } from 'mobx-react';
import StatCard from './StatCard';
import projectsStore from '../stores/projects/ProjectsStore';

const stats = [
  {
    label: 'dashboard.stats.projects',
    iconName: 'ic-folder',
    link: '/projects',
  },
  {
    label: 'dashboard.stats.ideas',
    iconName: 'ic-lightbulb_outline',
    link: '/search?type=idea',
  },
  {
    label: 'dashboard.stats.learnings',
    iconName: 'ic-book',
    link: '/search?type=learning',
  },
  {
    label: 'dashboard.stats.experiments',
    iconName: 'ic-rocket',
    link: '/search?type=experiment',
  },
  {
    label: 'dashboard.stats.solvedChallenges',
    iconName: 'ic-check-circle',
    link: '/search?type=project&status=SOLVED',
  },
];

class StatsCards extends React.Component {
  renderStat = stat => <StatCard key={stat.label} stat={stat} />;

  static getValue(stat) {
    let value;
    switch (stat.label) {
      case 'dashboard.stats.projects':
        value = projectsStore.projects.length;
        break;
      case 'dashboard.stats.ideas':
        value = projectsStore.nbIdeas;
        break;
      case 'dashboard.stats.learnings':
        value = projectsStore.nbLearnings;
        break;
      case 'dashboard.stats.experiments':
        value = projectsStore.nbExperiments;
        break;
      case 'dashboard.stats.solvedChallenges':
        value = projectsStore.nbProjectsSolved;
        break;
      default:
        value = 0;
    }
    return value;
  }

  render() {
    const statsWithValues = stats.map(stat => Object.assign({ value: StatsCards.getValue(stat) }, stat));
    return <div className="dashboardPanel-stats">{statsWithValues.map(this.renderStat)}</div>;
  }
}

export default observer(StatsCards);
