/*global window */

import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ImagePicker from '../global/imagePicker/ImagePicker';
import i18n from '../../i18n';

class Logo extends React.Component {
  static propTypes = {
    updateLogo: PropTypes.func.isRequired,
    deleteLogo: PropTypes.func,
    file: PropTypes.shape({
      link: PropTypes.string,
    }),
  };

  onDelete = () => {
    if (window.confirm(i18n.t('admin.logo.confirmDelete'))) {
      if (this.props.deleteLogo) {
        this.props.deleteLogo();
      }
    }
  };

  render() {
    const imageUrl = this.props.file && this.props.file.link;
    return <ImagePicker imageUrl={imageUrl} onImageUpdated={this.props.updateLogo} onImageDeleted={this.onDelete} />;
  }
}

export default observer(Logo);
