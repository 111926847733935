import React from 'react';
import PropTypes from 'prop-types';

export default class SubmitAndCancelButton extends React.PureComponent {
  static propTypes = {
    actionLabel: PropTypes.string.isRequired,
    handleCancel: PropTypes.func,
    handleSubmit: PropTypes.func,
  };

  render() {
    return (
      <div className="d-flex flexDirection_row">
        <button className="btn btn_ok btn_medium m-r-5" onClick={this.props.handleSubmit} value={'submit'}>
          {this.props.actionLabel}
        </button>
        <div className="btn btn_transparent btn_medium" onClick={this.props.handleCancel}>
          <i className="ic-x btn-icon" />
        </div>
      </div>
    );
  }
}
