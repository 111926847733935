import React from 'react';
import PropTypes from 'prop-types';
import withFeedback from '../global/feedback/withFeedback';
import { getErrorMessage } from '../global/utils/ErrorHandling';
import i18n from '../../i18n';
import userStore from '../stores/user/UserStore';
import './css/User.css';

class ResetPassword extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        tokenId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    hideMessage: PropTypes.func.isRequired,
    showErrorMessage: PropTypes.func.isRequired,
    showSuccessMessage: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    password1: '',
    password2: '',
  };

  handleFieldValueChange = fieldName => event => {
    const newState = {};
    newState[fieldName] = event.target.value;
    this.setState(newState);
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.hideMessage();
    const { password1, password2 } = this.state;
    userStore
      .resetPassword(this.props.match.params.tokenId, { password1, password2 })
      .then(data => {
        this.props.showSuccessMessage(i18n.t('user.resetPassword.success'), 1500, () => {
          // go to home after displaying success message
          // and refresh the user data to have isLogged as true
          this.props.history.push('/');
          userStore.refreshUserData(data).catch(error => {
            this.props.showErrorMessage(getErrorMessage(error));
          });
        });
      })
      .catch(error => {
        this.props.showErrorMessage(getErrorMessage(error));
      });
  };

  render() {
    if (this.props.match && this.props.match.params && this.props.match.params.tokenId) {
      return (
        <form className="user-form" onSubmit={this.handleSubmit}>
          <div className="center-square card">
            <label className="m-b-20">{i18n.t('user.resetPassword.title')}</label>
            <input
              className="user-password-input input m-b-40"
              type="password"
              value={this.state.password1}
              placeholder={i18n.t('user.changePassword.newPassword1')}
              onChange={this.handleFieldValueChange('password1')}
            />
            <input
              className="user-password-input input m-b-40"
              type="password"
              value={this.state.password2}
              placeholder={i18n.t('user.changePassword.newPassword2')}
              onChange={this.handleFieldValueChange('password2')}
            />
            <button
              className="user-submit-button btn btn_medium btn_medium_square btn_primary"
              type="submit"
              value={i18n.t('user.submit')}
            >
              {i18n.t('user.submit')}
            </button>
          </div>
        </form>
      );
    } else {
      this.props.showErrorMessage(i18n.t('user.resetPassword.invalidUrl'));
      return <form className="user-form" />;
    }
  }
}

export default withFeedback(ResetPassword);
