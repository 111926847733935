import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import BoardTaskCard from './BoardTaskCard';

class TaskCardsList extends React.Component {
  static propTypes = {
    project: PropTypes.shape({
      tasksList: PropTypes.object.isRequired,
    }).isRequired,
    column: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  renderCard = (card, index) => (
    <BoardTaskCard task={card} index={index} key={card.id} project={this.props.project} column={this.props.column} />
  );

  render() {
    const tasksList = this.props.project.tasksList;
    if (!tasksList[this.props.column.id]) {
      return <div />;
    }
    return <div>{tasksList[this.props.column.id].map(this.renderCard)}</div>;
  }
}

export default observer(TaskCardsList);
