import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import withFeedback from '../../global/feedback/withFeedback';
import learningTypeEnum from '../../stores/projects/models/learnings/LearningTypeEnum';
import { mapProperties, groupBy, renderLearningsByIdea } from '../../search/SearchResults';
import LearningsList from './LearningsList';
import './css/boardLearnings.css';

class BoardLearnings extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
    project: PropTypes.shape({
      id: PropTypes.string.isRequired,
      learnings: PropTypes.shape({
        pains: PropTypes.shape({}),
        gains: PropTypes.shape({}),
        others: PropTypes.shape({}),
        addLearning: PropTypes.func.isRequired,
        deleteLearning: PropTypes.func.isRequired,
      }).isRequired,
      experimentsLearnings: PropTypes.array,
      tasksLearnings: PropTypes.array,
    }).isRequired,
  };

  handleAdd = learning =>
    this.props.project.learnings.addLearning(this.props.project.id, learning).catch(error => {
      this.props.showErrorMessage(getErrorMessage(error), 5000);
    });

  handleDelete = learning =>
    this.props.project.learnings.deleteLearning(learning).catch(error => {
      this.props.showErrorMessage(getErrorMessage(error), 5000);
    });

  render() {
    return (
      <div className="board-learnings">
        <div className="board-learnings-blocks">
          <LearningsList
            name={i18n.t('learnings.painPoints.title')}
            emptyText={i18n.t('learnings.painPoints.emptyText')}
            iconName="ic-sentiment_dissatisfied"
            project={this.props.project}
            learnings={this.props.project.learnings.pains}
            learningType={learningTypeEnum.enum.PAIN}
            onAdd={this.handleAdd}
            onDelete={this.handleDelete}
          />
          <LearningsList
            name={i18n.t('learnings.gainPoints.title')}
            emptyText={i18n.t('learnings.gainPoints.emptyText')}
            iconName="ic-sentiment_satisfied"
            project={this.props.project}
            learnings={this.props.project.learnings.gains}
            learningType={learningTypeEnum.enum.GAIN}
            onAdd={this.handleAdd}
            onDelete={this.handleDelete}
          />
        </div>
        <LearningsList
          name={i18n.t('learnings.otherLearnings.title')}
          emptyText={i18n.t('learnings.otherLearnings.emptyText')}
          iconName="ic-book"
          project={this.props.project}
          learnings={this.props.project.learnings.others}
          learningType={learningTypeEnum.enum.OTHER}
          onAdd={this.handleAdd}
          onDelete={this.handleDelete}
        />
        {this.props.project.experimentsLearnings.length + this.props.project.tasksLearnings.length > 0 ? (
          <div className="tasks-learnings">
            <div className="learnings-block">
              <h3 className="learnings-title d-flex flexDirection_row alignItems_center">
                <i className="learnings-title-icon ic-book-open" />
                {i18n.t('learnings.tasksLearnings')}
              </h3>
              <div className="card learnings-block-content">
                {mapProperties(
                  groupBy(
                    this.props.project.tasksLearnings.concat(this.props.project.experimentsLearnings),
                    learning => learning.ideaId
                  ),
                  renderLearningsByIdea(0)
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withFeedback(BoardLearnings);
