import { executeRequest, executeRequestWithBody, jsRoutes } from '../../../components/lib/RequestUtil';

class LearningsService {
  addProjectLearning(projectId, learning) {
    const { url, method } = jsRoutes.controllers.Learnings.createLearning(projectId);
    return executeRequestWithBody(url, learning, { method });
  }

  addExperimentLearning(experiment, learning) {
    const { url, method } = jsRoutes.controllers.Learnings.createExperimentLearning(
      experiment.projectId,
      experiment.ideaId,
      experiment.id
    );
    return executeRequestWithBody(url, learning, { method });
  }

  addActionLearning(action, learning) {
    const { url, method } = jsRoutes.controllers.Learnings.createActionLearning(action.projectId, action.id);
    return executeRequestWithBody(url, learning, { method });
  }

  deleteProjectLearning(learning) {
    const route = jsRoutes.controllers.Learnings.deleteLearning(learning.projectId, learning.id);
    return executeRequest(route.url, { method: route.method });
  }

  deleteExperimentLearning(experiment, learning) {
    const route = jsRoutes.controllers.Learnings.deleteExperimentLearning(
      experiment.projectId,
      experiment.ideaId,
      experiment.id,
      learning.id
    );
    return executeRequest(route.url, { method: route.method });
  }

  deleteActionLearning(action, learning) {
    const route = jsRoutes.controllers.Learnings.deleteActionLearning(action.projectId, action.id, learning.id);
    return executeRequest(route.url, { method: route.method });
  }

  saveProjectLearning(learning, properties) {
    const route = jsRoutes.controllers.Learnings.updateLearning(learning.projectId, learning.id);
    return executeRequestWithBody(route.url, properties, { method: route.method });
  }

  saveExperimentLearning(learning, experiment, properties) {
    const route = jsRoutes.controllers.Learnings.updateExperimentLearning(
      experiment.projectId,
      experiment.ideaId,
      experiment.id,
      learning.id
    );
    return executeRequestWithBody(route.url, properties, { method: route.method });
  }

  saveActionLearning(learning, action, properties) {
    const route = jsRoutes.controllers.Learnings.updateActionLearning(action.projectId, action.id, learning.id);
    return executeRequestWithBody(route.url, properties, { method: route.method });
  }
}

const learningsService = new LearningsService();
export default learningsService;
