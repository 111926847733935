import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from '../../../i18n';
import Description from '../../global/elems/Description';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import ActionTypeEnum from '../../stores/projects/models/actions/ActionTypeEnum';

class ActionDescription extends React.Component {
  static propTypes = {
    action: PropTypes.shape({
      setText: PropTypes.func,
      setType: PropTypes.func.isRequired,
      text: PropTypes.text,
      type: PropTypes.oneOf(ActionTypeEnum.keys),
    }).isRequired,
    canEditOrRemove: PropTypes.bool,
    showErrorMessage: PropTypes.func.isRequired,
  };

  onChange = event => {
    if (this.props.action.type !== event.target.value) {
      this.props.action
        .setType(event.target.value)
        .catch(error => this.props.showErrorMessage(getErrorMessage(error), 5000));
    }
  };

  render() {
    return (
      <div>
        <div>
          <h3>Type</h3>
          <div>
            <input
              type="radio"
              id="action-type-none"
              name="action-type"
              value={ActionTypeEnum.enum.NONE}
              onChange={this.onChange}
              checked={this.props.action.type === ActionTypeEnum.enum.NONE}
              disabled={!this.props.canEditOrRemove}
            />
            <label htmlFor="action-type-none">{i18n.t('action.type.' + ActionTypeEnum.enum.NONE)}</label>
          </div>
          <div>
            <input
              type="radio"
              id="action-type-empathize"
              name="action-type"
              value={ActionTypeEnum.enum.EMPATHIZE}
              onChange={this.onChange}
              checked={this.props.action.type === ActionTypeEnum.enum.EMPATHIZE}
              disabled={!this.props.canEditOrRemove}
            />
            <label htmlFor="action-type-empathize">{i18n.t('action.type.' + ActionTypeEnum.enum.EMPATHIZE)}</label>
          </div>
        </div>
        <Description
          label="Description"
          placeholder="Describe your action here..."
          defaultValue={this.props.action.text}
          onChange={this.props.action.setText}
          canEdit={this.props.canEditOrRemove}
          showErrorMessage={this.props.showErrorMessage}
        />
      </div>
    );
  }
}

export default observer(ActionDescription);
