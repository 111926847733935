import React from 'react';
import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';

class SearchLearning extends React.Component {
  static propTypes = {
    learning: PropTypes.shape({
      projectId: PropTypes.string.isRequired,
      text: PropTypes.string,
      experimentId: PropTypes.string,
      ideaId: requiredIf(PropTypes.string, props => props.experimentId),
    }).isRequired,
    nestingLevel: PropTypes.number,
  };

  static defaultProps = {
    nestingLevel: 0,
  };

  render() {
    const learning = this.props.learning;
    let entityLink = '';
    if (learning.experimentId) {
      entityLink = '/ideas/' + learning.ideaId + '/experiments/' + learning.experimentId;
    } else if (learning.actionId) {
      entityLink = '/actions/' + learning.actionId;
    }
    const link = '/projects/' + learning.projectId + entityLink + '/learnings';
    return (
      <div className="searchPanel-line" style={{ paddingLeft: 20 + this.props.nestingLevel * 30 }}>
        <i className="searchPanel-line-icon ic-book" />
        <div className="searchPanel-line-text">{learning.text}</div>
        <Link title={i18n.t('see.learnings')} className="btn btn_primary btn_medium searchPanel-line-btn" to={link}>
          <i className="ic-arrow-right" />
        </Link>
      </div>
    );
  }
}

export default observer(SearchLearning);
