import React from 'react';
import PropTypes from 'prop-types';
import 'url-search-params-polyfill';
import i18n from '../../i18n';
import HELPS_TEXTS from './HelpsTexts';
import './css/HelpPanel.css';

class HelpPanel extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        helpId: PropTypes.string,
      }),
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  renderHowToText(text, index) {
    return (
      <div className="helpPanel-howTo-text" key={index}>
        <div className="helpPanel-howTo-text-number">{index + 1}</div>
        {this === true ? <div dangerouslySetInnerHTML={{ __html: text }} /> : text}
      </div>
    );
  }

  renderHowTo = howTo => {
    return (
      <div className="card helpPanel-howTo" key={howTo.title}>
        <div className="helpPanel-howTo-title">{howTo.title}</div>
        <div className="helpPanel-howTo-texts">
          {howTo.texts ? howTo.texts.map(this.renderHowToText, howTo.isHtml) : howTo.text}
        </div>
      </div>
    );
  };

  renderBackBtn() {
    const backUrl = new URLSearchParams(this.props.location.search).get('back');
    if (!backUrl) {
      return null;
    }
    return (
      <div className="modal-back" onClick={() => this.props.history.push(backUrl)}>
        <i className="ic-arrow-left m-r-10" />
        {i18n.t('board.backToBoard')}
      </div>
    );
  }

  renderAdvice = (advice, index) => (
    <div className="helpPanel-advice d-flex flexDirection_row alignItems_center" key={index}>
      <div className={'helpPanel-advice-dot helpPanel-advice-dot_' + advice.type} />
      <div dangerouslySetInnerHTML={{ __html: advice.text }} />
    </div>
  );

  render() {
    const help =
      this.props.match.params && this.props.match.params.helpId
        ? HELPS_TEXTS[this.props.match.params.helpId]
        : HELPS_TEXTS.ideate;
    return (
      <div className="helpPanel">
        {this.renderBackBtn()}
        <h1 className="helpPanel-headline">{help.title}</h1>
        <div className="helpPanel-blocks">
          <div className="helpPanel-block">
            <h3 className="helpPanel-title textTransform_uppercase">
              <i className="helpPanel-title-icon ic-help-circle" />
              {help.howTo.title}
            </h3>
            <div className="helpPanel-block-content">{help.howTo.blocks.map(this.renderHowTo)}</div>
          </div>
          <div className="helpPanel-block">
            <h3 className="helpPanel-title">
              <i className="helpPanel-title-icon ic-info" />
              {i18n.t('help.dosAndDont')}
            </h3>
            <div className="card helpPanel-block-content">{help.advices.map(this.renderAdvice)}</div>
          </div>
        </div>
        {help.toolGuidelines ? (
          <div className="helpPanel-block">
            <h3 className="helpPanel-title">
              <i className="helpPanel-title-icon ic-ask" />
              {i18n.t('help.toolGuidelines')}
            </h3>
            <div
              className="helpPanel-block-content p-20 card"
              dangerouslySetInnerHTML={{ __html: help.toolGuidelines }}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default HelpPanel;
