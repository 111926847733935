/*global window */

import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from '../../../i18n';
import AddButton from '../../global/elems/AddButton';
import CreateModal from '../../global/elems/CreateModal';
import DeleteButton from '../../global/elems/DeleteButton';
import withFeedback from '../../global/feedback/withFeedback';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import UserStore from '../../stores/user/UserStore';
import Group from './Group';
import './css/groups.css';

class Groups extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
    hideMessage: PropTypes.func.isRequired,
    organization: PropTypes.shape({
      groups: PropTypes.object.isRequired,
      createGroup: PropTypes.func.isRequired,
      deleteGroup: PropTypes.func.isRequired,
    }),
  };

  canAddOrDelete = !!UserStore.currentUser && UserStore.currentUser.hasOneOrManyAdminRoles;

  handleStartAdding = () => this.createModal.open();

  handleCreate = name => {
    this.props.organization
      .createGroup({ name })
      .then(() => {
        this.props.hideMessage();
      })
      .catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
  };

  onDelete = group => event => {
    event.preventDefault();
    event.stopPropagation();
    if (window.confirm(i18n.t('admin.groups.confirmDelete', { groupName: group.name }))) {
      this.props.organization
        .deleteGroup(group)
        .then(() => {
          this.props.hideMessage();
        })
        .catch(error => {
          this.props.showErrorMessage(getErrorMessage(error), 5000);
        });
    }
  };

  renderGroup = group => {
    return group ? (
      <div className="adminPanel-block-content-line group-block" key={group.id}>
        <Group group={group} className="group-details" canEdit={this.canAddOrDelete} />
        <DeleteButton
          onClick={this.onDelete(group)}
          title={i18n.t('admin.groups.delete')}
          className="groups-block-btnTrash"
          canDelete={this.canAddOrDelete}
        />
      </div>
    ) : null;
  };

  renderAddButton = isEmptyList => {
    return this.canAddOrDelete ? (
      <AddButton isEmptyList={isEmptyList} buttonText={i18n.t('admin.groups.add')} onClick={this.handleStartAdding} />
    ) : null;
  };

  render() {
    return (
      <div className="card adminPanel-block-content">
        <div className="adminPanel-block-content-line">
          <h4 className="d-flex flexDirection_row alignItems_center">
            <i className="ic-users m-r-10" />
            {i18n.t('admin.groups.title')}
            {this.renderAddButton()}
          </h4>
        </div>
        {this.props.organization.groups.map(this.renderGroup)}
        <CreateModal
          ref={createModal => (this.createModal = createModal)}
          title={i18n.t('admin.groups.new.title')}
          label={i18n.t('admin.groups.new.name.label')}
          createButtonText={i18n.t('admin.groups.new.create')}
          placeholder={i18n.t('admin.groups.new.name.placeholder')}
          handleCreate={this.handleCreate}
        />
      </div>
    );
  }
}

export default withFeedback(observer(Groups));
