import { destroy, flow, types } from 'mobx-state-tree';
import FileModel from './FileModel';
import filesService from '../../FilesService';

const FilesModel = types
  .model('Files', {
    files: types.array(FileModel),
  })
  .views(self => ({
    getFileById(fileId) {
      return self.files.find(file => file.id === fileId);
    },

    searchFiles(text) {
      return self.files.filter(file => file.contains(text));
    },
    getFileFromId(fileId) {
      return self.getFileById(fileId);
    },
  }))
  .actions(self => ({
    createProjectFiles: flow(function* createProjectFiles(organizationId, projectId, files) {
      const res = yield filesService.createProjectFiles(organizationId, projectId, files);
      self.addFiles(res.data.files);
    }),

    deleteProjectFile: flow(function* deleteProjectFile(projectId, file) {
      const fileId = file.id;
      yield filesService.deleteProjectFile(projectId, fileId);
      self.removeFile(fileId);
    }),

    createActionFiles: flow(function* createActionFiles(projectId, actionId, files) {
      const res = yield filesService.createActionFiles(projectId, actionId, files);
      self.addFiles(res.data.files);
    }),

    deleteActionFile: flow(function* deleteActionFile(projectId, actionId, file) {
      const fileId = file.id;
      yield filesService.deleteActionFile(projectId, actionId, fileId);
      self.removeFile(fileId);
    }),

    createExperimentFiles: flow(function* createExperimentFiles(projectId, ideaId, experimentId, files) {
      const res = yield filesService.createExperimentFiles(projectId, ideaId, experimentId, files);
      self.addFiles(res.data.files);
    }),

    createExperimentResultFiles: flow(function* createExperimentResultFiles(projectId, ideaId, experimentId, files) {
      const res = yield filesService.createExperimentResultFiles(projectId, ideaId, experimentId, files);
      self.addFiles(res.data.files);
    }),

    createExperimentSolutionFiles: flow(function* createExperimentSolutionFiles(
      projectId,
      ideaId,
      experimentId,
      files
    ) {
      const res = yield filesService.createExperimentSolutionFiles(projectId, ideaId, experimentId, files);
      self.addFiles(res.data.files);
    }),

    deleteExperimentFile: flow(function* deleteExperimentFile(projectId, ideaId, experimentId, file) {
      const fileId = file.id;
      yield filesService.deleteExperimentFile(projectId, ideaId, experimentId, fileId);
      self.removeFile(fileId);
    }),

    deleteExperimentResultFile: flow(function* deleteExperimentResultFile(projectId, ideaId, experimentId, file) {
      const fileId = file.id;
      yield filesService.deleteExperimentResultFile(projectId, ideaId, experimentId, fileId);
      self.removeFile(fileId);
    }),

    deleteExperimentSolutionFile: flow(function* deleteExperimentSolutionFile(projectId, ideaId, experimentId, file) {
      const fileId = file.id;
      yield filesService.deleteExperimentSolutionFile(projectId, ideaId, experimentId, fileId);
      self.removeFile(fileId);
    }),

    addFiles(files) {
      files.forEach(file => {
        if (!self.getFileById(file.id)) {
          self.files.push(FileModel.create(file));
        }
      });
    },

    removeFile(fileId) {
      const fileToRemove = self.getFileById(fileId);
      if (fileToRemove) {
        destroy(fileToRemove);
        return true;
      } else {
        return false;
      }
    },

    handleEvent(event) {
      if (event && event.event && event.eventType) {
        const addFiles = files => {
          self.addFiles(files);
          return true;
        };

        switch (event.eventType) {
          case 'ProjectFilesAdded':
            return addFiles(event.event.files.files);
          case 'ActionFilesAdded':
            return addFiles(event.event.files.files);
          case 'ExperimentFilesAdded':
            return addFiles(event.event.files.files);
          case 'ExperimentResultFilesAdded':
            return addFiles(event.event.files.files);
          case 'ExperimentSolutionFilesAdded':
            return addFiles(event.event.files.files);
          case 'ProjectFileRemoved':
            return self.removeFile(event.event.fileId);
          case 'ActionFileRemoved':
            return self.removeFile(event.event.fileId);
          case 'ExperimentFileRemoved':
            return self.removeFile(event.event.fileId);
          case 'ExperimentResultFileRemoved':
            return self.removeFile(event.event.fileId);
          case 'ExperimentSolutionFileRemoved':
            return self.removeFile(event.event.fileId);
          case 'ProjectFileMarkedPublic':
          case 'ProjectFileCallForUpdated':
            var file = self.getFileById(event.event.fileId);
            if (file) {
              return file.handleEvent(event);
            }
            console.warn('Cannot update file metadata, file does not exist');
            return false;
          default:
            console.warn('FilesModel does not know how to handle this eventType', event);
            return false;
        }
      }
      console.warn('FilesModel does not know how to handle this event', event);
      return false;
    },
  }));

export default FilesModel;
