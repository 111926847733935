import { createEnhancedEnum } from '../../../../global/utils/EnumBuilder';

const FILE_CALL_FOR = {
  IDEA: 0,
  PROJECT: 1,
  PROPOSAL: 2,
  INFORMATION: 3,
};

const FileCallForEnum = createEnhancedEnum('FileCallFor', FILE_CALL_FOR);

export default FileCallForEnum;
