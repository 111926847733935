import React from 'react';
import PropTypes from 'prop-types';

export default class AddButton extends React.PureComponent {
  static propTypes = {
    isEmptyList: PropTypes.bool,
    buttonText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    value: PropTypes.string,
  };

  render() {
    if (this.props.isEmptyList) {
      return (
        <button className="btn btn_primary btn_medium m-t-10" onClick={this.props.onClick} value={this.props.value}>
          <i className="btn-icon ic-plus" />
          <span>{this.props.buttonText}</span>
        </button>
      );
    } else {
      return (
        <button
          className="btn btn_primary btn_medium btn_medium_square m-l-20"
          onClick={this.props.onClick}
          value={this.props.value}
          title={this.props.buttonText}
        >
          <i className="btn-icon ic-plus" />
        </button>
      );
    }
  }
}
