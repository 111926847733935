import { flow, getParent, types } from 'mobx-state-tree';
import { jsRoutes } from '../../../../../components/lib/RequestUtil';
import { caseInsensitiveSearch } from '../../../../../components/lib/SearchUtil';
import FileMetadataModel from './FileMetadataModel';
import FilesService from '../../FilesService';

const FileModel = types
  .model('File', {
    id: types.identifier,
    name: types.string,
    contentType: types.optional(types.string, ''),
    metadata: types.optional(FileMetadataModel, () => FileMetadataModel.create()),
  })
  .views(self => ({
    get link() {
      return jsRoutes.controllers.Projects.getFile(self.id).url;
    },

    get project() {
      try {
        let maybeProject = getParent(self, 3);
        if (maybeProject.$treenode.type.name === 'Project') {
          return maybeProject;
        }
        maybeProject = getParent(self, 6);
        if (maybeProject.$treenode.type.name === 'Project') {
          return maybeProject;
        }
      } catch (err) {
        console.warn(err);
      }
      return getParent(self, 8);
    },

    get projectId() {
      return self.project.id;
    },

    get idea() {
      const maybeIdea = getParent(self, 5);
      return maybeIdea.$treenode.type.name === 'Idea' ? maybeIdea : undefined;
    },

    get ideaId() {
      return self.idea ? self.idea.id : undefined;
    },

    get experiment() {
      const maybeExperiment = getParent(self, 3);
      return maybeExperiment.$treenode.type.name === 'Experiment' ? maybeExperiment : undefined;
    },

    get experimentId() {
      return self.experiment ? self.experiment.id : undefined;
    },

    get action() {
      const maybeAction = getParent(self, 3);
      return maybeAction.$treenode.type.name === 'Action' ? maybeAction : undefined;
    },

    get actionId() {
      return self.action ? self.action.id : undefined;
    },

    contains(text) {
      return caseInsensitiveSearch(self.name, text);
    },
  }))
  .actions(self => ({
    togglePublicFile: flow(function* togglePublicFile(project, value) {
      yield FilesService.togglePublicFile(project, self, value);
      self.metadata.togglePublicFile(value);
    }),
    setCallFor: flow(function* setCallFor(project, value) {
      yield FilesService.setCallFor(project, self, value);
      self.metadata.setCallFor(value);
    }),

    handleEvent(event) {
      if (event && event.event && event.eventType) {
        switch (event.eventType) {
          case 'ProjectFileMarkedPublic':
            if (self.metadata.public !== event.event.public) {
              self.metadata.togglePublicFile(event.event.public);
              return true;
            } else {
              return false;
            }
          case 'ProjectFileCallForUpdated':
            if (self.metadata.callFor !== event.event.callFor) {
              self.metadata.setCallFor(event.event.callFor);
              return true;
            } else {
              return false;
            }
          default:
        }
      }
      console.warn('FileModel does not know how to handle event ', event);
      return false;
    },
  }));

export default FileModel;
