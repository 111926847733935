import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ReactTable from 'react-table';
import withFeedback from '../../global/feedback/withFeedback';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import RootStore from '../../stores/root/RootStore';
import 'react-table/react-table.css';

class EventsPanel extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
  };

  state = {
    events: [],
    users: [],
    usersMap: [],
    usersInEvents: [],
  };

  componentDidMount() {
    RootStore.getAllUsers()
      .then(users => {
        const usersMap = users.reduce((map, user) => {
          map[user.id] = user;
          return map;
        }, {});
        this.setState({ users: users, usersMap: usersMap });
      })
      .catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
    RootStore.rawEvents()
      .then(events => {
        this.setState({ events: events });
      })
      .catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
  }

  static getDerivedStateFromProps(props, state) {
    const _usersInEvents = new Set();
    state.events.forEach(event => {
      _usersInEvents.add(RootStore.getUserIdFromEvent(event));
    });

    return { usersInEvents: Array.from(_usersInEvents) };
  }

  componentWillUnmount() {
    // FIXME: Reproduction: Come back to an other URL before finishing the componentDidMount()
    // TODO: Cancel the 2 network requests from componentDidMount() to avoid the:
    // Can't call setState (or forceUpdate) on an unmounted component.
    // This is a no-op, but it indicates a memory leak in your application.
    // To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.
  }

  getUserFullName(value) {
    const user = this.state.usersMap[value];
    return user ? user.fullName : value;
  }

  render() {
    const usersList = this.state.usersInEvents;

    const customOptionsFilter = ({ filter, onChange }) => {
      return (
        <select
          onChange={e => onChange(e.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Show All</option>
          {usersList.map(k => {
            return (
              <option key={k.toString()} value={k}>
                {this.getUserFullName(k)}
              </option>
            );
          })}
        </select>
      );
    };

    const customOptionsFilterMethod = (filter, row) => {
      if (filter.value === '') {
        return true;
      }

      if (usersList.includes(filter.value)) {
        return row[filter.id] === filter.value;
      } else {
        return true;
      }
    };

    return (
      <div className="panel">
        <h3>Events List</h3>
        <ReactTable
          filterable={true}
          defaultSorted={[
            {
              id: 'createdOn',
              desc: true,
            },
          ]}
          data={this.state.events}
          columns={[
            { Header: 'Event Type', accessor: 'eventType' },
            { Header: 'Page', accessor: 'page' },
            { Header: 'Title', accessor: 'title' },
            {
              id: 'createdOn',
              Header: 'Created On',
              accessor: 'auditTrail.created.on.$date',
              filterable: false,
              Cell: row => {
                return new Date(row.value).toLocaleString();
              },
            },
            {
              id: 'By',
              Header: 'By',
              accessor: event => {
                return RootStore.getUserIdFromEvent(event);
              },
              Cell: row => {
                return this.getUserFullName(row.value);
              },
              filterMethod: (filter, row) => customOptionsFilterMethod(filter, row),
              Filter: ({ filter, onChange }) => customOptionsFilter({ filter, onChange }),
            },
          ]}
        />
      </div>
    );
  }
}

export default withFeedback(observer(EventsPanel));
