import ideaStateEnum from './IdeaStateEnum';
import ExperimentStateEnum from '../experiments/ExperimentStateEnum';

const IdeaStateFromExperimentState = {
  [ExperimentStateEnum.index.REFUSED]: ideaStateEnum.enum.PPP,
  [ExperimentStateEnum.index.PIVOTED]: ideaStateEnum.enum.MVP,
  [ExperimentStateEnum.index.BUILDING]: ideaStateEnum.enum.MVP,
  [ExperimentStateEnum.index.TESTING]: ideaStateEnum.enum.TEST,
  [ExperimentStateEnum.index.PPP]: ideaStateEnum.enum.PPP,
  [ExperimentStateEnum.index.ACCEPTED]: ideaStateEnum.enum.SOLVED,
};

export default IdeaStateFromExperimentState;
