import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ReactTable from 'react-table';
import withFeedback from '../../global/feedback/withFeedback';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import OrganizationsStore from '../../stores/organizations/OrganizationsStore';
import RootStore from '../../stores/root/RootStore';
import 'react-table/react-table.css';

class Users extends React.Component {
  static propTypes = {
    showErrorMessage: PropTypes.func.isRequired,
  };

  state = {
    users: [],
  };

  componentDidMount() {
    RootStore.getAllUsers()
      .then(users => {
        this.setState({ users: users });
      })
      .catch(error => {
        this.props.showErrorMessage(getErrorMessage(error), 5000);
      });
  }

  componentWillUnmount() {
    // FIXME: Reproduction: Come back to an other URL before finishing the componentDidMount()
    // TODO: Cancel the 2 network requests from componentDidMount() to avoid the:
    // Can't call setState (or forceUpdate) on an unmounted component.
    // This is a no-op, but it indicates a memory leak in your application.
    // To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.
  }

  renderCeil(value) {
    if (Array.isArray(value)) {
      return value.map(v => {
        return <div key={v}>{v}</div>;
      });
    }
    return value;
  }

  render() {
    const customOptionsFilter = (values, { filter, onChange }) => {
      return (
        <select
          onChange={e => onChange(e.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Show All</option>
          {values.map(k => {
            return (
              <option key={k.toString()} value={k}>
                {k}
              </option>
            );
          })}
        </select>
      );
    };
    const customOptionsFilterMethod = (values, filter, row) => {
      if (filter.value === '') {
        return true;
      }

      if (values.includes(filter.value)) {
        if (!row.roles) {
          return false;
        }
        return row.roles.includes(filter.value);
      } else {
        return true;
      }
    };

    const organizationCustomOptionsFilter = (organizations, { filter, onChange }) => {
      return (
        <select
          onChange={e => onChange(e.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'all'}
        >
          <option value="all">Show All</option>
          {organizations.map(k => {
            return (
              <option key={k.id.toString()} value={k.id}>
                {k.name}
              </option>
            );
          })}
        </select>
      );
    };
    const organizationCustomOptionsFilterMethod = (filter, row) => {
      if (filter.value === 'all') {
        return true;
      }

      return row.organizations.includes(filter.value);
    };

    const _userOrganizations = new Set();
    this.state.users.forEach(user => {
      if (user.organizations) {
        user.organizations.forEach(organizationId => _userOrganizations.add(organizationId));
      }
    });
    const userOrganizations = Array.from(_userOrganizations).reduce((map, organizationId) => {
      map.push({ id: organizationId, name: OrganizationsStore.getOrganizationById(organizationId).name });
      return map;
    }, []);

    const _userRoles = new Set();
    this.state.users.forEach(user => {
      if (user.roles) {
        user.roles.forEach(role => _userRoles.add(role));
      }
    });
    const userRoles = Array.from(_userRoles);

    return (
      <div className="panel">
        <h3>Users List</h3>
        <ReactTable
          filterable={true}
          defaultSorted={[{ id: 'lastName' }, { id: 'firstName' }]}
          data={this.state.users}
          columns={[
            { Header: 'Id', accessor: 'id' },
            { Header: 'FirstName', accessor: 'firstName', id: 'firstName' },
            { Header: 'LastName', accessor: 'lastName', id: 'lastName' },
            { Header: 'Email', accessor: 'email' },
            {
              id: 'roles',
              Header: 'Roles',
              accessor: 'roles',
              Cell: row => this.renderCeil(row.value),
              filterMethod: (filter, row) => customOptionsFilterMethod(userRoles, filter, row),
              Filter: ({ filter, onChange }) => customOptionsFilter(userRoles, { filter, onChange }),
            },
            {
              id: 'organizations',
              Header: 'Organizations',
              accessor: 'organizations',
              Cell: row => {
                if (Array.isArray(row.value)) {
                  row.value = row.value.map(value => OrganizationsStore.getOrganizationById(value).name);
                }
                return this.renderCeil(row.value);
              },
              filterMethod: (filter, row) => organizationCustomOptionsFilterMethod(filter, row),
              Filter: ({ filter, onChange }) => {
                return organizationCustomOptionsFilter(userOrganizations, { filter, onChange });
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default withFeedback(observer(Users));
