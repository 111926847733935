import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { observer } from 'mobx-react';
import Modal from '../../global/elems/Modal';
import { getErrorMessage } from '../../global/utils/ErrorHandling';
import i18n from '../../../i18n';

class ExperimentValidationModal extends React.Component {
  static propTypes = {
    groups: PropTypes.array,
    experiment: PropTypes.shape({
      requestValidation: PropTypes.func.isRequired,
    }),
    onError: PropTypes.func,
    onSuccess: PropTypes.func,
  };

  static getDerivedStateFromProps(props, state) {
    const groups = props.groups || [];
    const groupIds = groups.map(group => group.id);
    return {
      options: groups.map(group => ({ value: group.id, label: group.name })),
      selectedOptions: state.selectedOptions.filter(option => groupIds.includes(option.value)),
    };
  }

  state = {
    selectedOptions: [],
  };

  open = () => this.modal.open();

  close = () => this.modal.close();

  handleChange = selectedOptions => {
    this.setState({ selectedOptions });
  };

  requestValidationClick = evt => {
    if (this.state.selectedOptions.length === 0) {
      return;
    }
    evt.preventDefault();
    const groupIds = this.state.selectedOptions.map(option => {
      return option.value;
    });
    const selectedGroups = this.state.selectedOptions;
    this.props.experiment
      .requestValidation(groupIds)
      .then(requestedGroups => {
        const groupNames = selectedGroups
          .filter(option => requestedGroups.includes(option.value))
          .map(option => option.label);
        this.props.onSuccess && this.props.onSuccess(i18n.t('validation.emailsWillBeSent', { groupNames }));
      })
      .catch(error => {
        const errorMessage = getErrorMessage(error);
        this.props.onError && this.props.onError(i18n.t('validation.requestError', { errorMessage }));
      });
  };

  render() {
    const { selectedOptions } = this.state;

    return (
      <Modal onOpened={this.focusInput} ref={modal => (this.modal = modal)}>
        <h2 className="modal-title">{i18n.t('validation.requestValidation')}</h2>
        <div className="form-group">
          <label>What group(s) need to be requested?</label>
          <Select
            autoFocus={true}
            className="input"
            isMulti={true}
            isSearchable={true}
            value={selectedOptions}
            onChange={this.handleChange}
            options={this.state.options}
          />
        </div>
        <div className="d-flex flexDirection_row">
          {this.state.selectedOptions.length > 0 ? (
            <div className="btn btn_ok btn_medium m-r-5" onClick={this.requestValidationClick}>
              {i18n.t('validation.sendRequest')}
            </div>
          ) : (
            <div className="btn btn_medium m-r-5 disabled">{i18n.t('validation.sendRequest')}</div>
          )}
          <div className="btn btn_transparent btn_medium" onClick={this.close}>
            <i className="ic-x btn-icon" />
          </div>
        </div>
      </Modal>
    );
  }
}

export default observer(ExperimentValidationModal);
