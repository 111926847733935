import { executeRequest, jsRoutes } from '../../../components/lib/RequestUtil';

class RootService {
  getAllUsers() {
    const { url, method } = jsRoutes.mvpstars.controllers.admin.Users.list();
    return executeRequest(url, { method });
  }

  rawEvents() {
    const { url, method } = jsRoutes.mvpstars.controllers.Events.list();
    return executeRequest(url, { method });
  }
}

const rootService = new RootService();
export default rootService;
