import React from 'react';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import UserStore from '../../stores/user/UserStore';
import File from './File';
import '../../../css/dropzone.css';
import './css/files.css';

class Files extends React.Component {
  static propTypes = {
    publicSwitch: PropTypes.bool,
    handleCheckBoxChange: PropTypes.func,
    handleSelectChange: PropTypes.func,
    deleteFile: PropTypes.func,
    createFiles: PropTypes.func,
    files: PropTypes.shape({
      files: PropTypes.oneOfType([PropTypes.array, MobxPropTypes.observableArray]),
    }),
    project: PropTypes.shape({
      isMember: PropTypes.func,
    }),
  };

  state = {
    showDropZone: false,
    sending: false,
    currentUser: {},
  };

  componentDidMount() {
    this.setState({
      currentUser: {
        canAddOrDelete:
          (UserStore.currentUser && UserStore.currentUser.hasOneOrManyAdminRoles) ||
          (this.props.project && this.props.project.isMember(UserStore.currentUser.id)),
      },
    });
  }

  handleShowHideDropZone = () => this.setState({ showDropZone: !this.state.showDropZone });

  onDrop = files => {
    if (files.length > 0 && !this.state.sending) {
      this.setState({ sending: true });
      this.props
        .createFiles(files)
        .catch(e => {
          // TODO Fix error handling using withFeedback HOC
          console.warn(e);
        })
        .then(() => {
          this.setState({ sending: false });
        });
    } else {
      this.setState({ sending: false });
    }
  };

  deleteFile = file => {
    this.props.deleteFile(file);
  };

  handleCheckBoxChange = (file, value) => {
    this.props.handleCheckBoxChange(file, value);
  };
  handleSelectChange = (file, value) => {
    this.props.handleSelectChange(file, value);
  };

  renderDropZone = () => (
    <Dropzone
      multiple={true}
      className={`dropzone ${this.state.showDropZone ? 'visible' : ''}`}
      activeClassName="active"
      acceptClassName="accept"
      rejectClassName="reject"
      onDropAccepted={this.onDrop}
    >
      <i className={this.state.sending ? 'ic-save' : 'ic-file-plus'} />
      {i18n.t(this.state.sending ? 'files.uploading' : 'files.clickOrDrag')}
    </Dropzone>
  );

  renderEmptyList = () =>
    this.state.showDropZone ? null : (
      <div className="files-block-emptyList d-flex alignItems_center justifyContent_center flex_1 flexDirection_column">
        {i18n.t('files.emptyList')}
        {this.renderAddButton(true)}
      </div>
    );

  renderAddButton = isEmptyList => {
    if (!this.state.currentUser.canAddOrDelete) {
      return null;
    }
    if (isEmptyList) {
      return (
        <div className="btn btn_primary btn_medium m-t-10" onClick={this.handleShowHideDropZone}>
          <i className="btn-icon ic-file-plus" />
          <span>{i18n.t('files.add')}</span>
        </div>
      );
    }
    return (
      <div className="btn btn_primary btn_medium btn_medium_square m-l-20" onClick={this.handleShowHideDropZone}>
        <i className="btn-icon ic-file-plus" />
      </div>
    );
  };

  render() {
    return (
      <div className="files-block">
        <h3 className="files-title d-flex flexDirection_row alignItems_center">
          <i className="ic-paperclip m-r-10" />
          {i18n.t('files.title')}
          {this.renderAddButton()}
        </h3>
        {this.renderDropZone()}
        <div className="card files-block-content">
          {this.props.files && this.props.files.files.length > 0
            ? this.props.files.files.map(
                file => (
                  <File
                    key={file.id}
                    file={file}
                    deleteFile={this.deleteFile}
                    canUpdateOrDelete={this.state.currentUser.canAddOrDelete}
                    publicSwitch={this.props.publicSwitch}
                    handleCheckBoxChange={this.handleCheckBoxChange}
                    handleSelectChange={this.handleSelectChange}
                  />
                ),
                this
              )
            : this.renderEmptyList()}
        </div>
      </div>
    );
  }
}

export default observer(Files);
