import { types } from 'mobx-state-tree';

function createEnhancedEnum(name, values) {
  const enumObject = {};

  enumObject.type = types.enumeration(name, Object.keys(values));
  enumObject.enum = Object.freeze(
    Object.keys(values).reduce((enumeration, value) => {
      enumeration[value] = value;
      return enumeration;
    }, {})
  );
  enumObject.keys = Object.freeze(Object.keys(values));
  enumObject.values = Object.freeze(Object.values(values));
  enumObject.index = Object.freeze(values);
  enumObject.fromIndex = Object.freeze(
    Object.entries(values).reduce((fromIndex, [key, index]) => {
      fromIndex[index] = key;
      return fromIndex;
    }, {})
  );

  return Object.freeze(enumObject);
}

export { createEnhancedEnum };
